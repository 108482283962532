import Vue from 'vue';
import store from '@/stores/store';

const contentful = require('contentful');

const client = contentful.createClient({
    space: "pi2rko0ko0vm",
    accessToken: "--8R5Fb_StNt59asxJ849F7yZ6U1rKUN--tJYigUqg8",
});

const state = {
    uniqueIds: [],
    uniqueIds_loading: false,
    uniqueIds_loading_errors: null,
};

const mutations = {

    updateUniqueIds(state, uniqueIds) {
        state.uniqueIds = uniqueIds;
    },

    updateUniqueIdsLoading(state, loading) {
        state.uniqueIds_loading = loading;
    },

    updateUniqueIdsLoadingErrors(state, errors) {
        state.uniqueIds_loading_errors = errors;
    },
};

const actions = {

    fetchUniqueIds({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {

            // Function to list 'help' content types, 50 items at a time with pagination support
            const listHelpContentUniqueIds = async () => {
                let skip = 0;
                const limit = 50;
                let uniqueIds = [];
                let totalPages = 1;
                let response;

                try {
                    do {
                        // Fetching entries in batches of 50
                        response = await client.getEntries({
                            content_type: 'help',
                            skip: skip,
                            limit: limit
                        });

                        // Updating pagination information
                        totalPages = Math.ceil(response.total / limit);
                        skip += limit;

                        // Extracting 'uniqueId' fields and adding them to the results list
                        uniqueIds = uniqueIds.concat(response.items.map(item => item.fields.uniqueId));
                    } while (skip < response.total);  // Continue fetching until all pages are processed

                    return uniqueIds;
                } catch (error) {
                    console.error('Error while fetching entries:', error);
                    return [];
                }
            };

            listHelpContentUniqueIds().then(uniqueIds => {
                commit('updateUniqueIds', uniqueIds);
                resolve(uniqueIds);
            }).catch(error => {
                console.error('Erreur lors de la récupération des entrées :', error);
                reject(error);
            });
        });
    },

    fetchContent({ commit, dispatch, state }, contentUniqueId) { // eslint-disable-line no-unused-vars
        let active_locale = store.state.session['active_locale'];
        return new Promise((resolve, reject) => {
            client
                .getEntries({
                    "fields.uniqueId": contentUniqueId,
                    content_type: "help",
                    locale: active_locale,
                })
                .then((entries) => {
                    if (!entries?.items?.length) {
                        reject(Vue.prototype.$gettext("Did not find help content."));
                        return;
                    }
                    resolve(entries.items[0]);
                })
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
