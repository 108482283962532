import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import synchronize from './submodules/synchronize';
import qs from 'qs';


const state = {
    stock: null,
    stocks: [],
    stocks_loaded: false,
    stocks_loading: false,
    stocks_loading_errors: null,

    producttypes: [],
    producttypes_loading: false,
    producttypes_loaded: false,
    producttypes_loading_errors: null,
};


const mutations = {
    updateStocks(state, stocks) {
        state.stocks = stocks;
        state.stocks_loaded = true;
        state.stocks_loading = false;
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
        if (loading) {
            state.stocks_loading_errors = null;
            state.stocks_loaded = false;
        }
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
        state.stocks_loading = false;
        state.stocks_loaded = false;
    },
    updateStocksCount(state, stocks_count) {
        state.stocks_count = stocks_count;
    },

    updateStock(state, stock) {
        state.stock = stock;
    },
};

const actions = {
    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStock', null);
        dispatch("fetchStocks");
    },

    changeStock({ commit, dispatch, state }, stock) { // eslint-disable-line no-unused-vars
        commit("updateStock", stock);
        commit("synchronize/updateExtraParams", { stock });
    },

    fetchStocks({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStocksLoading', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/stocks/', {
                params: { limit: 500, owned: true },
            })
                .then((response) => {
                    commit('updateStocks', response.data.results);
                    commit('updateStocksCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateStocksLoading', false);
                    reject(error);
                })
        });
    },


    exportStockProductsAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        filters.stock = state.stock;
        filters.format = 'csv';
        filters.limit = 20000;
        filters.exclude_empty = true;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/stockproducts/?" + queryparams);
    },

    exportProductsAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        filters.stock = state.stock;
        filters.format = 'csv';
        filters.limit = 20000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/products/?" + queryparams);
    },
};

export default {
    namespaced: true,
    state,

    actions,
    mutations,
    modules: {
        synchronize: synchronize(),
    }
};
