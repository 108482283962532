import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {

    evolution: null,
    evolutionPeriod: "last12months",
    evolutionLoading: false,
    evolutionLoadingErrors: {},

    byCustomer: null,
    byCustomerPeriod: "last12months",
    byCustomerLoading: false,
    byCustomerLoadingErrors: {},

    statsLoading: false,
    statsLoadingErrors: {},
    lastMonth: null,
    previousMonth: null,
    lastMonthTrend: null,
    currentYear: null,
    lastYear: null,
    waitingValidation: null,
    waitingValidationInvoices: null,
    waitingPayment: null,
    waitingPaymentInvoices: null,
    waitingDeliveryInvoices: null,
};

const mutations = {

    updateStatsLoading(state, statsLoading) {
        state.statsLoading = statsLoading;
        state.statsLoadingErrors = {};
    },
    updateStatsLoadingErrors(state, statsLoadingErrors) {
        state.statsLoadingErrors = statsLoadingErrors;
        state.statsLoading = false;
    },

    updateStats(state, stats) {
        state.statsLoading = false;
        state.lastMonth = stats.last_month_total_excl_vat;
        state.previousMonth = stats.previous_month_total_excl_vat;
        state.lastMonthTrend = stats.last_month_trend;
        state.currentYear = stats.current_year_total_excl_vat;
        state.lastYear = stats.last_year_total_excl_vat;
        state.waitingValidation = stats.draft_invoices_total_excl_vat;
        state.waitingDeliveryInvoices = stats.waiting_invoices_delivery_count;
        state.waitingValidationInvoices = stats.draft_invoices_count;
        state.waitingPaymentInvoices = stats.waiting_invoices_payment_count;
        state.waitingPayment = stats.waiting_invoices_payment_total_excl_vat;
    },

    updateEvolution(state, evolution) {
        state.evolution = evolution;
        state.evolutionLoading = false;
        state.evolutionLoadingErrors = {};
    },
    updateEvolutionPeriod(state, evolutionPeriod) {
        state.evolution = null;
        state.evolutionPeriod = evolutionPeriod;
    },
    updateEvolutionLoading(state, evolutionLoading) {
        state.evolutionLoading = evolutionLoading;
    },
    updateEvolutionLoadingErrors(state, evolutionLoadingErrors) {
        state.evolutionLoading = false;
        state.evolutionLoadingErrors = evolutionLoadingErrors;
    },

    updateByCustomerPeriod(state, byCustomerPeriod) {
        state.byCustomer = null;
        state.byCustomerPeriod = byCustomerPeriod;
    },
    updateByCustomer(state, byCustomer) {
        state.byCustomer = byCustomer;
        state.byCustomerLoading = false;
        state.byCustomerLoadingErrors = {};
    },
    updateByCustomerLoading(state, byCustomerLoading) {
        state.byCustomerLoading = byCustomerLoading;
    },
    updateByCustomerLoadingErrors(state, byCustomerLoadingErrors) {
        state.byCustomerLoading = false;
        state.byCustomerLoadingErrors = byCustomerLoadingErrors;
    },

};

const actions = {

    fetchAllStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        dispatch('fetchStats');
        dispatch('fetchEvolution');
        dispatch('fetchByCustomer');
    },

    fetchStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStatsLoading', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/stats/billing/stats/', {})
                .then((response) => {
                    commit('updateStats', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStatsLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    fetchEvolution({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEvolutionLoading', true);
        if (!state.evolutionPeriod) {
            return;
        }

        return new Promise((resolve, reject) => {
            axios.get('/api/stats/billing/evolution/', { params: { start: state.evolutionPeriod.start, end: state.evolutionPeriod.end } })
                .then((response) => {
                    commit('updateEvolution', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateEvolutionLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    fetchByCustomer({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateByCustomerLoading', true);
        if (!state.byCustomerPeriod) {
            return;
        }
        return new Promise((resolve, reject) => {
            axios.get('/api/stats/billing/bycustomer/', { params: { start: state.byCustomerPeriod.start, end: state.byCustomerPeriod.end } })
                .then((response) => {
                    commit('updateByCustomer', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateByCustomerLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    setByCustomerPeriod({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateByCustomerPeriod', params);
        dispatch('fetchByCustomer');
    },

    setEvolutionPeriod({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEvolutionPeriod', params);
        dispatch('fetchEvolution');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
