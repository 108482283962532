import axios from '@/plugins/axios';
import store from '@/stores/store';
import utils from '@/stores/utils';
import documents from './submodules/documents';

const state = {
    endcustomersite: null,
    endcustomersite_loading: false,
    endcustomersite_saving: false,
    endcustomersite_loading_errors: null,

    stats: {},
    stats_loading: false,
    stats_loading_errors: null,

    shipments: [],
    shipments_count: 0,
    shipments_total_count: 0,
    shipments_filters: { offset: 0, limit: 20, ordering: '-delivery_date' },
    shipments_loading: false,
    shipments_loaded: false,
    shipments_loading_errors: null,

    orders: [],
    orders_count: 0,
    orders_filters: { offset: 0, limit: 20, ordering: '-creation_date' },
    orders_loading: false,
    orders_loaded: false,
    orders_loading_errors: null,
};

const mutations = {

    updateEndCustomerSite(state, endcustomersite) {
        if (state.endcustomersite && endcustomersite && state.endcustomersite.id == endcustomersite.id) {
            state.endcustomersite = endcustomersite;
            return;
        }
        state.endcustomersite = endcustomersite;

        state.stats = [];
        state.stats_loading = false;
        state.stats_loading_errors = null;

        state.shipments = [];
        state.shipments_count = 0;
        state.shipments_loaded = false;
        state.shipments_loading = false;
        state.shipments_loading_errors = null;

        state.orders = [];
        state.orders_count = 0;
        state.orders_loaded = false;
        state.orders_loading = false;
        state.orders_loading_errors = null;

        if (!endcustomersite) {
            state.shipments_filters = { offset: 0, limit: state.shipments_filters.limit };
            state.orders_filters = { offset: 0, limit: state.shipments_filters.limit };
            return;
        }
        state.shipments_filters = {
            endcustomersite: state.endcustomersite.id,
            offset: 0,
            limit: state.shipments_filters.limit,
            ordering: '-delivery_date',
        };
        state.orders_filters = {
            endcustomersite: state.endcustomersite.id,
            offset: 0,
            limit: state.orders_filters.limit,
            ordering: '-creation_date',
        };
    },
    updateStats(state, stats) {
        state.stats = stats;
        state.stats_loading = false;
        state.stats_loading_errors = null;
    },
    updateStatsLoading(state, loading) {
        state.stats = {};
        state.stats_loading = loading;
        state.stats_loading_errors = null;
    },
    updateStatsLoadingErrors(state, stats_loading_errors) {
        state.stats = {};
        state.stats_loading = false;
        state.stats_loading_errors = stats_loading_errors;
    },


    updateEndCustomerSiteLoading(state, loading) {
        state.endcustomersite_loading = loading;
        state.endcustomersite_loading_errors = null;
    },

    updateEndCustomerSiteLoadingErrors(state, errors) {
        state.endcustomersite = null;
        state.endcustomersite_loading = false;
        state.endcustomersite_loading_errors = errors;
    },

    updateEndCustomerSiteSaving(state, saving) {
        state.endcustomersite_saving = saving;
    },

    updateEndCustomerSiteDeleting(state, deleting) {
        state.endcustomersite_deleting = deleting;
    },

    updateShipments(state, data) {
        state.shipments = data.results;
        state.shipments_count = data.count;
        state.shipments_loaded = true;
    },
    updateShipmentsFilters(state, filters) {
        state.shipments_filters = filters;
    },
    updateShipmentsTotalCount(state, total_count) {
        state.shipments_total_count = total_count;
    },
    updateShipmentsLoading(state, loading) {
        state.shipments_loading = loading;
    },
    updateShipmentsLoadingErrors(state, errors) {
        state.shipments_loading_errors = errors;
    },

    updateOrders(state, data) {
        state.orders = data.results;
        state.orders_count = data.count;
        state.orders_loaded = true;
    },
    updateOrdersFilters(state, filters) {
        state.orders_filters = filters;
    },
    updateOrdersLoading(state, loading) {
        state.orders_loading = loading;
    },
    updateOrdersLoadingErrors(state, errors) {
        state.orders_loading_errors = errors;
    },
};

const actions = {

    fetchEndCustomerSiteStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStatsLoading', true);

        return new Promise((resolve, reject) => {
            let url = `/api/endcustomersites/${state.endcustomersite.id}/stats/`;
            axios.get(url)
                .then((response) => {
                    commit('updateStats', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStatsLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    fetchEndCustomerSite({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomerSiteLoading', true);
        let url;
        if (params && params.endcustomersite_id) {
            url = `/api/endcustomersites/${params.endcustomersite_id}/`;
        } else if (state.endcustomersite) {
            url = `/api/endcustomersites/${state.endcustomersite.id}/`;
        } else {
            throw 'No endcustomersite to fetch';
        }

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    commit('updateEndCustomerSite', response.data);
                    resolve(response);
                    dispatch('fetchShipments');
                    dispatch('fetchOrders').catch((error) => { }); // May be normal
                    dispatch('fetchEndCustomerSiteStats');
                    dispatch('documents/init', {
                        filterTag: `endcustomersite:${response.data.id}`,
                        createTags: [
                            `endcustomersite:${response.data.id}`,
                        ]
                    });
                    dispatch('documents/fetchDocuments');
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateEndCustomerSiteLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    deleteEndCustomerSite({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomerSiteDeleting', true);

        const url = `/api/endcustomersites/${state.endcustomersite.id}/`;
        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndCustomerSiteDeleting', false);
                    dispatch('fetchEndCustomerSite');
                })
        });
    },

    saveEndCustomerSite({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomerSiteSaving', true);
        let url = '/api/endcustomersites/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/endcustomersites/${params.instance.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    commit('updateEndCustomerSiteSaving', false);
                    dispatch('fetchCustomerStats');
                    dispatch('session/fetchStats', null, { root: true });
                })
        });
    },

    fetchShipments({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("core.view_shipment") == -1) {
            return;
        }

        commit('updateShipmentsLoading', true);
        commit('updateShipmentsLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/shipments/', { params: state.shipments_filters })
                .then((response) => {
                    commit('updateShipments', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateShipmentsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateShipmentsLoading', false);
                })
        });
    },

    fetchOrders({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateOrdersLoading', true);
        commit('updateOrdersLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/orders/', { params: state.orders_filters })
                .then((response) => {
                    commit('updateOrders', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateOrdersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateOrdersLoading', false);
                })
        });
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        documents: documents(),
    }
};
