import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';

const state = {
    agents: [],
    agents_count: 0,
    agents_filters: { offset: 0, limit: 20, ordering: 'name', profile: 'agent' },
    agents_loading: false,
    agents_loading_errors: null,
    synchronizing_agents: false,
    synchronizing_agents_errors: {}

};

const mutations = {
    updateAgents(state, agents) {
        state.agents = agents;
    },
    updateAgentsCount(state, count) {
        state.agents_count = count;
    },
    updateAgentsLoading(state, loading) {
        state.agents_loading = loading;
    },
    updateAgentsLoadingErrors(state, errors) {
        state.agents_loading_errors = errors;
    },
    updateSynchronizingAgents(state, synchronizing) {
        state.synchronizing_agents = synchronizing;
    },
    updateSynchronizingAgentsErrors(state, errors) {
        state.synchronizing_agents_errors = errors;
    },
    updateAgentsFilters(state, filters) {
        state.agents_filters = filters;
    },
};

const actions = {

    fetchAgents({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateAgentsLoading', true);
        commit('updateAgentsLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/entities/', { params: state.agents_filters })
                .then((response) => {
                    commit('updateAgentsLoading', false);
                    commit('updateAgents', response.data.results);
                    commit('updateAgentsCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateAgentsLoading', false);
                    commit('updateAgentsLoadingErrors', error.details);
                    reject(error);
                });

        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
