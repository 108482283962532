import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    orderitems: [],
    orderitems_url: '/api/orderitems/',
    orderitems_filters: { offset: 0, limit: 20, },
    orderitems_count: 0,
    orderitems_loaded: false,
    orderitems_loading: false,
    orderitems_loading_errors: null,

    orderitems_stats: {},
    orderitems_stats_loaded: false,
    orderitems_stats_loading: false,
    orderitems_stats_loading_errors: null,
});

const mutations = {
    updateOrderItems(state, orderitems) {
        state.orderitems = orderitems;
        state.orderitems_loaded = true;
        state.orderitems_loading = false;
        state.orderitems_loading_errors = null;
    },
    updateOrderItemsUrl(state, orderitems_url) {
        state.orderitems_url = orderitems_url;
    },
    updateOrderItemsCount(state, orderitems_count) {
        state.orderitems_count = orderitems_count;
    },
    updateOrderItemsFilters(state, orderitems_filters) {
        state.orderitems_filters = orderitems_filters;
    },
    updateOrderItemsLoading(state, orderitems_loading) {
        state.orderitems_loading = orderitems_loading;
    },
    updateOrderItemsLoadingErrors(state, orderitems_loading_errors) {
        state.orderitems_loading_errors = orderitems_loading_errors;
        state.orderitems_loading = false;
    },


    updateOrderItemsStats(state, orderitems_stats) {
        state.orderitems_stats = orderitems_stats;
        state.orderitems_stats_loaded = true;
    },
    updateOrderItemsStatsLoading(state, loading) {
        state.orderitems_stats_loading = loading;
    },
    updateOrderItemsStatsLoadingErrors(state, errors) {
        state.orderitems_stats_loading_errors = errors;
    },

};

const actions = {

    fetchOrderItems({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("order.view_orderitem") == -1) {
            return;
        }

        commit('updateOrderItemsLoading', true);
        commit('updateOrderItemsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get(state.orderitems_url, { params: state.orderitems_filters })
                .then((response) => {
                    commit('updateOrderItemsCount', response.data.count);
                    commit('updateOrderItems', response.data.results);
                    dispatch('fetchOrderItemsStats');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateOrderItemsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateOrderItemsLoading', false);
                });

        });
    },


    async fetchOrderItemsStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("order.view_allocation") == -1) {
            return;
        }

        commit('updateOrderItemsStatsLoading', true);
        commit('updateOrderItemsStatsLoadingErrors', null);

        try {
            const response = await axios.get('/api/orderitems/stats/', { params: state.orderitems_filters });
            commit('updateOrderItemsStatsLoading', false);
            commit('updateOrderItemsStats', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateOrderItemsStatsLoadingErrors', error.details);
            commit('updateOrderItemsStatsLoading', false);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateOrderItems', []);
        const default_filters = { offset: 0, limit: 20, ordering: '-creation_date' };
        commit('updateOrderItemsFilters', { ...default_filters, ...(params?.filters || {}) });
        if (params && params.url) {
            commit('updateOrderItemsUrl', params.url);
        }
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});