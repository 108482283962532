var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column overflow-hidden h-100 overflow-hidden"
  }, [_c('v-card-title', {
    staticClass: "mb-0 primary-ultralight-grey"
  }, [_c('span', {
    staticClass: "text-h3 pl-1 text-truncate"
  }, [_vm._t("title"), _vm.helpContentId && _vm.uniqueIdExists ? _c('v-icon', {
    staticClass: "ml-4",
    attrs: {
      "left": ""
    },
    on: {
      "click": _vm.openHelp
    }
  }, [_vm._v("help_outline")]) : _vm._e()], 2)]), _c('v-card-text', {
    staticClass: "v-card__text flex-grow-1 d-flex flex-1 flex-column h-100 pa-0 overflow-hidden"
  }, [_vm._t("rawcontent", function () {
    return [_c('div', {
      staticClass: "overflow-scroll px-2 px-sm-6 pt-4 pb-8"
    }, [_vm._t("content")], 2)];
  }), _c('div', {
    staticClass: "px-2 px-sm-6 py-0 d-sm-flex d-block justify-end primary-ultralight-grey"
  }, [_vm._t("bottom")], 2)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }