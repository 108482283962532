import synchronize from './submodules/synchronize';

const state = {

};


const mutations = {

};

const actions = {

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        synchronize: synchronize(),
    }
};
