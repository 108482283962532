<template>
    <div class="tabsview">
        <v-tabs
            color="primary"
            :value="tabindex"
            :key="JSON.stringify(tabs)"
            prev-icon="mdi-delete-outline"
        >
            <v-tab
                class="px-0 mr-4"
                v-for="(tab, index) in tabs"
                :key="`${index}`"
                :disabled="tab.disabled"
                @change="$emit('change', tab.name)"
            >
                {{ tab.label }}

                <v-chip
                    small
                    v-if="tab.counter"
                    :color="tab.counter_color || 'primary'"
                    class="white--text ml-2"
                >
                    {{ tab.counter }}</v-chip
                >
            </v-tab>
        </v-tabs>
        <v-tabs-items
            :value="tabindex"
            @change="$emit('change', tabs_list[$event])"
            touchless
        >
            <slot name="tabs"> </slot>
        </v-tabs-items>
    </div>
</template>

<script>
export default {
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            required: false,
        },
    },
    data() {
        return { tabs_list: this.tabs.map((item) => item.name) };
    },
    computed: {
        tabindex() {
            return this.tabs_list.indexOf(this.value);
        },
    },
};
</script>

<style lang="scss">
.tabsview {
    .v-tab {
        text-transform: initial !important;
    }
    .v-slide-group__prev {
        display: none !important;
    }
}
</style>
