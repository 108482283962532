import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import billingaccounts from './submodules/billingaccounts';

const state = {
    customer: null,
    customer_saving: false,
    customer_saving_errors: {},

    features: [],
    features_loading: false,
    features_count: 0,
    features_loading_errors: null,

    billingaccount: null,
    billingaccount_loading: false,

    stocks: [],
    stocks_loaded: false,
    stocks_loading_errors: null,
};

const mutations = {
    updateCustomer(state, customer) {
        state.customer = customer;
    },
    updateCustomerSaving(state, saving) {
        state.customer_saving = saving;
    },
    updateCustomerSavingErrors(state, errors) {
        state.customer_saving_errors = errors;
    },
    updateFeatures(state, data) {
        state.features = data.results;
        state.features_count = data.count;
        state.features_loading_errors = {};
        state.features_loading = false;
        state.customer.features = data.results.filter((item) => item.entities > 0).map((item) => item.id);
    },
    updateFeaturesLoading(state, loading) {
        state.features_loading = loading;
        state.features_loading_errors = null;
    },
    updateFeaturesCount(state, count) {
        state.features_count = count;
    },
    updateFeaturesLoadingErrors(state, errors) {
        state.features_loading_errors = errors;
        state.features_loading = false;
    },
    updateCustomerBillingAccountId(state, billingaccount) {
        state.customer.billingaccount = billingaccount;
    },
    updateBillingAccountLoading(state, loading) {
        state.billingaccount_loading = loading;
    },
    updateStocks(state, stocks) {
        state.stocks = stocks;
        if (stocks === null) {
            state.stocks_loaded = false;
            return;
        }

        state.stocks_loaded = true;
        if (state.customer) {
            state.customer.allowed_stocks = stocks.filter((item) => item.allowed).map((item) => item.id);
            if (!state.customer.id) {
                const mainstock = stocks.find((item) => item.main);
                if (mainstock) {
                    state.customer.stocks = [mainstock.id];
                }
            }
        }
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },
};

const actions = {
    async fetchStocks({ commit, state }) {
        commit('updateStocksLoadingErrors', null);
        try {
            const response = await axios.get('/api/stocks/', { params: { customerstock_entity: state.customer.id } });
            commit('updateStocks', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateStocksLoadingErrors', error.details);
            throw error;
        }
    },
    async saveCustomer({ commit, dispatch, state }) {
        commit('updateCustomerSaving', true);
        let url = '/api/entities/';
        let method = axios.post;
        if (state.customer.id) {
            url = `/api/entities/${state.customer.id}/`;
            method = axios.put;
        }
        try {
            const response = await method(url, state.customer);
            commit('updateCustomerSaving', false);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateCustomerSavingErrors', error.details);
            commit('updateCustomerSaving', false);
            throw error;
        }
    },
    async fetchFeatures({ commit, state }) {
        commit('updateFeaturesLoading', true);
        try {
            const response = await axios.get('/api/features/', { params: { profile: 'customer', entity: state.customer.id } });
            commit('updateFeatures', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateFeaturesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateFeaturesLoading', false);
        }
    },
    async fetchBillingAccount({ commit, dispatch }) {
        commit('updateBillingAccountLoading', true);
        try {
            const response = await dispatch('activebillingaccounts/fetchBillingAccounts');
            if (response.data.results.length > 0) {
                commit('updateCustomerBillingAccountId', response.data.results[0].id);
            }
        } finally {
            commit('updateBillingAccountLoading', false);
        }
    },
    async init({ commit, dispatch, state }, params) {
        commit('updateStocks', []);
        commit('updateCustomer', params.customer);
        await dispatch('activebillingaccounts/init', {
            filters: { for_customer: state.customer.id }
        });
        await dispatch('fetchBillingAccount');
        await dispatch('fetchFeatures');
        await dispatch('fetchStocks');
        await dispatch('billingaccounts/fetchBillingAccounts');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        billingaccounts: billingaccounts(),
        activebillingaccounts: billingaccounts(),
    }
};