import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import permissions from './submodules/permissions';
import groups from './submodules/groups';

const state = {
    users: [],
    users_count: 0,
    users_filters: { offset: 0, limit: 20, myentity: true, ordering: 'full_name' },
    users_loading: false,
    users_loading_errors: null,
};

const mutations = {
    updateUsers(state, users) {
        state.users = users;

    },
    updateUsersFilters(state, filters) {
        state.users_filters = filters;
    },
    updateUsersCount(state, count) {
        state.users_count = count;
    },
    updateUsersLoading(state, loading) {
        state.users_loading = loading;
    },
    updateUsersLoadingErrors(state, errors) {
        state.users_loading_errors = errors;
    },
};

const actions = {
    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        dispatch('permissions/init', { profile: params.profile });
        dispatch('permissions/fetchPermissions');
        dispatch('groups/init', { filters: { for_entity: params.entity } });
        dispatch('groups/fetchGroups');
    },

    fetchUsers({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateUsersLoading', true);
        commit('updateUsersLoadingErrors', null);

        return new Promise((resolve, reject) => {

            this.request = axios.get('/api/users/', {
                params: state.users_filters,
            })
                .then((response) => {
                    commit('updateUsers', response.data.results);
                    commit('updateUsersCount', response.data.count);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateUsersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateUsersLoading', false);
                })

        })
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        groups: groups(),
        permissions: permissions(),
    }
};
