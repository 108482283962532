import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import qs from 'qs';

const state = {
    productprices: [],
    productprices_filters: { offset: 0, limit: 20, },
    productprices_count: 0,
    productprices_loading: false,
    productprices_loading_errors: null,

};

const mutations = {
    updateProductPrices(state, productprices) {
        state.productprices = productprices;
    },
    updateProductPricesFilters(state, productprices_filters) {
        state.productprices_filters = productprices_filters;
    },
    updateProductPricesCount(state, productprices_count) {
        state.productprices_count = productprices_count;
    },
    updateProductPricesLoading(state, loading) {
        state.productprices_loading = loading;
    },
    updateProductPricesLoadingErrors(state, errors) {
        state.productprices_loading_errors = errors;
    },
};

const actions = {

    exportProductPricesAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.productprices_filters);
            delete filters.offset;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/productprices/?" + queryparams);
    },

    fetchProductPrices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductPricesLoading', true);
        commit('updateProductPricesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/productprices/', {
                params: state.productprices_filters,
            })
                .then((response) => {
                    commit('updateProductPrices', response.data.results);
                    commit('updateProductPricesCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateProductPricesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductPricesLoading', false);
                })
        });
    },

    updateProductsPrices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        return new Promise((resolve, reject) => {
            axios.put('/api/productsprices/', { payload: params.prices })
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },


};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
