import qs from 'qs';
import store from '@/stores/store';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import documents from './submodules/documents';
import customerstats from './submodules/customerstats';
import groups from './submodules/groups';
import billingaccounts from './submodules/billingaccounts';
import shipments from './submodules/shipments';
import receipts from './submodules/receipts';
import orders from './submodules/orders';

const state = {
    customer: null,
    customer_loading: false,
    customer_saving: false,

    users: [],
    users_filters: { ordering: "full_name" },
    users_loading: false,
    users_loading_errors: null,

    features: [],
    features_loading: false,
    features_count: 0,
    features_loading_errors: null,

    monthly_stats: {},
    monthly_stats_loaded: false,
    monthly_stats_loading: false,
    monthly_stats_loading_errors: null,

    stats: null,
    stats_loading: false,
    stats_loading_errors: null,

    ask_user_creation: false,

    invoices: [],
    invoices_filters: { offset: 0, limit: 20, },
    invoices_count: 0,
    invoices_loading: false,
    invoices_loading_errors: null,

    contractlines: [],
    contractlines_filters: { offset: 0, limit: 20 },
    contractlines_count: 0,
    contractlines_loading: false,
    contractlines_loading_errors: null,

    endcustomers: [],
    endcustomers_filters: { offset: 0, limit: 20, profile: "endcustomer" },
    endcustomers_count: 0,
    endcustomers_loading: false,
    endcustomers_loading_errors: null,

    billingaccount: null,

    catalog: null,
    catalog_fetching: false,
    catalog_fetching_errors: null,

    section: null,

    stocks: [],
    stocks_loaded: false,
    stocks_loading_errors: null,
};

const mutations = {
    updateInvoices(state, data) {
        state.invoices = data.results;
        state.invoices_count = data.count;
    },
    updateSection(state, section) {
        state.section = section;
    },

    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },
    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },
    updateInvoicesFilters(state, filters) {
        state.invoices_filters = filters;
    },

    updateCustomer(state, customer) {

        if (customer && state.customer && customer.id == state.customer.id) {
            state.customer = customer;
            return;
        }
        state.customer = customer;
        state.stats = null;
        state.monthly_stats_loaded = false;
        state.monthly_stats = [];

        state.users = [];
        state.users_loaded = false;

        state.invoices = [];
        state.invoices_count = 0;
        state.invoices_loaded = 0;

        if (!customer) {
            state.users_filters = { offset: 0, limit: state.users_filters.limit, ordering: "full_name" };
            state.invoices_filters = { offset: 0, limit: state.invoices_filters.limit };
            state.endcustomers_filters = { offset: 0, limit: state.endcustomers_filters.limit, profile: "endcustomer" };
            state.contractlines_filters = { offset: 0, limit: state.contractlines_filters.limit };
            return;
        }

        state.users_filters = {
            offset: 0, limit: state.users_filters.limit,
            ordering: "full_name",
            entity: customer.id,
        };
        state.invoices_filters = {
            billed: customer.id,
            offset: 0, limit: state.invoices_filters.limit,
            ordering: '-invoice_date',
        };
        state.endcustomers_filters = {
            parent: customer.id,
            offset: 0, limit: state.endcustomers_filters.limit,
            ordering: 'name',
            profile: "endcustomer"
        };
        state.contractlines_filters = {
            offset: 0,
            limit: state.contractlines_filters.limit,
            entity: customer.id,
        };
    },

    updateAskUserCreation(state, ask) {
        state.ask_user_creation = ask;
    },
    updateCustomerSaving(state, saving) {
        state.customer_saving = saving;
    },
    updateCustomerLoading(state, loading) {
        state.customer_loading = loading;
    },
    updateCustomerLoadingErrors(state, errors) {
        state.customer_loading_errors = errors;
    },

    updateUsers(state, users) {
        state.users = users;
    },
    updateUsersFilters(state, filters) {
        state.users_filters = filters;
    },
    updateUsersLoading(state, loading) {
        state.users_loading = loading;
    },
    updateUsersLoadingErrors(state, errors) {
        state.users_loading_errors = errors;
    },

    updateStats(state, stats) {
        state.stats = stats;
        state.stats_loading = false;
        state.stats_loading_errors = null;
    },
    updateStatsLoading(state, loading) {
        state.stats_loading = loading;
        state.stats_loading_errors = null;
    },
    updateStatsLoadingErrors(state, stats_loading_errors) {
        state.stats = {};
        state.stats_loading = false;
        state.stats_loading_errors = stats_loading_errors;
    },

    updateMonthlyStats(state, stats) {
        state.monthly_stats = stats;
        state.monthly_stats_loaded = true;
    },
    updateMonthlyStatsLoading(state, loading) {
        state.monthly_stats_loading = loading;
    },
    updateMonthlyStatsLoadingErrors(state, errors) {
        state.monthly_stats_loading_errors = errors;
    },

    updateContractLines(state, data) {
        state.contractlines = data.results;
        state.contractlines_count = data.count;
        state.contractlines_loading_errors = {};
        state.contractlines_loading = false;
    },
    updateContractLinesLoading(state, loading) {
        state.contractlines_loading = loading;
        state.contractlines_loading_errors = {};
    },
    updateContractLinesLoadingErrors(state, errors) {
        state.contractlines_loading_errors = errors;
        state.contractlines_loading = false;
    },
    updateContractLinesFilters(state, filters) {
        state.contractlines_filters = filters;
    },

    updateEndCustomers(state, data) {
        state.endcustomers = data.results;
        state.endcustomers_count = data.count;
        state.endcustomers_loading_errors = {};
        state.endcustomers_loading = false;
    },
    updateEndCustomersLoading(state, loading) {
        state.endcustomers_loading = loading;
        state.endcustomers_loading_errors = null;
    },
    updateEndCustomersLoadingErrors(state, errors) {
        state.endcustomers_loading_errors = errors;
        state.endcustomers_loading = false;
    },
    updateEndCustomersFilters(state, filters) {
        state.endcustomers_filters = filters;
    },

    updateFeatures(state, data) {
        state.features = data.results;
        state.features_count = data.count;
        state.features_loading_errors = {};
        state.features_loading = false;
    },
    updateFeaturesLoading(state, loading) {
        state.features_loading = loading;
        state.features_loading_errors = null;
    },
    updateFeaturesCount(state, count) {
        state.features_count = count;
    },
    updateFeaturesLoadingErrors(state, errors) {
        state.features_loading_errors = errors;
        state.features_loading = false;
    },

    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
        state.billingaccount_loading = false;
        state.billingaccount_loading_errors = null;
    },
    updateBillingAccountLoading(state, billingaccount_loading) {
        state.billingaccount_loading = billingaccount_loading;
    },
    updateBillingAccountLoadingErrors(state, billingaccount_loading_errors) {
        state.billingaccount = null;
        state.billingaccount_loading = false;
        state.billingaccount_loading_errors = billingaccount_loading_errors;
    },

    updateFetchingCatalog(state) {
        state.catalog = null;
        state.catalog_fetching = true;
        state.catalog_fetching_errors = null;
    },

    updateCatalog(state, catalog) {
        state.catalog = catalog;
        state.catalog_fetching = false;
    },

    updateFetchingCatalogErrors(state, errors) {
        state.catalog_fetching_errors = errors;
    },

    updateStocks(state, stocks) {
        state.stocks = stocks;
        state.stocks_loaded = true;
        if (state.customer && !state.customer.id) {
            const mainstock = stocks.find((item) => item.main);
            if (mainstock) {
                state.customer.stocks = [mainstock.id];
            }
        }
    },

    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },

};

const actions = {

    exportInvoicesAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.invoices_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/invoices/?" + queryparams);
    },

    async sendConfirmationEmail({ commit }, params) {
        try {
            const response = await axios.post('/api/account/sendconfirmationemail/', { token: params.token });
            commit('updateUser', response.data);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },


    async fetchCatalog({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("catalog.view_catalogassignment") == -1) {
            return;
        }
        if (store.getters['session/current_user_permissions'].indexOf("catalog.view_catalog") == -1) {
            return;
        }

        try {
            commit('updateFetchingCatalog');
            const response = await axios.get('/api/catalogassignments/', { params: { entity: state.customer.id } });
            commit('updateCatalog', response.data.results[0]?.catalog_data);
            return response.data.results[0]?.catalog_data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateFetchingCatalogErrors', error.details);
            throw error;
        }
    },

    async fetchStocks({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStocks', []);
        commit('updateStocksLoadingErrors', null);

        try {
            const response = await axios.get('/api/stocks/');
            commit('updateStocks', response.data.results);
            return response;
        }
        catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateStocksLoadingErrors', error.details);
            throw error;
        }
    },

    fetchInvoices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateInvoicesLoading', true);
        commit('updateInvoicesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/invoices/', { params: state.invoices_filters })
                .then((response) => {
                    commit('updateInvoices', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateInvoicesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateInvoicesLoading', false);
                });
        });
    },

    fetchCustomerStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStatsLoading', true);
        if (!state.customer) {
            return;
        }
        return new Promise((resolve, reject) => {
            let url = `/api/entities/${state.customer.id}/stats/`;
            axios.get(url, { params: { profile: "customer" } })
                .then((response) => {
                    commit('updateStats', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStatsLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    fetchCustomerMonthlyStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateMonthlyStatsLoading', true);

        return new Promise((resolve, reject) => {
            let url = `/api/entities/${state.customer.id}/statsbymonth/`;
            axios.get(url, { params: { profile: "customer" } })
                .then((response) => {
                    commit('updateMonthlyStats', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateMonthlyStatsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateMonthlyStatsLoading', false);
                })
        });
    },

    fetchCustomer({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        commit('updateCustomerLoading', true);

        let url = null;
        if (params && params.customer_id) {
            url = `/api/entities/${params.customer_id}/`;
        } else if (state.customer) {
            url = `/api/entities/${state.customer.id}/`;
        } else {
            throw 'No customer to fetch';
        }

        return new Promise((resolve, reject) => {
            axios.get(url, { params: { profile: "customer", fields: "features_data" } })
                .then((response) => {
                    commit('updateCustomerLoading', false);
                    commit('updateCustomer', response.data);
                    dispatch('fetchBillingAccount');
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    commit('updateCustomerLoading', false);
                    reject(utils.handleError(xhr_error));
                });
        });
    },

    deleteCustomer({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        const url = `/api/entities/${params.instance.id}/`;

        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    dispatch('fetchCustomer');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    fetchUsers({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateUsersLoading', true);

        return new Promise((resolve, reject) => {
            axios.get('/api/users/', { params: state.users_filters })
                .then((response) => {
                    commit('updateUsers', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateUsersLoadingError', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateUsersLoading', false);
                })
        });
    },


    fetchFeatures({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateFeaturesLoading', true);

        return new Promise((resolve, reject) => {
            axios.get('/api/features/', { params: { profile: 'customer' } })
                .then((response) => {
                    commit('updateFeatures', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateFeaturesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateFeaturesLoading', false);
                })
        });
    },


    fetchContractLines({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateContractLines', []);
        commit('updateContractLinesLoading', true);

        return new Promise((resolve, reject) => {

            axios.get("/api/contractlines/", { params: state.contractlines_filters })
                .then((response) => {
                    commit('updateContractLines', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateContractLinesLoadingErrors', error.details);
                    reject(error);
                });
        });
    },

    deleteContractLine({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            const url = `/api/contractlines/${params.instance.id}/`;

            axios.delete(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let errors = utils.handleError(xhr_error);
                    reject(errors);
                });
        });
    },

    fetchEndCustomers({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomers', []);
        commit('updateEndCustomersLoading', true);

        return new Promise((resolve, reject) => {

            axios.get("/api/entities/", { params: state.endcustomers_filters })
                .then((response) => {
                    commit('updateEndCustomers', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateEndCustomersLoadingErrors', error.details);
                    reject(error);
                });
        });
    },

    fetchBillingAccount({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("billing.view_billingaccount") == -1) {
            return;
        }
        dispatch('activebillingaccounts/fetchBillingAccounts').then((response) => {
            if (response.data.results.length > 0) {
                commit('updateBillingAccount', response.data.results[0]);
            }
        });
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateBillingAccount', null);
        commit('updateUsers', []);
        commit('updateFeatures', []);
        commit('updateStats', null);
        commit('updateCatalog', null);

        commit('updateCustomer', params.customer);
        commit('customerstats/updateCustomer', params.customer);

        dispatch('documents/init', {
            filterTag: `customer:${params.customer.id}`,
            createTags: [
                `customer:${params.customer.id}`,
            ]
        });
        dispatch('orders/init', { customer: params.customer.id });
        dispatch('shipments/init', { customer: params.customer.id });
        dispatch('receipts/init', { customer: params.customer.id });
        dispatch('groups/init', { filters: { for_entity: params.customer.id } });
        dispatch('activebillingaccounts/init', {
            filters: { for_customer: params.customer.id }
        });

        dispatch('fetchBillingAccount');
        dispatch('fetchUsers');
        dispatch('fetchFeatures');
        dispatch('fetchCustomerStats');
        dispatch('fetchCatalog');
        dispatch('groups/fetchGroups');
        dispatch('customerstats/fetchStats');
        dispatch('documents/fetchDocuments');
        dispatch('shipments/fetchShipments');
        dispatch('orders/fetchOrders');
        dispatch('receipts/fetchReceipts');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        documents: documents(),
        groups: groups(),
        customerstats: customerstats(),
        activebillingaccounts: billingaccounts(),
        shipments: shipments(),
        receipts: receipts(),
        orders: orders(),
    }
};
