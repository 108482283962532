import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    zones: [],
    loading_zones: false,
    loading_zones_errors: null,
    zoneproducts: [],
    loading_zoneproducts: false,
    loading_zoneproducts_errors: null,
    zonesmap: [],
    loading_zonesmap: false,
    loading_zonesmap_errors: null,
    saving_zone: false,
};

const mutations = {
    updateZones(state, zones) {
        state.zones = zones;
    },
    updateSavingZone(state, saving_zone) {
        state.saving_zone = saving_zone;
    },
    updateLoadingZones(state, loading) {
        state.loading_zones = loading;
    },
    updateLoadingZonesErrors(state, errors) {
        state.loading_zones_errors = errors;
    },
    updateZoneProducts(state, zoneproducts) {
        state.zoneproducts = zoneproducts;
    },
    updateSavingZoneProduct(state, saving_zone) {
        state.saving_zone = saving_zone;
    },
    updateLoadingZoneProducts(state, loading) {
        state.loading_zoneproducts = loading;
    },
    updateLoadingZoneProductsErrors(state, errors) {
        state.loading_zoneproducts_errors = errors;
    },
    updateZonesMap(state, zonesmap) {
        state.zonesmap = zonesmap;
    },
    updateLoadingZonesMap(state, loading) {
        state.loading_zonesmap = loading;
    },
    updateLoadingZonesMapErrors(state, errors) {
        state.loading_zonesmap_errors = errors;
    },
};

const actions = {

    fetchZoneProducts({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateLoadingZoneProducts', true);
        commit('updateLoadingZoneProductsErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/zoneproducts/', {})
                .then((response) => {
                    commit('updateLoadingZoneProducts', false);
                    commit('updateZoneProducts', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateLoadingZoneProductsErrors', error.details);
                    commit('updateLoadingZoneProducts', false);
                    reject(error);
                });
        });
    },

    fetchZones({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateLoadingZones', true);
        commit('updateLoadingZonesErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/zones/', { params: { limit: 1000 } })
                .then((response) => {
                    commit('updateLoadingZones', false);
                    commit('updateZones', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateLoadingZonesErrors', error.details);
                    commit('updateLoadingZones', false);
                    reject(error);
                });
        });
    },

    fetchZonesMap({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateLoadingZonesMap', true);
        commit('updateLoadingZonesMapErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/zonesmap/', {})
                .then((response) => {
                    commit('updateLoadingZonesMap', false);
                    commit('updateZonesMap', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateLoadingZonesMapErrors', error.details);
                    commit('updateLoadingZonesMap', false);
                    reject(error);
                });
        });
    },

    cloneZone({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        return new Promise((resolve, reject) => {
            axios.post(`/api/zones/${params.instance.id}/clone/`, params.instance)
                .then((response) => {
                    dispatch('fetchZonesMap');
                    dispatch('fetchZones');
                    dispatch('fetchZoneProducts');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    deleteZone({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateSavingZone', true);

        return new Promise((resolve, reject) => {
            axios.delete(`/api/zones/${params.instance.id}/`, params.instance)
                .then((response) => {
                    dispatch('fetchZonesMap');
                    dispatch('fetchZones');
                    dispatch('fetchZoneProducts');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    saveZoneProduct({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateSavingZoneProduct', true);

        let url = '/api/zoneproducts/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/zoneproducts/${params.instance.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    commit('updateSavingZoneProduct', false);
                    dispatch('fetchZonesMap');
                    dispatch('fetchZones');
                    dispatch('fetchZoneProducts');
                    resolve(response);

                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateSavingZoneProduct', false);
                    reject(error);
                });
        });
    },

    saveZone({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateSavingZone', true);

        let url = '/api/zones/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/zones/${params.instance.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    commit('updateSavingZone', false);
                    dispatch('fetchZonesMap');
                    dispatch('fetchZones');
                    dispatch('fetchZoneProducts');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateSavingZone', false);
                    reject(error);
                });
        });
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
