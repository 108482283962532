import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    billingaccounts: null,
    billingaccounts_count: 0,
    billingaccounts_loading: false,
    billingaccounts_filters: { offset: 0, limit: 20, ordering: 'name' },
    billingaccounts_loading_errors: null,
};

const mutations = {
    updateBillingAccounts(state, billingaccounts) {
        state.billingaccounts = billingaccounts;
        state.billingaccounts_loading = false;
        state.billingaccounts_loading_errors = null;
    },
    updateBillingAccountsCount(state, count) {
        state.billingaccounts_count = count;
    },
    updateBillingAccountsLoading(state, billingaccounts_loading) {
        state.billingaccounts_loading = billingaccounts_loading;
    },
    updateBillingAccountsLoadingErrors(state, billingaccounts_loading_errors) {
        state.billingaccounts = [];
        state.billingaccounts_loading = false;
        state.billingaccounts_loading_errors = billingaccounts_loading_errors;
    },
    updateBillingAccountsFilters(state, billingaccounts_filters) {
        state.billingaccounts_filters = billingaccounts_filters;
    },
};

const actions = {

    async fetchBillingAccounts({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateBillingAccountsLoading', true);
        commit('updateBillingAccountsLoadingErrors', null);

        try {
            const response = await axios.get('/api/billingaccounts/', { params: state.billingaccounts_filters });
            commit('updateBillingAccounts', response.data.results);
            commit('updateBillingAccountsCount', response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateBillingAccountsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateBillingAccountsLoading', false);
        }
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
