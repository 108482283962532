var render = function render(){
  var _vm$actions;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 d-flex justify-center placeholder",
    style: {
      'min-height': _vm.minHeight
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "flex-column text-center d-flex justify-center"
  }, [_c('MasterSvg', {
    attrs: {
      "use": _vm.icon
    }
  }), _vm.title ? _c('div', {
    staticClass: "placeholder--title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _vm.description || _vm.actions ? _c('div', {
    staticClass: "mt-4 px-md-12 d-flex text-caption text-gs-semibold flex-column align-center"
  }, [_vm.description ? _c('span', {
    staticClass: "placeholder--description"
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")]) : _vm._e(), (_vm$actions = _vm.actions) !== null && _vm$actions !== void 0 && _vm$actions.length ? _c('div', {
    staticClass: "placeholder--actions d-flex flex-column mt-5"
  }, _vm._l(_vm.actions, function (action) {
    return _c('Button', {
      key: action.action,
      staticClass: "mb-4",
      attrs: {
        "text": action.text,
        "disabled": action.disabled,
        "icon": action.icon ? _vm.iconName(action.icon) : null,
        "ghost": !action.main
      },
      on: {
        "click": function click($event) {
          return _vm.onAction(action);
        }
      }
    });
  }), 1) : _vm._e()]) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }