const state = {
    modals: [],
    modal: null,
    modalCount: 0,
};

const mutations = {
    addModal(state, modal) {
        state.modals.push(modal);
        state.modal = modal;
    },

    popModal(state) {
        state.modals.pop();

        if (!state.modals.length) {
            state.modal = null;
            return;
        }

        state.modal = state.modals[state.modals.length - 1]
    }
};

const actions = {
    showModal({ commit, dispatch, state }, modalParams) {
        return new Promise((resolve, reject) => {
            state.modalCount += 1;
            let modal = {
                id: state.modalCount,
                resolve,
                reject,
                component: modalParams.component,
                params: modalParams.params,
            };
            commit('addModal', modal);
        });
    },

    hideModal({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let modal = state.modal;
        commit('popModal');

        if (modal.reject && params && params.error) {
            modal.reject(params.error);
            return;
        }
        if (modal.resolve) {
            if (params && params.result !== undefined) {
                modal.resolve(params && params.result);
            } else {
                modal.resolve(null);
            }
        }
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
