import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    invoices: [],
    invoices_filters: { offset: 0, limit: 20, ordering: '-invoice_date' },
    invoices_count: 0,
    invoices_loading: false,
    invoices_loading_errors: null,
};

const mutations = {
    updateInvoices(state, invoices) {
        state.invoices = invoices;
    },
    updateInvoicesCount(state, invoices_count) {
        state.invoices_count = invoices_count;
    },
    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },
    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },
    updateInvoicesFilters(state, filters) {
        state.invoices_filters = filters;
    },
};

const actions = {
    exportInvoicesAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.invoices_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/invoices/?" + queryparams);
    },

    exportInvoiceItemsAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.filtered) {
            filters.billed = state.invoices_filters.billed;
            filters.invoice_status = state.invoices_filters.status;
            filters.invoice_type = state.invoices_filters.invoice_type;
        }
        filters.myinvoices = 'true';
        filters.format = 'csv';
        filters.limit = 10000;
        filters.ordering = 'billing_date';
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/invoiceitems/?" + queryparams);
    },

    fetchInvoices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateInvoicesLoading', true);
        commit('updateInvoicesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/invoices/', { params: state.invoices_filters })
                .then((response) => {
                    commit('updateInvoices', response.data.results);
                    commit('updateInvoicesCount', response.data.count);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateInvoicesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateInvoicesLoading', false);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
