import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    allocation: {},
    allocation_loading: false,
    allocation_loading_errors: null,
    allocation_saving: false,
    allocation_saving_errors: {},
    allocation_deleting: false,
    allocation_deleting_errors: null,

    allocation_used: null,
    allocation_count: null,
    start_date: null,
    end_date: null,

    products: [],
    products_filters: { limit: 20, offset: 0 },
    products_loading: false,
    products_loading_error: null,

    endcustomers: [],
    endcustomers_loading: false,
    endcustomers_loading_errors: null,

    endcustomer: null,
    product: null,
};


const mutations = {

    updateProducts(state, products) {
        state.products = products;
        state.products_loading = false;
        state.products_loading_errors = null;
    },
    updateProductsLoading(state, products_loading) {
        state.products_loading = products_loading;
    },
    updateProductsLoadingErrors(state, products_loading_errors) {
        state.products_loading_errors = products_loading_errors;
    },
    updateProductsFilters(state, products_filters) {
        state.products_filters = products_filters;
    },

    updateAllocation(state, allocation) {
        state.allocation = allocation;
        state.allocation_loading = false;
        state.allocation_loading_errors = null;

        state.allocation_count = allocation.count;
        state.allocation_ued = allocation.used;

        state.product = allocation.product;
        state.endcustomer = allocation.endcustomer;
        state.start_date = allocation.start_date;
        state.end_date = allocation.end_date;
        state.allocation_used = allocation.used;

        state.products = [];
        state.products_filters = { limit: 20, offset: 0 };
        state.products_loading = false;
        state.products_loading_errors = null;

        state.endcustomers = [];
        state.endcustomers_filters = { limit: 30, ordering: "name", profile: "endcustomer" };
        state.endcustomers_loading = false;
        state.endcustomers_loading_errors = null;
    },
    updateProduct(state, product) {
        state.allocation.product = product;
        state.product = product;
        state.allocation.product_data = state.products.find((item) => item.id == product);
    },
    updateEndcustomer(state, endcustomer) {
        state.allocation.endcustomer = endcustomer;
        state.endcustomer = endcustomer;
    },

    updateAllocationLoading(state) {
        state.allocation_loading = true;
        state.allocation_loading_errors = null;
    },
    updateAllocationLoadingErrors(state, errors) {
        state.allocation_loading = false;
        state.allocation_loading_errors = errors;
    },
    updateAllocationSaving(state, saving) {
        state.allocation_saving = saving;
        state.allocation_saving_errors = {};
    },
    updateAllocationSavingErrors(state, errors) {
        state.allocation_saving = false;
        state.allocation_saving_errors = errors || {};
    },
    updateAllocationDeleting(state, deleting) {
        state.allocation_deleting = deleting;
    },
    updateAllocationDeletingErrors(state, errors) {
        state.allocation_deleting_errors = errors;
    },

    updateMinDate(state, start_date) {
        state.allocation.start_date = start_date;
        state.start_date = start_date;
    },
    updateMaxDate(state, end_date) {
        state.allocation.end_date = end_date;
        state.end_date = end_date;
    },

    updateAllocationUsed(state, used) {
        state.allocation.used = used;
        state.allocation_used = used;
    },
    updateAllocationCount(state, count) {
        state.allocation.count = count;
        state.allocation_count = count;
    },

    updateAllocations(state, allocations) {
        state.allocations = allocations;
    },
    updateAllocationsCount(state, count) {
        state.allocations_count = count;
    },
    updateAllocationsLoading(state, loading) {
        state.allocations_loading = loading;
    },
    updateAllocationsLoadingErrors(state, errors) {
        state.allocations_loading_errors = errors;
    },


    updateEndCustomers(state, endcustomers) {
        state.endcustomers = endcustomers;
    },
    updateEndCustomersFilters(state, endcustomers_filters) {
        state.endcustomers_filters = endcustomers_filters;
    },
    updateEndCustomersLoading(state, loading) {
        state.endcustomers_loading = loading;
    },
    updateEndCustomersLoadingErrors(state, errors) {
        state.endcustomers_loading_errors = errors;
    },
};


const getters = {

};

const actions = {

    fetchAllocation({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateAllocationLoading', true);

        return new Promise((resolve, reject) => {
            let url;
            if (params && params.id) {
                url = `/api/allocations/${params.id}/`;
            } else if (state.allocation) {
                url = `/api/allocations/${state.allocation.id}/`;
            } else {
                throw 'No allocation to fetch';
            }

            axios.get(url)
                .then((response) => {
                    commit('updateAllocation', response.data);
                    dispatch('fetchAllocationEntries');
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateAllocationLoadingErrors', error.details);
                    reject(error)
                });
        });
    },

    fetchAllocationEntry({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateAllocationEntryLoading', true);
        commit('updateAllocationEntryLoadingErrors', null);

        return new Promise((resolve, reject) => {

            let url;
            if (params && params.id) {
                url = `/api/allocationentries/${params.id}/`;
            } else if (state.allocation) {
                url = `/api/allocationentries/${state.allocationentry.id}/`;
            } else {
                throw 'No allocationentry to fetch';
            }

            axios.get(url)
                .then((response) => {
                    commit('updateAllocationEntryLoading', false);
                    commit('updateAllocationEntry', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateAllocationEntryLoading', false);
                    commit('updateAllocationEntryLoadingErrors', error.details);
                    reject(error)
                });
        });
    },

    setAllocationAsDefault({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            const url = `/api/allocations/${state.allocation.id}/default/`;
            axios.post(url)
                .then((response) => {
                    resolve(response);
                    dispatch('fetchAllocation')
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    saveAllocation({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateAllocationSaving', true);

        return new Promise((resolve, reject) => {
            let url = '/api/allocations/';
            let method = axios.post;

            if (params.instance.id) {
                url = `/api/allocations/${params.instance.id}/`;
                method = axios.put;
            }
            method(url, params.instance)
                .then((response) => {
                    commit('updateAllocationSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateAllocationSavingErrors', error.details);
                    reject(error);
                });
        });
    },

    fetchProducts({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductsLoading', true);

        return new Promise((resolve, reject) => {
            axios.get('/api/products/', { params: state.products_filters })
                .then((response) => {
                    commit('updateProducts', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductsLoadingErrors', error.details);
                    reject(error);
                })
        });
    },



    fetchEndCustomers({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomersLoading', true);
        commit('updateEndCustomersLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/entities/', {
                params: state.endcustomers_filters,
            })
                .then((response) => {
                    commit('updateEndCustomers', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateEndCustomersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndCustomersLoading', false);
                });
        });
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateAllocation', params.allocation);
        if (!params.allocation.id) {
            dispatch('fetchEndCustomers');
            dispatch('fetchProducts');
        }
    },

    searchProducts({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductsFilters', { ...state.products_filters, ...{ search: params } });
        dispatch('fetchProducts')
    },

    searchEndCustomer({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomersFilters', { ...state.endcustomers_filters, ...{ search: params } });
        dispatch('fetchEndCustomers')
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
