<template>
    <v-card v-if="readonly && isOperator && status != 'trial'" class="mb-8">
        <v-card-title class="text-h2 pa-3 pa-sm-6">
            <translate>Readonly account</translate>
        </v-card-title>

        <v-card-text class="px-3 px-sm-6">
            <template v-if="status == 'trial-ended'">
                <div>
                    {{
                        $gettext(
                            "Your trial period has ended and your account is currently in readonly mode."
                        )
                    }}
                </div>
                <div>
                    {{
                        $gettext(
                            "Please contact us to activate your account at the following address"
                        )
                    }}
                    <a
                        :href="
                            'mailto:contact@safetee.io?subject=[Safetee] - ' +
                            $gettext('Account activation')
                        "
                    >
                        contact@safetee.io</a
                    >.
                </div>
            </template>
            <template v-else>
                <div>
                    {{
                        $gettext(
                            "Your account is currently in readonly mode. Please contact your sales representative at the following address"
                        )
                    }}
                    <a href="mailto:contact@safetee.io"> contact@safetee.io</a>.
                </div>
            </template>
            <Button
                class="mt-3"
                :text="$gettext('Contact us')"
                :color="'primary'"
                :icon="iconName('email')"
                @click="sendMail"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import GlobalMixin from "@/mixins/global";
import { mapState, mapGetters } from "vuex";

export default {
    mixins: [GlobalMixin],
    computed: Object.assign(
        mapState("session", ["readonly", "status"]),
        mapGetters("session", ["trial_ended", "trial_remaining_days"]),
        {
            alertType() {
                if (this.status == "trial-ended") {
                    return "warning";
                }
                return "error";
            },
        }
    ),
    methods: {
        sendMail() {
            if (this.status == "trial-ended") {
                window.open(
                    `mailto:contact@safetee.io?subject=[Safetee] - ${this.$gettext(
                        "Account activation"
                    )}`
                );
            } else {
                window.open("mailto:contact@safetee.io");
            }
        },
    },
};
</script>
