import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    active_locale: 'fr',
    alerts: [],
    connected: null,
    creating_demo_data: false,
    dashboard_header_color_is_dark: false,
    dashboard_header_color: '#ffffff',
    dashboard_logo: null,
    dashboard_title: 'Safetee',
    demo: null,
    drawer: true,
    entity: null,
    features: [],
    identity_confirmed: null,
    impersonated: null,
    loading_customer_error: null,
    loading_customer: false,
    loading_logininfo_error: null,
    loading_logininfo: false,
    must_confirm_identity: false,
    operator_domain: false,
    permissions: [],
    readonly: false,
    settings: {},
    stats_loaded: false,
    stats_loading_error: null,
    stats_loading: false,
    stats: {},
    status: null,
    user_mode: null,
    user: null,

    organizations: [],
    organizations_loaded: false,
    organizations_loading: false,
    organizations_loading_errors: null,
};


const getters = {
    readonly: state => state.readonly,
    stats: state => state.stats,
    stats_loaded: state => state.stats_loaded,
    trial_ended: state => state.status == 'trial-ended',
    current_user: state => state.user,
    current_entity: state => state.entity,
    current_entity_stats: state => state.stats,
    current_entity_features: state => state.features,
    current_user_language: state => state.user.language,
    current_entity_profile: state => state.entity.profile,
    current_entity_id: state => state.entity.id,
    current_user_connected: state => state.connected,
    current_user_impersonated: state => state.impersonated,
    current_user_identity_confirmed: state => state.identity_confirmed,
    current_user_must_confirm_identity: state => state.must_confirm_identity,
    current_user_demo: state => state.demo,
    current_user_mode: state => state.entity.profile,
    current_user_permissions: state => state.permissions,

    trial_remaining_days(state) {
        if (state.status != 'trial') {
            return null;
        }

        if (!state.trial_end) {
            return null;
        }
        const now = new Date();
        const end = new Date(state.trial_end);
        const diff = end.getTime() - now.getTime();
        return Math.ceil(diff / (1000 * 3600 * 24));
    },
};

const mutations = {

    updateActiveLocale(state, locale) {
        state.active_locale = locale;
    },

    updateStats(state, stats) {
        state.stats = stats;
        state.stats_loaded = true;
    },

    updateUserMode(state, mode) {
        state.user_mode = mode;
    },

    updateStatsLoading(state, loading) {
        state.stats_loading = loading;
    },

    updateStatsLoadingError(state, error) {
        state.stats_loading_error = error;
    },

    updateDrawer(state, drawer) {
        state.drawer = drawer;
    },

    updateLoginInfo(state, info) {
        state.connected = info.connected;
        state.impersonated = info.impersonated;
        state.user = info.user || {};
        state.entity = info.entity;
        state.demo = state.user.demo;
        state.identity_confirmed = info.identity_confirmed || false;
        state.must_confirm_identity = info.must_confirm_identity || false;
        state.dashboard_title = info.dashboard_title;
        state.operator_domain = info.operator_domain;
        state.dashboard_logo = info.dashboard_logo;
        state.dashboard_logo = info.dashboard_logo;
        state.dashboard_header_color = info.dashboard_header_color || '#ffffff';
        state.dashboard_header_color_is_dark = utils.isColorDark(state.dashboard_header_color);
        state.permissions = info.permissions;
        state.features = info.features || [];
        state.readonly = info.readonly;
        state.status = info.status;
        state.trial_end = info.trial_end;
    },

    updateLoadingLoginInfo(state, loading) {
        state.loading_logininfo = loading;
    },

    updateLoadingLoginInfoError(state, error) {
        state.loading_logininfo_error = error;
    },

    updateLoadingCustomer(state, loading) {
        state.loading_customer = loading;
    },

    updateLoadingCustomerError(state, error) {
        state.loading_customer_error = error;
    },

    appendAlert(state, alert) {
        state.alerts.push(alert);
    },

    removeAlert(state, id) {
        state.alerts = state.alerts.filter((item) => {
            return item.id != id;
        });
    },

    updateCreatingDemoData(state, creating) {
        state.creating_demo_data = creating;
    },

    updateSettings(state, settings) {
        state.settings = settings;
    },

    updateOrganizations(state, organizations) {
        state.organizations = organizations;
        state.organizations_loaded = true;
        state.organizations_loading = false;
    },

    updateOrganizationsLoading(state, loading) {
        state.organizations_loading = loading;
    },

    updateOrganizationsLoadingErrors(state, errors) {
        state.organizations_loading_errors = errors;
        state.organizations_loading = false;
    },
};

const actions = {

    activateAgent({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        return new Promise((resolve, reject) => {
            this.request = axios.post(`/api/entities/${state.entity.id}/activateagent/`)
                .then((response) => {
                    dispatch('fetchLoginInfo');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    fetchSettings({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.get('/api/settings/')
                .then((response) => {
                    commit('updateSettings', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    createDemoData({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateCreatingDemoData', true);
        return new Promise((resolve, reject) => {
            axios.post('/api/demo/createdemodata/')
                .then((response) => {
                    commit('updateCreatingDemoData', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    commit('updateCreatingDemoData', false);
                    reject(utils.handleError(xhr_error));
                });
        });
    },

    signIn({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.post('/api/account/login/', { username: params.username, password: params.password })
                .then((response) => {
                    dispatch('fetchLoginInfo');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    async addAlert({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let alert = {};
        let timeout = 2000;
        if (typeof params == 'string') {
            alert.text = params;
        } else {
            alert.text = params.text;
            alert.type = params.type;
            alert.button_route = params.button_route;
            alert.button_text = params.button_text;
            alert.hide_modal = params.hide_modal;
            timeout = params.timeout || timeout;
        }
        alert.id = Math.floor(Math.random() * 1000000);
        commit('appendAlert', alert);
        window.setTimeout(() => {
            commit('removeAlert', alert.id);
        }, timeout);
    },


    signOut({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.delete('/api/account/login/')
                .then((response) => {
                    dispatch('fetchLoginInfo');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    async fetchLoginInfo({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateLoadingLoginInfo', true);
        commit('updateLoadingLoginInfoError', null);
        return new Promise((resolve, reject) => {
            axios.get('/api/account/login/')
                .then((response) => {
                    commit('updateLoginInfo', response.data);
                    resolve(response);
                    if (response.data.connected) {
                        dispatch('fetchStats');
                        dispatch('fetchSettings');
                    }
                    dispatch('help/fetchUniqueIds', null, { root: true })
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateLoadingLoginInfoError', error);
                    reject(error);
                }).finally(() => {
                    commit('updateLoadingLoginInfo', false);
                });
        });
    },

    stopImpersonating({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.delete("/api/account/impersonation/")
                .then(() => {
                    resolve(state.entity);
                })
        });
    },

    async fetchStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        let url = `/api/entities/${state.entity.id}/stats/`;

        commit('updateStatsLoading', true);
        commit('updateStatsLoadingError', null);

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    commit('updateStats', response.data);
                    resolve();
                })
                .catch((xhr_error) => {
                    commit('updateStatsLoadingError', utils.handleError(xhr_error));
                }).finally(() => {
                    commit('updateStatsLoading', false);
                    reject();
                });
        });
    },

    async fetchOrganizations({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        commit('updateOrganizationsLoading', true);

        try {
            let response = await axios.get('/api/organizations/', { params: { limit: 1000 } });
            commit('updateOrganizations', response.data.results);
        } catch (xhr_error) {
            commit('updateOrganizationsLoadingError', utils.handleError(xhr_error));
        }
    },

    impersonateUser({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        return new Promise((resolve, reject) => {
            axios.post(`/api/users/${params.user_id}/impersonate/`)
                .then(resolve)
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                });
        });
    },

    activateOrganization({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        return new Promise((resolve, reject) => {
            axios.post(`/api/organizations/${params.user_id}/activate/`)
                .then(resolve)
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                });
        });
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
