import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    demoaccount: {},
    demoaccount_errors: {},
    demoaccount_saving: false,
};

const mutations = {

    updateDemoAccount(state, demoaccount) {
        state.demoaccount = JSON.parse(JSON.stringify(demoaccount || {}));
        state.demoaccount_errors = {};
        state.demoaccount_saving = false;
    },

    updateDemoAccountErrors(state, errors) {
        state.demoaccount_errors = errors;
    },

    updateDemoAccountSaving(state, saving) {
        state.demoaccount_saving = saving;
    },

};

const actions = {

    saveDemoAccount({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let url = '/api/demoaccounts/';
        let method = axios.post;

        if (state.demoaccount.id) {
            url = `/api/demoaccounts/${state.demoaccount.id}/`;
            method = axios.put;
        }

        commit('updateDemoAccountErrors', {});
        commit('updateDemoAccountSaving', true);
        return new Promise((resolve, reject) => {
            method(url, state.demoaccount)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateDemoAccountErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateDemoAccountSaving', false);
                    dispatch('session/fetchStats', null, { root: true });
                })
        });
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
