import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    endcustomers: [],
    endcustomers_loading: false,
    endcustomers_loading_errors: null,

    endcustomersites: [],
    endcustomersites_loading: false,
    endcustomersites_loading_errors: null,

    orders: [],
    orders_loading: false,
    orders_loading_errors: null,

    orderitem: {},
    orderitem_saving: false,
    orderitem_saving_errors: {},

    product: {},
    products_filters: {},
};

const mutations = {
    updateEndCustomers(state, endcustomers) {
        state.endcustomers = endcustomers;
    },
    updateEndCustomersLoading(state, loading) {
        state.endcustomers_loading = loading;
    },
    updateEndCustomersLoadingErrors(state, errors) {
        state.endcustomers_loading_errors = errors;
    },

    updateEndCustomerSites(state, endcustomersites) {
        state.endcustomersites = endcustomersites;
    },
    updateEndCustomerSitesLoading(state, loading) {
        state.endcustomersites_loading = loading;
    },
    updateEndCustomerSitesLoadingErrors(state, errors) {
        state.endcustomersites_loading_errors = errors;
    },

    updateOrders(state, orders) {
        state.orders = orders;
    },
    updateOrdersLoading(state, loading) {
        state.orders_loading = loading;
    },
    updateOrdersLoadingErrors(state, errors) {
        state.orders_loading_errors = errors;
    },

    updateOrderItemSaving(state, saving) {
        state.orderitem_saving = saving;
    },
    updateOrderItemSavingErrors(state, errors) {
        state.orderitem_saving_errors = errors;
    },

    updateOrderItem(state, orderitem) {
        state.orderitem = orderitem;
        state.orders_filters = {
            exclude_readonly: true,
            ready: false,
            ordering: "order_date",
            limit: 1000,
        }

        state.products_filters = {
            product: state.orderitem.product
        };
        state.orderitem_saving_errors = [];
    },

    updateStockProduct(state, product) {
        state.product = product;
    },
};
const actions = {

    async fetchEndCustomerSites({ commit, rootGetters, state }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_endcustomersite") === -1) {
            return;
        }

        commit('updateEndCustomerSitesLoading', true);
        commit('updateEndCustomerSitesLoadingErrors', null);

        const endcustomer_id = params.endcustomer_id;

        try {
            const response = await axios.get(`/api/endcustomersites/?endcustomer=${endcustomer_id}`, { params: { limit: 1000 } });
            commit('updateEndCustomerSites', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEndCustomerSitesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateEndCustomerSitesLoading', false);
        }
    },

    async fetchOrders({ commit, rootGetters, state }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("order.view_order") === -1) {
            return;
        }

        commit('updateOrdersLoading', true);
        commit('updateOrdersLoadingErrors', null);

        try {
            const response = await axios.get('/api/orders/', { params: { ready: false, limit: 1000 } });
            commit('updateOrders', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateOrdersLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateOrdersLoading', false);
        }
    },

    async fetchEndCustomers({ commit, rootGetters, state }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_endcustomer") === -1) {
            return;
        }

        commit('updateEndCustomersLoading', true);
        commit('updateEndCustomersLoadingErrors', null);

        try {
            const response = await axios.get("/api/entities/", { params: { limit: 1000, ordering: 'name', profile: "endcustomer" } });
            commit('updateEndCustomers', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEndCustomersLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateEndCustomersLoading', false);
        }
    },

    async saveOrderItem({ commit, rootGetters, state }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("order.add_orderitem") === -1 && !state.orderitem.id) {
            return;
        }
        if (rootGetters['session/current_user_permissions'].indexOf("order.change_orderitem") === -1 && state.orderitem.id) {
            return;
        }

        commit('updateOrderItemSaving', true);
        commit('updateOrderItemSavingErrors', {});

        let url = '/api/orderitems/';
        let method = axios.post;

        if (state.orderitem.id) {
            url = `/api/orderitems/${state.orderitem.id}/`;
            method = axios.put;
        }
        try {
            const response = await method(url, state.orderitem);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateOrderItemSavingErrors', error.details);
            throw error;
        } finally {
            commit('updateOrderItemSaving', false);
        }
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
