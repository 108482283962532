import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import groups from './submodules/groups';

const state = {
    user: null,
    user_groups: [],
    user_loading: false,
    user_deleting: false,
    user_saving: false,
    user_saving_errors: {},
};

const mutations = {
    updateUser(state, user) {
        state.user = user;
    },

    updateUserLoading(state, loading) {
        state.user_loading = loading;
    },

    updateUserLoadingErrors(state, errors) {
        state.user_loading_errors = errors;
    },

    updateUserSaving(state, saving) {
        state.user_saving = saving;
    },

    updateUserSavingErrors(state, errors) {
        state.user_saving_errors = errors;
    },


    updateUserDeleting(state, deleting) {
        state.user_deleting = deleting;
    },

    updateUserGroups(state, groups) {
        state.user_groups = groups;
    },
};

const actions = {
    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.user.entity) {
            filters.for_entity = params.user.entity;
        } else {
            filters.myentity = true;
        }

        commit('updateUser', params.user);
        commit('updateUserGroups', params.user?.groups || []);
        dispatch('groups/init', { filters });
        dispatch('groups/fetchGroups').then((response) => {
            if (response.data.results.length == 1) {
                commit('updateUserGroups', [response.data.results[0].id]);
            }
        });
    },

    fetchUser({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateUserLoadingErrors', null);
        commit('updateUserLoading', true);

        return new Promise((resolve, reject) => {
            axios.get(`/api/users/${params.user_id}/`)
                .then((response) => {
                    commit('updateUserLoading', false);
                    commit('updateUser', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateUserLoading', false);
                    commit('updateUserLoadingErrors', error.details);
                    reject(error);
                });
        })
    },

    saveUser({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateUserSaving', true);

        let url = '/api/users/';
        let method = axios.post;

        if (state.user.id) {
            url = `/api/users/${state.user.id}/`;
            method = axios.put;
        }
        let payload = Object.assign(state.user, { groups: state.user_groups });
        return new Promise((resolve, reject) => {

            method(url, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let errors = utils.handleError(xhr_error);
                    commit('updateUserSavingErrors', errors.details);
                    reject(errors);
                })
                .finally(() => {
                    commit('updateUserSaving', false);
                })

        })
    },

    deleteUser({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateUserDeleting', true);

        return new Promise((resolve, reject) => {
            axios.delete(`/api/users/${params.instance.id}/`)
                .then(resolve)
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    commit('updateUserDeleting', false);
                });
        });
    },

    recoverPassword({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.post("/api/account/recover/", { email: params.instance.email })
                .then(resolve)
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
        });
    },

    async deactivate({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateUserSaving', true);

        let url = `/api/users/${params.instance.id}/deactivate/`;
        let method = axios.post;

        try {
            const response = await method(url);
            commit('updateUserSaving', false);
            return response;
        } catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit('updateUserSavingErrors', error.details);
            commit('updateUserSaving', false);
            throw error;
        }
    },

    async activate({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateUserSaving', true);

        let url = `/api/users/${params.instance.id}/activate/`;
        let method = axios.post;

        try {
            const response = await method(url);
            commit('updateUserSaving', false);
            return response;
        } catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit('updateUserSavingErrors', error.details);
            commit('updateUserSaving', false);
            throw error;
        }
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        groups: groups(),
    }
};




