import store from '@/stores/store';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    product: null,
    product_loading: false,
    product_loading_errors: null,
    product_deleting: false,

    product_saving: false,
    product_saving_errors: {},

    producttype: null,
    producttypes: [],
    producttypes_loading: false,
    producttypes_loading_errors: null,

    providers: [],
    providers_loading: false,
    providers_count: 0,
    providers_loading_errors: null,

    productprices: [],
    productprices_count: 0,
    productprices_loading: false,
    productprices_loading_errors: null,
    productprices_saving: false,
    productprices_saving_errors: null,
};


function cleanupMetadata(metadata, producttype) {
    if (!metadata || !producttype) {
        return metadata;
    }
    const new_metadata = {};
    for (const key in metadata) {
        const field = producttype.fields.find((item) => item.code === key);
        if (!field) {
            continue;
        }
        const value = metadata[key];
        if (field.type === 'boolean') {
            new_metadata[key] = value === 'true' || value === true;
        } else if (field.type === 'float') {
            const float = parseFloat(value);
            new_metadata[key] = isNaN(float) ? null : float;
        } else if (field.type === 'integer' || field.type === 'number') {
            const int = parseInt(value);
            new_metadata[key] = isNaN(int) ? null : int;
        } else if (field.type === 'string') {
            new_metadata[key] = value;
        } else if (field.type === 'choices') {
            if (field.choices.includes(value)) {
                new_metadata[key] = value;
            } else {
                new_metadata[key] = null;
            }
        } else {
            console.log(`field ${key} has unknown type ${field.type}`);
            new_metadata[key] = value;
        }
    }
    return new_metadata;
}

const mutations = {

    updateProduct(state, product) {
        state.product_saving = false;
        state.product_saving_errors = {};

        if (product && state.product && product.id && state.product.id) {
            state.product = product;
            return;
        }
        state.providers = [];
        state.providers_count = 0;
        state.providers_loading_errors = null;
        state.product = product;
        state.producttype = product.producttype;
        state.productprices = [],
            state.productprices_count = 0;
    },

    updateProductType(state, producttype) {
        state.producttype = producttype;
        state.product.producttype = producttype;
    },

    updateProductLoading(state, loading) {
        state.product_loading = loading;
    },
    updateProductLoadingErrors(state, errors) {
        state.product_loading_errors = errors;
    },

    updateProductSavingErrors(state, errors) {
        state.product_saving = false;
        state.product_saving_errors = errors;
    },
    updateProductSaving(state, saving) {
        state.product_saving = saving;
    },

    updateProductTypes(state, producttypes) {
        state.producttypes = producttypes;
    },
    updateProductTypesLoading(state, loading) {
        state.producttypes_loading = loading;
    },
    updateProductTypesLoadingErrors(state, errors) {
        state.producttypes_loading_errors = errors;
    },

    updateProviders(state, providers) {
        state.providers = providers;
    },
    updateProvidersLoading(state, loading) {
        state.providers_loading = loading;
    },
    updateProvidersCount(state, count) {
        state.providers_count = count;
    },
    updateProvidersLoadingErrors(state, errors) {
        state.providers_loading_errors = errors;
    },

    updateProductPrices(state, productprices) {
        state.productprices = productprices;
    },
    updateProductPricesCount(state, count) {
        state.productprices_count = count;
    },
    updateProductPricesLoading(state, loading) {
        state.productprices_loading = loading;
    },
    updateProductPricesLoadingErrors(state, errors) {
        state.productprices_loading_errors = errors;
    },
    updateProductPricesSavingErrors(state, errors) {
        state.productprices_saving = false;
        state.productprices_saving_errors = errors;
    },
    updateProductPricesSaving(state, saving) {
        state.productprices_saving = saving;
    },
};

const actions = {


    async fetchProduct({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductLoading', true);
        let url = null;
        if (params && params.product_id) {
            url = `/api/products/${params.product_id}/`;
        } else if (state.product) {
            url = `/api/products/${state.product.id}/`;
        } else {
            throw "no product to fetch";
        }
        try {
            const response = await axios.get(url);
            commit('updateProduct', response.data);
            dispatch('fetchMovementsTotalCount');
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductLoading', false);
        }
    },

    async saveProduct({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let url = '/api/products/';
        let method = axios.put;
        const producttype = state.producttypes.find((item) => item.id === state.product.producttype);
        state.product.metadata = cleanupMetadata(state.product.metadata, producttype);
        if (state.product?.id) {
            url = `/api/products/${state.product.id}/`;
            delete (state.product.providers);
            method = axios.patch;
        } else {
            url = '/api/products/';
            method = axios.post;
        }

        try {
            const response = await method(url, state.product);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit('updateProductSavingErrors', error.details);
            throw error;
        }
    },


    async saveProductPrices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductPricesSaving', true);

        try {
            const url = `/api/products/${state.product.id}/`;
            const response = await axios.patch(url, { providers: state.productprices });
            dispatch('fetchProductPrices');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductPricesSavingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductPricesSaving', false);
        }
    },

    fetchProductPrices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductPricesLoading', true);
        commit('updateProductPricesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/productprices/', { params: { product: state.product.id } })
                .then((response) => {
                    commit('updateProductPrices', response.data.results);
                    commit('updateProductPricesCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductPricesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductPricesLoading', false);
                })
        });
    },

    fetchProductTypes({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductTypesLoading', true);
        commit('updateProductTypesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/producttypes/', { params: { limit: 100 } })
                .then((response) => {
                    commit('updateProductTypes', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductTypesLoading', false);
                })
        });
    },

    fetchProviders({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProvidersLoading', true);
        commit('updateProvidersLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/providers/', {
                params: { limit: 1000, ordering: 'name' },
            })
                .then((response) => {
                    commit('updateProvidersLoading', false);
                    commit('updateProviders', response.data.results);
                    commit('updateProvidersCount', response.data.count);

                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateProvidersLoadingErrors', error.details);
                    commit('updateProvidersLoading', false);
                    reject(error);
                });
        });
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        dispatch('fetchProductTypes');
        if (store.getters['session/current_entity_profile'] == 'customer') {
            dispatch('fetchProviders');
        }
        let product = Object.assign(
            { metadata: {}, providers: [{}], packaging: store.state.session.settings.default_packaging },
            JSON.parse(
                JSON.stringify(params.product || { providers: [{}], metadata: {} })
            )
        );
        commit('updateProduct', product);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};

