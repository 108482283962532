var render = function render(){
  var _vm$error, _vm$error2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "flex-grow-1 d-flex flex-column",
    attrs: {
      "elevation": 1
    }
  }, [_c('v-card-title', {
    staticClass: "text-h2 error--text"
  }, [_vm._v(" " + _vm._s(_vm.$gettext("Failed to load ressource")) + " ")]), _c('v-card-text', [((_vm$error = _vm.error) === null || _vm$error === void 0 ? void 0 : _vm$error.code) == 404 ? [_c('span', {
    staticClass: "text-h7"
  }, [_vm._v(" " + _vm._s(_vm.$gettext("This ressource does not exist. It may have been deleted or it does not belong to your organization.")) + " ")])] : ((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.code) == 403 ? [_c('span', {
    staticClass: "text-h7"
  }, [_vm._v(" " + _vm._s(_vm.$gettext("You don't have the permission to access this ressource. Please contact your administrator.")) + " ")])] : [_c('span', {
    staticClass: "text-h7"
  }, [_vm._v(" " + _vm._s(_vm.$gettext("An error occured while loading this ressource. Please contact your administrator.")) + " ")])], _c('div', {
    staticClass: "mt-5"
  }, [_c('Button', {
    attrs: {
      "text": _vm.$gettext('Back to home')
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/');
      }
    }
  })], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }