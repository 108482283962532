import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import allocations from './submodules/allocations';
import orderitems from "./submodules/orderitems";
import shipmentitems from "./submodules/shipmentitems";
import receiptitems from "./submodules/receiptitems";

const state = {
    product: null,
    product_loading: false,
    product_loading_errors: null,
    product_deleting: false,

    product_saving: false,
    product_saving_errors: {},

    producttypes: [],
    producttypes_loading: false,
    producttypes_loading_errors: null,

    evolution: null,
    evolutionPeriod: null,
    evolutionLoading: false,
    evolutionLoadingErrors: {},

    movements: [],
    movements_filters: { limit: 20 },
    movements_count: 0,
    movements_total_count: null,
    movements_loading: false,
    movements_loaded: false,
    movements_loading_errors: null,

    productpictures: [],
    productpictures_count: 0,
    productpictures_loading: false,
    productpictures_loading_errors: null,

    productpicture_saving: false,
    productpicture_saving_errors: null,

    providers: [],
    providers_loading: false,
    providers_count: 0,
    providers_loading_errors: null,

    productprices: [],
    productprices_count: 0,
    productprices_loading: false,
    productprices_loading_errors: null,
    productprices_saving: false,
    productprices_saving_errors: null,

    stockproducts: [],
    stockproducts_fetching_errors: null,

    section: null,
};

let getMovementsFilters = function (product, filters) {
    return { product: product.id };
};


const mutations = {

    updateProduct(state, product) {
        state.product_saving = false;
        state.product_saving_errors = {};

        if (product && state.product && product.id && state.product.id) {
            state.product = product;
            return;
        }
        state.product = product;

        state.movements = [];
        state.movements_count = 0;
        state.movements_total_count = null;
        state.movements_loaded = false;

        state.productpictures = [];
        state.productpictures_count = 0;

        state.productprices = [],
            state.productprices_count = 0,

            state.stockproducts = [];
        state.movements_filters = { ordering: "-creation_date", limit: state.movements_filters.limit, offset: 0 };
    },

    updateSection(state, section) {
        state.section = section;
    },

    updateProductLoading(state, loading) {
        state.product_loading = loading;
    },
    updateProductLoadingErrors(state, errors) {
        state.product_loading_errors = errors;
    },

    updateProductSavingErrors(state, errors) {
        state.product_saving = false;
        state.product_saving_errors = errors;
    },
    updateProductSaving(state, saving) {
        state.product_saving = saving;
    },
    updateProductTypes(state, producttypes) {
        state.producttypes = producttypes;
    },
    updateProductTypesLoading(state, loading) {
        state.producttypes_loading = loading;
    },
    updateProductTypesLoadingErrors(state, errors) {
        state.producttypes_loading_errors = errors;
    },

    updateProductDeleting(state, deleting) {
        state.product_deleting = deleting;
    },

    updateMovements(state, data) {
        state.movements = data.results;
        state.movements_count = data.count;
        state.movements_loaded = true;
    },
    updateMovementsTotalCount(state, total_count) {
        state.movements_total_count = total_count;
    },
    updateMovementsLoading(state, loading) {
        state.movements_loading = loading;
    },
    updateMovementsLoadingErrors(state, errors) {
        state.movements_loading_errors = errors;
    },
    updateMovementsFilters(state, filters) {
        state.movements_filters = filters;
    },

    updateEvolution(state, evolution) {
        state.evolution = {
            labels: evolution.labels.map((label) => new Date(label)),
            datasets: evolution.datasets,
        }
        state.evolutionLoading = false;
        state.evolutionLoadingErrors = {};
    },
    updateEvolutionPeriod(state, evolutionPeriod) {
        state.evolution = null;
        state.evolutionPeriod = evolutionPeriod;
    },
    updateEvolutionLoading(state, evolutionLoading) {
        state.evolutionLoading = evolutionLoading;
    },
    updateEvolutionLoadingErrors(state, evolutionLoadingErrors) {
        state.evolutionLoading = false;
        state.evolutionLoadingErrors = evolutionLoadingErrors;
    },

    updateProviders(state, providers) {
        state.providers = providers;
    },
    updateProvidersLoading(state, loading) {
        state.providers_loading = loading;
    },
    updateProvidersCount(state, count) {
        state.providers_count = count;
    },
    updateProvidersLoadingErrors(state, errors) {
        state.providers_loading_errors = errors;
    },

    updateProductPrices(state, productprices) {
        state.productprices = productprices;
        state.productprices_saving_errors = null;
    },
    updateProductPricesCount(state, count) {
        state.productprices_count = count;
    },
    updateProductPricesLoading(state, loading) {
        state.productprices_loading = loading;
    },
    updateProductPricesLoadingErrors(state, errors) {
        state.productprices_loading_errors = errors;
    },
    updateProductPricesSavingErrors(state, errors) {
        state.productprices_saving = false;
        state.productprices_saving_errors = errors;
    },
    updateProductPricesSaving(state, saving) {
        state.productprices_saving = saving;
    },

    updateProductPictures(state, productpictures) {
        state.productpictures = productpictures;
    },
    updateProductPicturesCount(state, count) {
        state.productpictures_count = count;
    },
    updateProductPicturesLoading(state, loading) {
        state.productpictures_loading = loading;
    },
    updateProductPicturesLoadingErrors(state, errors) {
        state.productpictures_loading_errors = errors;
    },

    updateProductPictureSavingErrors(state, errors) {
        state.productpicture_saving_errors = errors;
    },
    updateProductPictureSaving(state, saving) {
        state.productpicture_saving = saving;
    },

    updateStockProducts(state, stockproducts) {
        state.stockproducts = stockproducts;
        state.stockproducts_fetching_errors = null;
    },
    updateStockProductsFetchingErrors(state, errors) {
        state.stockproducts_fetching_errors = errors;
    },
};

const actions = {

    async fetchEvolution({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEvolutionLoading', true);
        try {
            const response = await axios.get('/api/stats/stock/byproduct/', { params: { start: state.evolutionPeriod?.start, end: state.evolutionPeriod?.end, product: state.product.id } });
            commit('updateEvolution', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEvolutionLoadingErrors', error.details);
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateEvolutionLoading', false);
        }
    },

    async fetchMovementsTotalCount({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = Object.assign(getMovementsFilters(state.product, {}), { limit: 0 });

        try {
            const response = await axios.get("/api/movements/", { params: filters });
            commit('updateMovementsTotalCount', response.data.count);
            await dispatch('fetchMovements');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateMovementsLoadingErrors', error.details);
            throw error;
        }
    },

    async fetchProduct({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductLoading', true);
        let url = null;
        if (params && params.product_id) {
            url = `/api/products/${params.product_id}/`;
        } else if (state.product) {
            url = `/api/products/${state.product.id}/`;
        } else {
            throw "no product to fetch";
        }
        try {
            const response = await axios.get(url);
            commit('updateProduct', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductLoading', false);
        }
    },

    async saveProduct({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let url = '/api/products/';
        let method = axios.put;

        if (state.product?.id) {
            url = `/api/products/${state.product.id}/`;
        } else {
            url = '/api/products/';
            method = axios.post;
        }

        try {
            const response = await method(url, state.product);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async patchProduct({ commit }, params) {
        commit('updateProductSaving', true);

        try {
            const url = `/api/products/${params.instance.id}/`;
            const response = await axios.patch(url, params.instance);
            commit('updateProduct', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductSavingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductSaving', false);
        }
    },

    async saveProductPrices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductPricesSaving', true);

        try {
            const url = `/api/products/${state.product.id}/`;
            const response = await axios.patch(url, { providers: state.productprices });
            dispatch('fetchProductPrices');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductPricesSavingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductPricesSaving', false);
        }
    },

    async deleteProduct({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductDeleting', true);

        const url = `/api/products/${params.instance.id}/`;
        try {
            const response = await axios.delete(url);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateProductDeleting', false);
        }
    },


    async fetchMovements({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (!state.product) {
            return;
        }
        commit('updateMovementsLoading', true);
        commit('updateMovementsLoadingErrors', null);

        try {
            const response = await axios.get('/api/movements/', { params: Object.assign({}, state.movements_filters, getMovementsFilters(state.product)) });
            commit('updateMovements', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateMovementsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateMovementsLoading', false);
        }
    },

    async fetchProductPictures({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductPicturesLoading', true);
        commit('updateProductPicturesLoadingErrors', null);

        try {
            const response = await axios.get('/api/productpictures/', { params: { limit: 100, product: state.product.id } });
            commit('updateProductPictures', response.data.results);
            commit('updateProductPicturesCount', response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductPicturesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductPicturesLoading', false);
        }
    },


    fetchProductPrices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductPricesLoading', true);
        commit('updateProductPricesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/productprices/', { params: { product: state.product.id } })
                .then((response) => {
                    commit('updateProductPrices', response.data.results);
                    commit('updateProductPricesCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductPricesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductPricesLoading', false);
                })
        });
    },

    fetchProductTypes({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductTypesLoading', true);
        commit('updateProductTypesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/producttypes/', { params: { limit: 100 } })
                .then((response) => {
                    commit('updateProductTypes', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductTypesLoading', false);
                })
        });
    },

    fetchProviders({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProvidersLoading', true);
        commit('updateProvidersLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/providers/', {
                params: { limit: 1000, ordering: 'name' },
            })
                .then((response) => {
                    commit('updateProvidersLoading', false);
                    commit('updateProviders', response.data.results);
                    commit('updateProvidersCount', response.data.count);

                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateProvidersLoadingErrors', error.details);
                    commit('updateProvidersLoading', false);
                    reject(error);
                });
        });
    },

    saveProductPicture({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (!params || !params.instance) {
            return;
        }

        let headers = {};
        let data = params.instance;
        let url = '/api/productpictures/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/productpictures/${params.instance.id}/`;
            method = params.patch ? axios.patch : axios.put;
        } else {
            headers = { 'Content-Type': 'multipart/form-data' };
            data = new FormData();
            for (let key in params.instance) {
                if (params.instance[key]) {
                    data.append(key, params.instance[key]);
                }
            }
        }

        commit('updateProductPictureSavingErrors', null);
        commit('updateProductPictureSaving', true);

        return new Promise((resolve, reject) => {
            method(url, data, { headers })
                .then((response) => {
                    resolve({ productpicture: response.data });
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductPictureSavingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    commit('updateProductPictureSaving', false);
                })
        });
    },


    deleteProductPicture({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        const url = `/api/productpictures/${params.instance.id}/`;

        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    fetchStockProducts({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {

            axios.get('/api/stockproducts/', { params: { product: state.product.id } })
                .then((response) => {
                    commit('updateStockProducts', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStockProductsFetchingErrors', error.details);
                    reject(error);
                });
        });
    },

    setEvolutionPeriod({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEvolutionPeriod', params);
        dispatch('fetchEvolution');
    },

    activateAllocation({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.patch(`/api/products/${state.product.id}/`, { requires_allocation: true })
                .then((response) => {
                    resolve(response);
                    dispatch('fetchProduct');
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    async init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        dispatch('allocations/init', {
            filters: { product: params.product.id },
        });
        dispatch('orderitems/init', {
            filters: { product: params.product.id },
        });
        dispatch('shipmentitems/init', {
            filters: { product: params.product.id },
        });
        dispatch('receiptitems/init', {
            filters: { product: params.product.id },
        });
        commit('updateProduct', params.product);

    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        allocations: allocations(),
        orderitems: orderitems(),
        shipmentitems: shipmentitems(),
        receiptitems: receiptitems(),
    },
};

