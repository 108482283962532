<template>
    <v-card :elevation="elevation" class="flex-grow-1 d-flex flex-column">
        <v-card-title class="pb-0 d-flex flex-wrap flex-sm-nowrap" v-if="title">
            <div class="d-flex align-center flex-grow-1 ellipsis" v-if="title">
                <span class="mr-4">{{ title }}</span>
            </div>
            <slot name="title" />
            <Select
                class="flex-grow-0 w-sm-100 mb-0 ml-4"
                :value="preset"
                @input="$emit('preset-changed', $event)"
                :outlined="true"
                :dense="true"
                :clearable="false"
                :options="periods"
            />
        </v-card-title>
        <v-card-text class="flex-grow-1 d-flex flex-column">
            <component
                v-if="!isEmpty"
                :is="chartComponent"
                :x-axis-type="xAxisType"
                :y-axis-type="yAxisType"
                :unit="unit"
                :height="height"
                :key="JSON.stringify(chartData)"
                :chart-data="chartData || {}"
                :aspect-ratio="aspectRatio"
                :chart-id="chartId"
            />
            <Loading :absolute="false" :size="height" v-if="loading" />
            <slot name="empty" v-else-if="isEmpty">
                <div class="my-12 d-flex flex-column justify-center align-center">
                    <MasterSvg use="nodata" />
                    <div class="mt-2">{{ $translate("noData") }}</div>
                </div>
            </slot>
        </v-card-text>
    </v-card>
</template>

<script>
import utils from "@/utils.js";
import Line from "@/components/charts/line";
import Doughnut from "@/components/charts/doughnut";
import Bar from "@/components/charts/bar";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        preset: {
            type: String,
            required: true,
        },
        unit: {
            type: String,
            required: false,
            default: "",
        },
        chartData: {
            type: Object,
            required: true,
        },
        chartId: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "line",
        },
        title: {
            type: String,
            required: false,
        },
        xAxisType: {
            type: String,
            required: false,
        },
        yAxisType: {
            type: String,
            required: false,
        },
        height: {
            type: Number,
            default: 200,
        },
        aspectRatio: {
            type: Number,
            default: 2.5,
        },
        elevation: {
            type: Number,
            default: 0,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        chartComponent() {
            if (this.type == "doughnut") {
                return Doughnut;
            }
            if (this.type == "bar") {
                return Bar;
            }
            return Line;
        },
        periods() {
            return [
                {
                    text: this.$translate("last12Months"),
                    value: "last12months",
                },
                {
                    text: this.$translate("thisYear"),
                    value: "thisyear",
                },
                {
                    text: this.$translate("lastYear"),
                    value: "lastyear",
                },
                {
                    text: this.$translate("allTime"),
                    value: "alltime",
                },
            ];
        },
        isEmpty() {
            if (!this.chartData || !this.chartData.datasets) {
                return true;
            }
            return !this.chartData.datasets.reduce((total, dataset) => {
                return total + (dataset.data ? dataset.data.length : 0);
            }, 0);
        },
    },
    methods: {
        getPeriod(value) {
            return new Promise((resolve, reject) => {
                if (value == "thisyear") {
                    resolve({
                        start: this.$moment().startOf("year").format("YYYY-MM-DD"),
                    });
                } else if (value == "alltime") {
                    resolve({
                        start: null,
                        end: null,
                    });
                } else if (value == "lastyear") {
                    resolve({
                        start: this.$moment()
                            .startOf("year")
                            .subtract(1, "day")
                            .startOf("year")
                            .format("YYYY-MM-DD"),
                        end: this.$moment()
                            .startOf("year")
                            .subtract(1, "day")
                            .format("YYYY-MM-DD"),
                    });
                } else if (value == "last12months") {
                    resolve({
                        start: this.$moment().subtract(1, "year").format("YYYY-MM-DD"),
                    });
                }
            });
        },
        periodChanged(value) {
            this.getPeriod(value).then((period) => {
                this.$emit("period-changed", period);
            });
        },
    },
    watch: {
        preset: {
            immediate: true,
            handler: function (value) {
                this.periodChanged(value);
            },
        },
    },
    data() {
        return {
            utils: utils,
        };
    },
    i18n: {
        messages: {
            en: {
                last12Months: "Last 12 months",
                thisYear: "This year",
                lastYear: "Last year",
                allTime: "All time",
                noData: "No data to display",
            },
            fr: {
                last12Months: "Les 12 derniers mois",
                thisYear: "Cette année",
                lastYear: "L'année dernière",
                allTime: "Depuis toujours",
                noData: "Aucune donnée à afficher",
            },
        },
    },
};
</script>
