import axios from '@/plugins/axios';
import store from '@/stores/store';
import utils from '@/stores/utils';
import documents from './submodules/documents';
import allocations from './submodules/allocations';
import groups from './submodules/groups';
import billingaccounts from './submodules/billingaccounts';

const state = {
    endcustomer: null,
    endcustomer_loading: false,
    endcustomer_loading_errors: null,

    stats: null,
    stats_loading: false,
    stats_loading_errors: null,

    features: [],
    features_loading: false,
    features_count: 0,
    features_loading_errors: null,

    users: [],
    users_filters: {},
    users_loading: false,
    users_count: 0,
    users_loading_errors: null,

    shipments: [],
    shipments_count: 0,
    shipments_filters: { offset: 0, limit: 20, ordering: '-delivery_date' },
    shipments_loading: false,
    shipments_loaded: false,
    shipments_loading_errors: null,

    orders: [],
    orders_count: 0,
    orders_filters: { offset: 0, limit: 20, ordering: '-validated_date' },
    orders_loading: false,
    orders_loaded: false,
    orders_loading_errors: null,

    endcustomersites: [],
    endcustomersites_filters: { offset: 0, limit: 20, },
    endcustomersites_count: 0,
    endcustomersites_loaded: false,
    endcustomersites_loading: false,
    endcustomersites_loading_errors: null,

    invoices: [],
    invoices_filters: { offset: 0, limit: 20, },
    invoices_count: 0,
    invoices_loading: false,
    invoices_loading_errors: null,

    contractlines: [],
    contractlines_filters: { offset: 0, limit: 20 },
    contractlines_count: 0,
    contractlines_loaded: false,
    contractlines_loading: false,
    contractlines_loading_errors: null,

    billingaccount: null,

    billingaccounts: [],
    billingaccounts_loading: false,
    billingaccounts_loading_errors: null,

    catalog: null,
    catalog_fetching: false,
    catalog_fetching_errors: null,

    section: null,
};

const mutations = {
    updateInvoices(state, data) {
        state.invoices = data.results;
        state.invoices_count = data.count;
    },
    updateInvoicesCount(state, invoices_count) {
        state.invoices_count = invoices_count;
    },
    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },
    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },
    updateInvoicesFilters(state, filters) {
        state.invoices_filters = filters;
    },

    updateContractLines(state, data) {
        state.contractlines = data.results;
        state.contractlines_count = data.count;
        state.contractlines_loading_errors = {};
        state.contractlines_loading = false;
        state.contractlines_loaded = true;
    },
    updateContractLinesLoading(state, loading) {
        state.contractlines_loading = loading;
        state.contractlines_loading_errors = {};
    },
    updateContractLinesLoadingErrors(state, errors) {
        state.contractlines_loading_errors = errors;
        state.contractlines_loading = false;
    },
    updateContractLinesFilters(state, filters) {
        state.contractlines_filters = filters;
    },

    updateEndCustomer(state, endcustomer) {
        if (state.endcustomer && endcustomer && state.endcustomer.id == endcustomer.id) {
            state.endcustomer = endcustomer;
            return;
        }
        state.endcustomer = endcustomer;
        state.users = [];
        state.users_loaded = false;
        state.users_count = 0;

        state.stats = {};
        state.stats_loading = false;
        state.stats_loading_errors = null;

        state.shipments = [];
        state.shipments_count = 0;
        state.shipments_loaded = false;
        state.shipments_loading = false;
        state.shipments_loading_errors = null;

        state.orders = [];
        state.orders_count = 0;
        state.orders_loaded = false;
        state.orders_loading = false;
        state.orders_loading_errors = null;

        state.endcustomersites = [];
        state.endcustomersites_count = 0;
        state.endcustomersites_loaded = false;
        state.endcustomersites_loading = false;
        state.endcustomersites_loading_errors = null;

        if (!endcustomer) {
            state.users_filters = { offset: 0, limit: state.users_filters.limit };
            state.orders_filters = { offset: 0, limit: state.orders_filters.limit };
            state.shipments_filters = { offset: 0, limit: state.shipments_filters.limit };
            state.endcustomersites_filters = { offset: 0, limit: state.endcustomersites_filters.limit };
            state.contractlines_filters = { offset: 0, limit: state.contractlines_filters.limit };
            state.invoices_filters = { offset: 0, limit: state.contractlines_filters.limit };
            return;
        }
        state.users_filters = {
            offset: 0,
            limit: state.users_filters.limit,
            entity: endcustomer.id,
        };
        state.orders_filters = {
            offset: 0,
            limit: state.orders_filters.limit,
            endcustomer: state.endcustomer.id,
            ordering: '-validated_date',
        };
        state.shipments_filters = {
            offset: 0,
            limit: state.shipments_filters.limit,
            endcustomer: state.endcustomer.id,
            ordering: '-delivery_date',
        };
        state.endcustomersites_filters = {
            offset: 0,
            limit: state.endcustomersites_filters.limit,
            endcustomer: state.endcustomer.id,
            ordering: 'name',
        };
        state.contractlines_filters = {
            offset: 0,
            limit: state.contractlines_filters.limit,
            entity: state.endcustomer.id,
            ordering: 'name',
        };
        state.invoices_filters = {
            offset: 0,
            limit: state.invoices_filters.limit,
            billed: state.endcustomer.id,
            ordering: '-invoice_date',
        };
    },
    updateUsers(state, data) {
        state.users = data.results;
        state.users_count = data.count;
    },
    updateUsersFilters(state, filters) {
        state.users_filters = filters;
    },
    updateUsersLoading(state, loading) {
        state.users_loading = loading;
    },
    updateUsersLoadingErrors(state, errors) {
        state.users_loading_errors = errors;
    },


    updateStats(state, stats) {
        state.stats = stats;
        state.stats_loading = false;
        state.stats_loading_errors = null;
    },
    updateStatsLoading(state, loading) {
        state.stats_loading = loading;
        state.stats_loading_errors = null;
    },
    updateStatsLoadingErrors(state, stats_loading_errors) {
        state.stats = {};
        state.stats_loading = false;
        state.stats_loading_errors = stats_loading_errors;
    },


    updateEndCustomerLoading(state, loading) {
        state.endcustomer_loading = loading;
    },
    updateEndCustomerLoadingErrors(state, errors) {
        state.endcustomer_loading_errors = errors;
    },

    updateEndCustomerDeleting(state, deleting) {
        state.endcustomer_deleting = deleting;
    },

    updateOrders(state, data) {
        state.orders = data.results;
        state.orders_count = data.count;
        state.orders_loaded = true;
        state.orders_loading = false;
        state.orders_loading_errors = null;
    },
    updateOrdersFilters(state, filters) {
        state.orders_filters = filters;
    },
    updateOrdersLoading(state, loading) {
        state.orders_loading = loading;
        state.orders_loading_error = null;
    },
    updateOrdersLoadingErrors(state, errors) {
        state.orders_loading = false;
        state.orders_loading_errors = errors;
    },

    updateShipments(state, shipments) {
        state.shipments = shipments;
        state.shipments_loaded = true;
    },
    updateShipmentsCount(state, count) {
        state.shipments_count = count;
    },
    updateShipmentsFilters(state, filters) {
        state.shipments_filters = filters;
    },
    updateShipmentsLoading(state, loading) {
        state.shipments_loading = loading;
    },
    updateShipmentsLoadingErrors(state, errors) {
        state.shipments_loading_errors = errors;
    },

    updateEndCustomerSites(state, data) {
        state.endcustomersites = data.results;
        state.endcustomersites_count = data.count;
        state.endcustomersites_loaded = true;
    },
    updateEndCustomerSitesLoading(state, loading) {
        state.endcustomersites_loading = loading;
    },
    updateEndCustomerSitesLoadingErrors(state, errors) {
        state.endcustomersites_loading_errors = errors;
    },
    updateEndCustomerSitesFilters(state, filters) {
        state.endcustomersites_filters = filters;
    },

    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
        state.billingaccount_loading = false;
        state.billingaccount_loading_errors = null;
    },
    updateBillingAccountLoading(state, billingaccount_loading) {
        state.billingaccount_loading = billingaccount_loading;
    },
    updateBillingAccountLoadingErrors(state, billingaccount_loading_errors) {
        state.billingaccount = null;
        state.billingaccount_loading = false;
        state.billingaccount_loading_errors = billingaccount_loading_errors;
    },

    updateFeatures(state, data) {
        state.features = data.results;
        state.features_count = data.count;
        state.features_loading_errors = {};
        state.features_loading = false;
    },
    updateFeaturesLoading(state, loading) {
        state.features_loading = loading;
        state.features_loading_errors = null;
    },
    updateFeaturesCount(state, count) {
        state.features_count = count;
    },
    updateFeaturesLoadingErrors(state, errors) {
        state.features_loading_errors = errors;
        state.features_loading = false;
    },

    updateFetchingCatalog(state) {
        state.catalog = null;
        state.catalog_fetching = true;
        state.catalog_fetching_errors = null;
    },

    updateCatalog(state, catalog) {
        state.catalog = catalog;
        state.catalog_fetching = false;
    },

    updateFetchingCatalogErrors(state, errors) {
        state.catalog_fetching_errors = errors;
    },

    updateSection(state, section) {
        state.section = section;
    },
};

const actions = {

    async fetchFeatures({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("core.view_feature") == -1) {
                return;
            }

            commit('updateFeaturesLoading', true);

            const response = await axios.get('/api/features/', { params: { profile: 'endcustomer' } })
            commit('updateFeatures', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateFeaturesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateFeaturesLoading', false);
        }
    },

    async fetchInvoices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("billing.view_invoice") == -1) {
                return;
            }

            commit('updateInvoicesLoading', true);
            commit('updateInvoicesLoadingErrors', null);

            const response = await axios.get('/api/invoices/', { params: state.invoices_filters })
            commit('updateInvoices', response.data);
            commit('updateInvoicesCount', response.data.count);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateInvoicesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateInvoicesLoading', false);
        }
    },


    async fetchContractLines({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("billing.view_contractline") == -1) {
                return;
            }

            commit('updateContractLinesLoading', true);

            const response = await axios.get("/api/contractlines/", { params: state.contractlines_filters })
            commit('updateContractLines', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateContractLinesLoadingErrors', error.details);
            throw error;
        }
    },


    async deleteContractLine({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("billing.delete_contractline") == -1) {
                return;
            }

            const url = `/api/contractlines/${params.instance.id}/`;

            await axios.delete(url)
                .then((response) => {
                    return response;
                })
        } catch (xhr_error) {
            let errors = utils.handleError(xhr_error);
            throw errors;
        }
    },


    async fetchUsers({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("core.view_user") == -1) {
                return;
            }
            commit('updateUsersLoading', true);

            const response = await axios.get('/api/users/', { params: state.users_filters })
            commit('updateUsers', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateUsersLoadingError', error.details);
            throw error;
        } finally {
            commit('updateUsersLoading', false);
        }
    },


    async fetchEndCustomerStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (!state.endcustomer) {
                return;
            }
            commit('updateStatsLoading', true);
            let url = `/api/entities/${state.endcustomer.id}/stats/`;
            const response = await axios.get(url)
            commit('updateStats', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateStatsLoadingErrors', error.details);
            throw error;
        }
    },


    async fetchEndCustomer({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("core.view_entity") == -1) {
                return;
            }
            let url;

            if (params && params.endcustomer_id) {
                url = `/api/entities/${params.endcustomer_id}/`;
            } else if (state.endcustomer) {
                url = `/api/entities/${state.endcustomer.id}/`;
            } else {
                throw 'No endcustomer to fetch';
            }
            commit('updateEndCustomerLoading', true);

            const response = await axios.get(url, { params: { fields: "features_data" } })
            commit('updateEndCustomer', response.data);
            dispatch('fetchEndCustomerStats');
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEndCustomerLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateEndCustomerLoading', false);
        }
    },


    async deleteEndCustomer({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("core.delete_entity") == -1) {
                return;
            }

            commit('updateEndCustomerDeleting', true);

            const url = `/api/entities/${state.endcustomer.id}/`;
            await axios.delete(url)
                .then((response) => {
                    dispatch('fetchEndCustomer');
                    return response;
                })
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateEndCustomerDeleting', false);
            dispatch('fetchEndCustomer');
        }
    },


    async fetchShipments({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("core.view_shipment") == -1) {
                return;
            }
            commit('updateShipmentsLoading', true);
            commit('updateShipmentsLoadingErrors', null);

            const response = await axios.get('/api/shipments/', { params: state.shipments_filters })
            commit('updateShipments', response.data.results);
            commit('updateShipmentsCount', response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateShipmentsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateShipmentsLoading', false);
        }
    },


    async fetchOrders({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("order.view_order") == -1) {
                throw "No permission to view orders";
            }
            commit('updateOrdersLoading', true);

            const response = await axios.get('/api/orders/', { params: state.orders_filters })
            commit('updateOrders', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateOrdersLoadingErrors', error.details);
            throw error;
        }
    },


    async fetchEndCustomerSites({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("core.view_endcustomersite") == -1) {
                throw "No permission to view end customer sites";
            }

            commit('updateEndCustomerSitesLoading', true);
            commit('updateEndCustomerSitesLoadingErrors', null);

            const response = await axios.get("/api/endcustomersites/", { params: state.endcustomersites_filters })
            commit('updateEndCustomerSites', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEndCustomerSitesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateEndCustomerSitesLoading', false);
        }
    },


    async deleteEndCustomerSite({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("core.delete_endcustomersite") == -1) {
                return;
            }

            const url = `/api/endcustomersites/${params.instance.id}/`;
            await axios.delete(url)
                .then((response) => {
                    dispatch('fetchEndCustomerSites');
                    return response;
                })
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            dispatch('session/fetchStats', null, { root: true });
        }
    },


    async fetchBillingAccounts({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("billing.view_billingaccount") == -1) {
                return;
            }

            commit('updateBillingAccountsLoading', true);
            commit('updateBillingAccountsLoadingErrors', null);

            const response = await axios.get('/api/billingaccounts/')
            commit('updateBillingAccounts', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateBillingAccountsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateBillingAccountsLoading', false);
        }
    },


    async fetchCatalog({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("catalog.view_catalogassignment") == -1) {
                return;
            }
            if (store.getters['session/current_user_permissions'].indexOf("catalog.view_catalog") == -1) {
                return;
            }

            commit('updateFetchingCatalog');
            const response = await axios.get('/api/catalogassignments/', { params: { entity: state.endcustomer.id } });
            commit('updateCatalog', response.data.results[0]?.catalog_data);
            return response.data.results[0]?.catalog_data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateFetchingCatalogErrors', error.details);
            throw error;
        }
    },


    async fetchBillingAccount({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            if (store.getters['session/current_user_permissions'].indexOf("billing.view_billingaccount") == -1) {
                return;
            }

            dispatch('activebillingaccounts/fetchBillingAccounts').then((response) => {
                if (response.data.results.length > 0) {
                    commit('updateBillingAccount', response.data.results[0]);
                }
            });
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateBillingAccountLoadingErrors', error.details);
            throw error;
        }
    },


    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateUsers', []);
        commit('updateShipments', []);
        commit('updateFeatures', []);
        commit('updateStats', null);
        commit('updateCatalog', null);
        commit('updateBillingAccount', null);
        commit('updateEndCustomer', params.endcustomer);

        dispatch('groups/init', { filters: { for_entity: params.endcustomer.id } });
        dispatch('groups/fetchGroups');
        dispatch('activebillingaccounts/init', {
            filters: { for_customer: params.endcustomer.id }
        });
        dispatch('allocations/init', {
            filters: { endcustomer: params.endcustomer.id },
        });
        dispatch('documents/init', {
            filterTag: `endcustomer:${params.endcustomer.id}`,
            createTags: [
                `endcustomer:${params.endcustomer.id}`,
            ]
        });

        dispatch('documents/fetchDocuments');
        dispatch('allocations/fetchAllocations');
        dispatch('fetchEndCustomerSites');
        dispatch('fetchBillingAccount');
        dispatch('fetchShipments');
        dispatch('fetchUsers');
        dispatch('fetchFeatures');
        dispatch('fetchCatalog');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        documents: documents(),
        allocations: allocations(),
        groups: groups(),
        activebillingaccounts: billingaccounts(),
    }
};
