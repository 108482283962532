import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';

const createState = () => ({
    movements: [],
    movements_filters: { offset: 0, limit: 20, ordering: '-creation_date' },
    movements_count: 0,
    movements_loaded: false,
    movements_loading: false,
    movements_loading_errors: null,
    movements_cancel_source: null,

    movements_stats: {},
    movements_stats_loaded: false,
    movements_stats_loading: false,
    movements_stats_loading_errors: null,
    movements_stats_cancel_source: null,

    movement_deleting: false,
    movement_deleting_errors: null,
});


const mutations = {
    updateMovements(state, movements) {
        state.movements = movements;
        state.movements_loaded = true;
    },
    updateMovementsCount(state, count) {
        state.movements_count = count;
    },
    updateMovementsFilters(state, filters) {
        state.movements_filters = filters;
    },
    updateMovementsLoading(state, loading) {
        state.movements_loading = loading;
    },
    updateMovementsLoadingErrors(state, errors) {
        state.movements_loading_errors = errors;
    },
    updateMovementsCancelSource(state, cancelSource) {
        state.movements_cancel_source = cancelSource;
    },

    updateMovementsStats(state, movements_stats) {
        state.movements_stats = movements_stats;
        state.movements_stats_loaded = true;
    },
    updateMovementsStatsLoading(state, loading) {
        state.movements_stats_loading = loading;
    },
    updateMovementsStatsLoadingErrors(state, errors) {
        state.movements_stats_loading_errors = errors;
    },
    updateMovementsStatsCancelSource(state, cancelSource) {
        state.movements_stats_cancel_source = cancelSource;
    },

    updateMovementDeleting(state, deleting) {
        state.movement_deleting = deleting;
    },
    updateMovementDeletingErrors(state, errors) {
        state.movement_deleting_errors = errors;
    },
    updateMovementsLoaded(state, loaded) {
        state.movements_loaded = loaded;
    },
};

const actions = {

    exportMovementsAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.movements_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/movements/?" + queryparams);
    },

    exportInventoryAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.movements_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/movements/inventory/?" + queryparams);
    },

    async fetchMovements({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateMovementsLoading', true);
        commit('updateMovementsLoadingErrors', null);

        if (state.movements_cancel_source) {
            state.movements_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateMovementsCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/movements/', {
                params: state.movements_filters,
                cancelToken: state.movements_cancel_source?.token
            });
            commit('updateMovementsLoading', false);
            commit('updateMovements', response.data.results);
            commit('updateMovementsCount', response.data.count);
            commit('updateMovementsCancelSource', null);
            dispatch('fetchMovementsStats');
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateMovementsLoadingErrors', error.details);
            commit('updateMovementsLoading', false);
            throw error;
        } finally {
            commit('updateMovementsLoading', false);
        }
    },

    async fetchMovementsStats({ commit, state }) { // eslint-disable-line no-unused-vars
        commit('updateMovementsStatsLoading', true);
        commit('updateMovementsStatsLoadingErrors', null);

        if (state.movements_stats_cancel_source) {
            state.movements_stats_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateMovementsStatsCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/movements/stats/', {
                params: state.movements_filters,
                cancelToken: state.movements_stats_cancel_source?.token
            });
            commit('updateMovementsStatsLoading', false);
            commit('updateMovementsStats', response.data);
            commit('updateMovementsStatsCancelSource', null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateMovementsStatsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateMovementsStatsLoading', false);
        }
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateMovementsLoaded', false);
        commit('updateMovements', []);
        commit('updateMovementsCount', 0);
        commit('updateMovementsFilters', { ...{ offset: 0, limit: 20 }, ...params?.filters || {} });
    }
};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});