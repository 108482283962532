import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    receiptitems: [],
    receiptitems_url: '/api/receiptitems/',
    receiptitems_filters: { offset: 0, limit: 20, },
    receiptitems_count: 0,
    receiptitems_loaded: false,
    receiptitems_loading: false,
    receiptitems_loading_errors: null,

    receiptitems_stats: {},
    receiptitems_stats_loaded: false,
    receiptitems_stats_loading: false,
    receiptitems_stats_loading_errors: null,
});

const mutations = {
    updateReceiptItems(state, receiptitems) {
        state.receiptitems = receiptitems;
        state.receiptitems_loaded = true;
        state.receiptitems_loading = false;
        state.receiptitems_loading_errors = null;
    },
    updateReceiptItemsUrl(state, receiptitems_url) {
        state.receiptitems_url = receiptitems_url;
    },
    updateReceiptItemsCount(state, receiptitems_count) {
        state.receiptitems_count = receiptitems_count;
    },
    updateReceiptItemsFilters(state, receiptitems_filters) {
        state.receiptitems_filters = receiptitems_filters;
    },
    updateReceiptItemsLoading(state, receiptitems_loading) {
        state.receiptitems_loading = receiptitems_loading;
    },
    updateReceiptItemsLoadingErrors(state, receiptitems_loading_errors) {
        state.receiptitems_loading_errors = receiptitems_loading_errors;
        state.receiptitems_loading = false;
    },

    updateReceiptItemsStats(state, receiptitems_stats) {
        state.receiptitems_stats = receiptitems_stats;
        state.receiptitems_stats_loaded = true;
    },
    updateReceiptItemsStatsLoading(state, loading) {
        state.receiptitems_stats_loading = loading;
    },
    updateReceiptItemsStatsLoadingErrors(state, errors) {
        state.receiptitems_stats_loading_errors = errors;
    },

};

const actions = {

    fetchReceiptItems({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("core.view_receiptitem") == -1) {
            return;
        }

        commit('updateReceiptItemsLoading', true);
        commit('updateReceiptItemsLoadingErrors', null);
        if (!state.receiptitems_filters.product) {
            throw "toto";
        }
        return new Promise((resolve, reject) => {
            axios.get(state.receiptitems_url, { params: state.receiptitems_filters })
                .then((response) => {
                    commit('updateReceiptItemsCount', response.data.count);
                    commit('updateReceiptItems', response.data.results);
                    dispatch('fetchReceiptItemsStats');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateReceiptItemsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateReceiptItemsLoading', false);
                });

        });
    },

    async fetchReceiptItemsStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("order.view_allocation") == -1) {
            return;
        }

        commit('updateReceiptItemsStatsLoading', true);
        commit('updateReceiptItemsStatsLoadingErrors', null);

        try {
            const response = await axios.get('/api/receiptitems/stats/', { params: state.receiptitems_filters });
            commit('updateReceiptItemsStatsLoading', false);
            commit('updateReceiptItemsStats', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateReceiptItemsStatsLoadingErrors', error.details);
            commit('updateReceiptItemsStatsLoading', false);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateReceiptItems', []);
        const default_filters = { offset: 0, limit: 20, ordering: '-creation_date' };
        commit('updateReceiptItemsFilters', { ...default_filters, ...(params?.filters || {}) });
        if (params && params.url) {
            commit('updateReceiptItemsUrl', params.url);
        }
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});