import Vue from 'vue';
import confetti from "canvas-confetti";


const ICONS = {
    // Generic
    account: "mdi-account-cog",
    address: "mdi-map-marker",
    addtoorder: "mdi-basket-outline",
    addtoreceipt: "mdi-dolly",
    addtoshipment: "mdi-truck-fast-outline",
    agent: "mdi-account",
    alert: "mdi-alert-circle-check-outline",
    allocation: "mdi-star-outline",
    api: "mdi-api",
    blinky: "mdi-wall-sconce-flat-variant",
    cancel: "mdi-cancel",
    catalog: "mdi-book-open-outline",
    check: "mdi-check",
    checkall: "mdi-check-all",
    code: "mdi-barcode",
    csv: "mdi-file-delimited",
    default: "mdi-cards-heart-outline",
    delete: "mdi-delete-outline",
    download: "mdi-file-download-outline",
    duplicate: "mdi-content-copy",
    edit: "mdi-pencil",
    email: "mdi-email",
    euro: "mdi-currency-eur",
    eye: "mdi-eye-outline",
    feature: "mdi-cards-playing-heart-multiple-outline",
    home: "mdi-home",
    instructions: "mdi-message-badge-outline",
    invoice: "mdi-receipt-outline",
    items: "mdi-cube-unfolded",
    login: "mdi-login",
    magnify: "mdi-magnify",
    missing: "mdi-alert-circle",
    mobilephone: "mdi-cellphone",
    movement: "mdi-plus-minus-variant",
    name: "mdi-card-account-details-outline",
    note: "mdi-note",
    order: "mdi-basket-outline",
    orders: "mdi-basket-outline",
    pdf: "mdi-file-pdf-box",
    phone: "mdi-phone",
    placed: "mdi-bookshelf",
    plus: "mdi-plus",
    printer: "mdi-printer",
    product: "mdi-cube-outline",
    products: "mdi-cube-outline",
    receipthandled: "mdi-dolly",
    refund: "mdi-cash-refund",
    release: "mdi-star",
    reload: "mdi-reload",
    search: "mdi-magnify",
    shipmentprepared: "mdi-truck-fast-outline",
    shipped: "mdi-truck-fast-outline",
    site: "mdi-map-marker",
    status: "mdi-state-machine",
    take: "mdi-star-outline",
    // Product specific
    stock: "mdi-counter",
    stockid: "mdi-music-accidental-sharp",
    stocklocation: "mdi-map-outline",
    upc: "mdi-ticket-confirmation-outline",
    packaging: "mdi-inbox-multiple-outline",
    producttype: "mdi-format-list-bulleted-type",

    shipment: "mdi-truck-delivery-outline",
    shipments: "mdi-truck-delivery-outline",
    receipt: "mdi-dolly",
    receipts: "mdi-dolly",
    billing: "mdi-currency-eur",
    price: "mdi-currency-eur",
    settings: "mdi-cog-outline",
    date: "mdi-calendar",
    delay: "mdi-calendar-plus",

    // Entities
    operator: "mdi-map-marker",
    customer: "mdi-account-box-outline",
    customers: "mdi-account-box-multiple-outline",
    endcustomer: "mdi-account-box-outline",
    endcustomers: "mdi-account-box-multiple-outline",
    endcustomersite: "mdi-domain",
    provider: "mdi-guy-fawkes-mask",
    providers: "mdi-drama-masks",
    manufacturer: "mdi-hammer",
    year: "mdi-calendar",
    siren: "domain",
    vat: "domain",

};

export default {
    triggerFireworks() {
        confetti({
            particleCount: 100,
            spread: 100,
            origin: { y: 0.8 },
            scalar: 1.5,
        });
    },
    getOrderStatusText(item) {
        if (item.status == "waiting" && !item.ready) {
            return Vue.prototype.$gettext("Draft");
        }
        return this.getStatusText(item.status);
    },
    getOrderStatusColor(order) {
        if (!order.ready) {
            return "error";
        }
        if (order.status == "completed") {
            return "success";
        }
        if (order.status == "canceled") {
            return "warning";
        }
        return "default";
    },
    getStatusColor(status) {
        return (
            {
                complete: "success",
                delivered: "success",
                canceled: "gray",
                waiting: "warning",
            }[status] || "grey"
        );
    },
    toPercent(value) {
        return Math.round(value * 100000) / 1000;
    },
    customerTypeText(type) {
        const CUSTOMER_TYPES_TEXTS = {
            'individual': Vue.prototype.$gettext('Individual'),
            'company': Vue.prototype.$gettext('Company'),
        };

        return CUSTOMER_TYPES_TEXTS[type];
    },

    icon(code) {
        if (!ICONS[code]) {
            console.warn("Unknown icon : " + code);
            return code;
        }
        return ICONS[code];
    },

    formatNumberToString(value, unit) {
        if (typeof value === 'number') {
            if (Number.isInteger(value)) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (unit ? ' ' + unit : '');
            } else {
                return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (unit ? ' ' + unit : '');
            }
        } else {
            return value + (unit ? ' ' + unit : '');
        }
    },


    price(value) {
        if (value === null) {
            return "- €";
        }
        return this.formatNumberToString(value, '€');
    },
    getStatusText(status) {
        const statusTexts = {
            'canceled': Vue.prototype.$gettext('Canceled'),
            'closed': Vue.prototype.$gettext('Closed'),
            'complete': Vue.prototype.$gettext('Complete'),
            'completed': Vue.prototype.$gettext('Completed'),
            'delivered': Vue.prototype.$gettext('Delivered'),
            'exhausted': Vue.prototype.$gettext('Exhausted'),
            'handled': Vue.prototype.$gettext('Handled'),
            'handling': Vue.prototype.$gettext('Handling'),
            'missing': Vue.prototype.$gettext('Missing'),
            'new': Vue.prototype.$gettext('New'),
            'opened': Vue.prototype.$gettext('Opened'),
            'partial': Vue.prototype.$gettext('Partial'),
            'pending': Vue.prototype.$gettext('Pending'),
            'picked_up': Vue.prototype.$gettext('Picked up'),
            'prepared': Vue.prototype.$gettext('Prepared'),
            'preparing': Vue.prototype.$gettext('Preparing'),
            'processed': Vue.prototype.$gettext('Processed'),
            'processing': Vue.prototype.$gettext('Processing'),
            'shipping': Vue.prototype.$gettext('Shipping'),
            'waiting': Vue.prototype.$gettext('Waiting'),
        };
        return statusTexts[status] || status;

    },
    getInvoiceIdText(item) {
        if (item.status == "draft") {
            return Vue.prototype.$gettext("Draft");
        }
        return `#${item.invoice_id}`;
    },
    getShipmentStatusText(item) {
        if (item.status == "waiting" && !item.ready) {
            return Vue.prototype.$gettext("Draft");
        }
        return this.getStatusText(item.status);
    },
    getReceiptStatusText(item) {
        if (item.status == "waiting" && !item.ready) {
            return Vue.prototype.$gettext("Draft");
        }
        return this.getStatusText(item.status);
    },
    getReceiptStatusColor(item) {
        if (!item.ready) {
            return "error";
        }
        if (item.status == "partial") {
            return "warning";
        }
        if (item.status == "complete") {
            return "success";
        }
        if (item.status == "waiting") {
            return "default";
        }
        return "default";
    },
    getShipmentStatusColor(item) {
        if (!item.ready) {
            return "error";
        }

        if (["picked_up", "delivered", "complete"].includes(item.status)) {
            return "success";
        }
        if (item.status == "waiting") {
            return "default";
        }
        return "default";
    },
    getInvoiceStatusText(status) {
        switch (status) {
            case 'draft':
                return Vue.prototype.$gettext('Draft');
            case 'validated':
                return Vue.prototype.$gettext('Validated');
            default:
                return status;
        }
    },

    resultToSwalContent(view, result) {
        let non_field_errors = null;
        let details = null;


        if (result?.details) {
            details = JSON.parse(JSON.stringify(result.details));
        }
        if (details && details.non_field_errors) {
            non_field_errors = details.non_field_errors;
            delete (details.non_field_errors);
        } else if (!details) {
            return '<div class="sweeterrors">' + view.$gettext('Unknown error occured') + '</div>';
        }

        let content = '<div class="sweeterrors">';
        if (details.detail) {
            content += `<ul><li>${details.detail}</li></ul>`;
        } else if ([400, 412].indexOf(result.code) != -1) {
            content += "<ul>";
            if (details && Object.keys(details).length) {
                content += `<li>${view.$gettext('Fields errors, check form errors and try again')}</li>`;
            } else if (non_field_errors) {
                (non_field_errors || []).forEach((error) => {
                    content += `<li>${error}</li>`;
                });
            } else {
                content += `<li>${view.$gettext('Server reported bad form values, please check values and try again')}</li>`;
            }
            content += "</ul>";
        } else if ([403].indexOf(result.code) != -1) {
            if (non_field_errors) {
                content += `${view.$gettext('This action cannot be performed.')}<br/>`;
                content += "<ul>";
                (non_field_errors || []).forEach((error) => {
                    content += `<li>${error}</li>`;
                });
                content += "</ul>";

            } else {
                content += `${view.$gettext('Permission denied')}`;
            }
        } else {
            content += "<ul>";
            content += `<li>${view.$gettext('Oops, an unexpected error happened, please contact us for support')}</li>`;
            content += "</ul>";
        }

        content += '</div>';
        return content;
    },

    resultToSwalContentForField(view, result, field) {
        let non_field_errors = null;
        let details = null;
        if (result.details) {
            details = JSON.parse(JSON.stringify(result.details));
        }
        if (details && details.non_field_errors) {
            non_field_errors = details.non_field_errors;
            delete (details.non_field_errors);
        }

        let content = '<div class="sweeterrors"><ul>';
        if ([400, 412].indexOf(result.code) != -1) {
            if (details && Object.keys(details).length) {
                content += `<li>${details[field]}</li>`;
            } else if (non_field_errors) {
                (non_field_errors || []).forEach((error) => {
                    content += `<li>${error}</li>`;
                });
            } else {
                content += `<li>${view.$gettext('Server reported bad form values, please check values and try again')}</li>`;
            }
        } else if (details.detail) {
            content += `<li>${details.detail}</li>`;
        } else {
            content += `<li>${view.$gettext('Oops, an unexpected error happened, please contact us for support')}</li>`;
        }

        content += '</ul></div>';
        return content;
    },
    humanDate(date) {
        // Full textual date 
        return Vue.prototype.$moment(date).format('dddd Do MMMM YYYY, hh:mm');
    },
    makePeriodsFromDate(start_date) {
        let today = new Date();
        var periods = [];
        let period_date = new Date(this.created_month);
        period_date.setDay(0);

        periods.push({ value: this.created_month, text: this.$moment(this.created_month).format('MMM YYYY') });
        while (true) {
            let year = period_date.getFullYear() + parseInt((period_date.getMonth() + 1) / 12);
            let month = (period_date.getMonth() + 1) % 12;
            let next_period = `${year}-${month + 1}-01`;
            period_date = new Date(next_period);

            if (today < period_date) {
                break;
            }
            periods.push({ value: next_period, text: this.$moment(next_period).format('MMM YYYY') });
        }
        return periods;
    },
};
