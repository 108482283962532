var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "flex-grow-1 d-flex flex-column",
    attrs: {
      "elevation": _vm.elevation
    }
  }, [_vm.title ? _c('v-card-title', {
    staticClass: "pb-0 d-flex flex-wrap flex-sm-nowrap"
  }, [_vm.title ? _c('div', {
    staticClass: "d-flex align-center flex-grow-1 ellipsis"
  }, [_c('span', {
    staticClass: "mr-4"
  }, [_vm._v(_vm._s(_vm.title))])]) : _vm._e(), _vm._t("title"), _c('Select', {
    staticClass: "flex-grow-0 w-sm-100 mb-0 ml-4",
    attrs: {
      "value": _vm.preset,
      "outlined": true,
      "dense": true,
      "clearable": false,
      "options": _vm.periods
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('preset-changed', $event);
      }
    }
  })], 2) : _vm._e(), _c('v-card-text', {
    staticClass: "flex-grow-1 d-flex flex-column"
  }, [!_vm.isEmpty ? _c(_vm.chartComponent, {
    key: JSON.stringify(_vm.chartData),
    tag: "component",
    attrs: {
      "x-axis-type": _vm.xAxisType,
      "y-axis-type": _vm.yAxisType,
      "unit": _vm.unit,
      "height": _vm.height,
      "chart-data": _vm.chartData || {},
      "aspect-ratio": _vm.aspectRatio,
      "chart-id": _vm.chartId
    }
  }) : _vm._e(), _vm.loading ? _c('Loading', {
    attrs: {
      "absolute": false,
      "size": _vm.height
    }
  }) : _vm.isEmpty ? _vm._t("empty", function () {
    return [_c('div', {
      staticClass: "my-12 d-flex flex-column justify-center align-center"
    }, [_c('MasterSvg', {
      attrs: {
        "use": "nodata"
      }
    }), _c('div', {
      staticClass: "mt-2"
    }, [_vm._v(_vm._s(_vm.$translate("noData")))])], 1)];
  }) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }