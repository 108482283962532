import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    demoaccounts: [],
    demoaccounts_count: 0,
    demoaccounts_filters: { offset: 0, limit: 20, },
    demoaccounts_loading: false,
    demoaccounts_loading_errors: null,
    demoaccount_deleting: false,
};

const mutations = {
    updateDemoAccounts(state, demoaccounts) {
        state.demoaccounts = demoaccounts;
    },
    updateDemoAccountsCount(state, count) {
        state.demoaccounts_count = count;
    },
    updateDemoAccountsLoading(state, loading) {
        state.demoaccounts_loading = loading;
    },
    updateDemoAccountsLoadingErrors(state, errors) {
        state.demoaccounts_loading_errors = errors;
    },
    updateDemoAccountsFilters(state, filters) {
        state.demoaccounts_filters = filters;
    },
    updateDemoAccountDeleting(state, deleting) {
        state.demoaccount_deleting = deleting;
    },
};

const actions = {

    async fetchDemoAccounts({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateDemoAccountsLoading', true);
        commit('updateDemoAccountsLoadingErrors', null);

        try {
            const response = await axios.get('/api/demoaccounts/', {
                params: state.demoaccounts_filters,
            });
            commit('updateDemoAccounts', response.data.results);
            commit('updateDemoAccountsCount', response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateDemoAccountsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateDemoAccountsLoading', false);
        }
    },

    async fetchDemoAccount({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateDemoAccount', null);
        commit('updateDemoAccountLoading', true);

        try {
            const response = await axios.get(`/api/demoaccounts/${params.demoaccount_id}/`);
            commit('updateDemoAccount', response.data);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateDemoAccountLoading', false);
        }
    },

    async resetDemoAccountData({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let url = `/api/demoaccounts/${params.instance.id}/resetdata/`;

        try {
            const response = await axios.post(url, params.instance);
            await dispatch('fetchDemoAccounts');
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async deleteDemoAccount({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        const url = `/api/demoaccounts/${params.instance.id}/`;

        commit('updateDemoAccountDeleting', true);
        try {
            const response = await axios.delete(url);
            await dispatch('session/fetchStats', null, { root: true });
            await dispatch('fetchDemoAccounts');
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateDemoAccountDeleting', false);
        }
    },

    async recoverPassword({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            return await axios.post("/api/account/recover/", { email: params.instance.user_email });
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};