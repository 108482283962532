import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    agent: null,
    agent_loading: false,
    agent_saving: false,
    catalog: null,

    stats: {},
    stats_loading: false,
    stats_loading_errors: null,

    invoices: [],
    invoices_filters: { offset: 0, limit: 20, },
    invoices_count: 0,
    invoices_loading: false,
    invoices_loading_errors: null,

    section: null,
};

const mutations = {
    updateCatalog(state, catalog) {
        state.catalog = catalog;
    },

    updateInvoices(state, data) {
        state.invoices = data.results;
        state.invoices_count = data.count;
    },
    updateSection(state, section) {
        state.section = section;
    },
    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },
    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },
    updateInvoicesFilters(state, filters) {
        state.invoices_filters = filters;
    },

    updateAgent(state, agent) {

        if (agent && state.agent && agent.id == state.agent.id) {
            state.agent = agent;
            return;
        }
        state.agent = agent;
        state.stats = null;

        state.invoices = [];
        state.invoices_count = 0;
        state.invoices_loaded = 0;

        if (!agent) {
            state.invoices_filters = { offset: 0, limit: state.invoices_filters.limit };
            return;
        }

        state.invoices_filters = {
            billed: agent.id,
            offset: 0, limit: state.invoices_filters.limit,
            ordering: '-invoice_date',
        };
    },

    updateAgentLoading(state, loading) {
        state.agent_loading = loading;
    },
    updateAgentLoadingErrors(state, errors) {
        state.agent_loading_errors = errors;
    },


    updateStats(state, stats) {
        state.stats = stats;
        state.stats_loading = false;
        state.stats_loading_errors = null;
    },
    updateStatsLoading(state, loading) {
        state.stats_loading = loading;
        state.stats_loading_errors = null;
    },
    updateStatsLoadingErrors(state, stats_loading_errors) {
        state.stats = {};
        state.stats_loading = false;
        state.stats_loading_errors = stats_loading_errors;
    },
};

const actions = {
    async fetchInvoices({ commit, state }) {
        commit('updateInvoicesLoading', true);
        commit('updateInvoicesLoadingErrors', null);

        try {
            const response = await axios.get('/api/invoices/', { params: state.invoices_filters });
            commit('updateInvoices', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateInvoicesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateInvoicesLoading', false);
        }
    },

    async fetchAgentStats({ commit, state }) {
        commit('updateStatsLoading', true);
        if (!state.agent) {
            return;
        }

        try {
            let url = `/api/entities/${state.agent.id}/stats/`;
            const response = await axios.get(url, { params: { profile: "endcustomer" } });
            commit('updateStats', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateStatsLoadingErrors', error.details);
            throw error;
        }
    },

    async fetchCatalog({ commit, state }) {
        if (store.getters['session/current_user_permissions'].indexOf("catalog.view_catalogassignment") == -1) {
            return;
        }
        if (store.getters['session/current_user_permissions'].indexOf("catalog.view_catalog") == -1) {
            return;
        }

        try {
            const response = await axios.get('/api/catalogassignments/', { params: { entity: state.agent.id } });
            commit('updateCatalog', response.data.results[0]?.catalog_data);
            return response.data.results[0]?.catalog_data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async fetchAgent({ commit, state }, params) {
        commit('updateAgentLoading', true);

        let url = null;
        if (params && params.agent_id) {
            url = `/api/entities/${params.agent_id}/`;
        } else if (state.agent) {
            url = `/api/entities/${state.agent.agent_id}/`;
        } else {
            throw 'No agent to fetch';
        }

        try {
            const response = await axios.get(url, { params: { profile: "agent" } });
            commit('updateAgentLoading', false);
            commit('updateAgent', response.data);
            return response;
        } catch (xhr_error) {
            commit('updateAgentLoading', false);
            throw utils.handleError(xhr_error);
        }
    },

    async init({ commit, dispatch }) {
        commit('updateStats', null);
        await dispatch('fetchAgentStats');
        await dispatch('fetchCatalog');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
    }
};
