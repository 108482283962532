import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import permissions from './submodules/permissions';

const state = {
    group_name: null,
    group_permissions: [],

    group: null,
    group_loading: false,
    group_deleting: false,
    group_saving: false,
    group_saving_errors: {},
};

const mutations = {

    updateGroup(state, group) {
        state.group = group;
        state.group_name = group.name;
        state.group_permissions = group.permissions || [];
    },

    updateGroupLoading(state, loading) {
        state.group_loading = loading;
    },

    updateGroupLoadingErrors(state, errors) {
        state.group_loading_errors = errors;
    },

    updateGroupSaving(state, saving) {
        state.group_saving = saving;
    },

    updateGroupDeleting(state, deleting) {
        state.group_deleting = deleting;
    },

    updatePermissions(state, permissions) {
        state.group.permissions = permissions || [];
        state.group_permissions = permissions || [];
    },

    updateGroupSavingErrors(state, errors) {
        state.group_saving_errors = errors;
    },

    updateGroupName(state, name) {
        state.group.name = name;
        state.group_name = name;
        state.group_saving_errors.name = null
    },

};

const actions = {
    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateGroup', params.group)

        dispatch('permissions/init', { profile: params.profile });
        dispatch('permissions/fetchPermissions');
    },

    fetchGroup({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateGroupLoadingErrors', null);
        commit('updateGroupLoading', true);

        return new Promise((resolve, reject) => {
            axios.get(`/api/groups/${params.group_id}/`)
                .then((response) => {
                    commit('updateGroupLoading', false);
                    commit('updateGroup', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateGroupLoading', false);
                    commit('updateGroupLoadingErrors', error.details);
                    reject(error);
                });
        })
    },

    saveGroup({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateGroupSaving', true);

        let url = '/api/groups/';
        let method = axios.post;

        if (state.group.id) {
            url = `/api/groups/${state.group.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            state.group.permissions = state.group.permissions || [];
            method(url, state.group)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateGroupSavingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateGroupSaving', false);
                })

        })
    },

    deleteGroup({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateGroupDeleting', true);

        return new Promise((resolve, reject) => {
            axios.delete(`/api/groups/${params.instance.id}/`)
                .then(resolve)
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    commit('updateGroupDeleting', false);
                });
        });
    },

    recoverPassword({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.post("/api/account/recover/", { email: params.instance.email })
                .then(resolve)
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
        });
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        permissions: permissions(),
    }
};




