<template>
    <ModalContent>
        <template slot="title">
            <span class="primary--text">{{ content?.fields?.title }}</span>
        </template>
        <template slot="content">
            <ContentfulRichTextRenderer
                v-if="content"
                :document="content?.fields?.helpText"
            />
            <div key="error" v-else-if="error">{{ error }}</div>
            <div key="loading" v-else-if="loading">
                <Loading :absolute="false" :size="200" />
            </div>
            <div key="notfound" v-else v-translate>Help not found.</div>
        </template>
        <template slot="bottom">
            <Button
                class="my-2 my-sm-4 w-sm-100 ml-sm-3"
                :text="$gettext('Close')"
                @click="hideModal"
            />
        </template>
    </ModalContent>
</template>

<script>
import { mapActions } from "vuex";
import { createClient } from "contentful";
import ContentfulRichTextRenderer from "contentful-rich-text-vue-renderer";

export default {
    components: {
        ContentfulRichTextRenderer,
    },
    props: {
        params: {
            type: Object,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            content: null,
            loading: false,
            error: null,
        };
    },
    created() {
        this.loading = true;
        this.fetchContent(this.params.contentUniqueId)
            .then((content) => {
                this.content = content;
            })
            .catch((error) => {
                this.error = error;
            })
            .finally(() => {
                this.loading = false;
            });
    },
    methods: Object.assign(
        mapActions("help", ["fetchContent"]),
        mapActions("mcl", ["hideModal"]),
        {}
    ),
};
</script>
