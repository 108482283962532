var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.readonly && _vm.isOperator && _vm.status != 'trial' ? _c('v-card', {
    staticClass: "mb-8"
  }, [_c('v-card-title', {
    staticClass: "text-h2 pa-3 pa-sm-6"
  }, [_c('translate', [_vm._v("Readonly account")])], 1), _c('v-card-text', {
    staticClass: "px-3 px-sm-6"
  }, [_vm.status == 'trial-ended' ? [_c('div', [_vm._v(" " + _vm._s(_vm.$gettext("Your trial period has ended and your account is currently in readonly mode.")) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$gettext("Please contact us to activate your account at the following address")) + " "), _c('a', {
    attrs: {
      "href": 'mailto:contact@safetee.io?subject=[Safetee] - ' + _vm.$gettext('Account activation')
    }
  }, [_vm._v(" contact@safetee.io")]), _vm._v(". ")])] : [_c('div', [_vm._v(" " + _vm._s(_vm.$gettext("Your account is currently in readonly mode. Please contact your sales representative at the following address")) + " "), _c('a', {
    attrs: {
      "href": "mailto:contact@safetee.io"
    }
  }, [_vm._v(" contact@safetee.io")]), _vm._v(". ")])], _c('Button', {
    staticClass: "mt-3",
    attrs: {
      "text": _vm.$gettext('Contact us'),
      "color": 'primary',
      "icon": _vm.iconName('email')
    },
    on: {
      "click": _vm.sendMail
    }
  })], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }