<template>
    <v-alert
        border="left"
        colored-border
        :color="type"
        :elevation="elevation"
        class="text-body-2 mb-4"
        :type="type"
        :icon="icon"
    >
        <div class="d-sm-flex">
            <div class="flex-grow-1 mb-2 mr-2">
                <slot />
            </div>
            <slot name="button" />
        </div>
    </v-alert>
</template>

<script>
export default {
    props: {
        elevation: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: "warning",
        },
        icon: {
            type: String,
            required: false,
        },
    },
    computed: {
        color() {
            switch (this.type) {
                case "success":
                    return "success";
            }
            return "warning";
        },
    },
};
</script>
