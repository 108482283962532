import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    order: {},
    order_type: 'endcustomersite',
    order_saving: false,
    order_saving_errors: {},

    order_loading: false,
    order_loading_errors: {},

    delivery_date: null,

    endcustomer_id: null,
    endcustomer: null,
    endcustomer_loading: false,
    endcustomer_loading_errors: {},

    endcustomersite_id: null,
    endcustomersite: null,
    endcustomersite_loading: false,
    endcustomersite_loading_errors: {},

    updating_order_status: false,

    endcustomers: [],
    endcustomers_count: 0,
    endcustomers_loading: false,
    endcustomers_loading_errors: {},

    endcustomersites: [],
    endcustomersites_count: 0,
    endcustomersites_loading: false,
    endcustomersites_loading_errors: {},
};


const mutations = {
    updateOrder(state, order) {
        state.order = JSON.parse(JSON.stringify(order || {}));
        state.endcustomer_id = order.endcustomer;
        state.endcustomersite = order.endcustomersite_data;
        state.endcustomersite_id = order.endcustomersite;
        state.delivery_date = order.delivery_date;
    },
    updateOrderSaving(state, saving) {
        state.order_saving = saving;
    },
    updateOrderSavingErrors(state, errors) {
        state.order_saving_errors = errors;
    },
    updateOrderLoading(state, loading) {
        state.order_loading = loading;
    },
    updateOrderLoadingErrors(state, errors) {
        state.order_loading_errors = errors;
    },
    updateEndCustomer(state, endcustomer) {
        state.endcustomer = endcustomer;
        state.endcustomer_id = endcustomer.id;
        state.order.endcustomer = endcustomer.id;
        state.endcustomersite_id = null;
        state.endcustomersite = null;
    },
    updateEndCustomerId(state, endcustomer_id) {
        state.endcustomer_id = endcustomer_id;
        state.order.endcustomer = endcustomer_id;
        state.endcustomersite_id = null;
        state.endcustomersite = null;
    },

    updateEndCustomerSiteId(state, endcustomersite_id) {
        state.endcustomersite_id = endcustomersite_id;
        state.order.endcustomersite = endcustomersite_id;
    },
    updateEndCustomerSiteLoadingErrors(state, errors) {
        state.endcustomersite_loading_errors = errors;
    },
    updateEndCustomerSiteLoading(state, loading) {
        state.endcustomersite_loading = loading;
    },
    updateEndCustomerSite(state, endcustomersite) {
        state.endcustomersite = endcustomersite;
        state.endcustomersite_id = endcustomersite.id;
        state.order.endcustomersite = endcustomersite.id;
    },

    updateEndCustomerLoadingErrors(state, errors) {
        state.endcustomer_loading_errors = errors;
    },
    updateEndCustomerLoading(state, loading) {
        state.endcustomer_loading = loading;
    },



    updateEndCustomers(state, endcustomers) {
        state.endcustomers = endcustomers;
    },
    updateEndCustomersCount(state, count) {
        state.endcustomers_count = count;
    },
    updateEndCustomersLoading(state, loading) {
        state.endcustomers_loading = loading;
    },
    updateEndCustomersLoadingErrors(state, errors) {
        state.endcustomers_loading_errors = errors;
    },

    updateEndCustomerSites(state, endcustomersites) {
        state.endcustomersites = endcustomersites;
        if (!state.order.endcustomersite && endcustomersites.length == 1) {
            state.endcustomersite_id = endcustomersites[0].id;
            state.order.endcustomersite = endcustomersites[0].id;
        }
    },
    updateEndCustomerSitesCount(state, count) {
        state.endcustomersites_count = count;
    },
    updateEndCustomerSitesLoading(state, loading) {
        state.endcustomersites_loading = loading;
    },
    updateEndCustomerSitesLoadingErrors(state, errors) {
        state.endcustomersites_loading_errors = errors;
    },
    updateDeliveryDate(state, delivery_date) {
        state.order.delivery_date = delivery_date;
        state.delivery_date = delivery_date;
    },

};

const actions = {

    saveOrder({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateOrderSaving', true);
        commit('updateOrderSavingErrors', {});

        let url = '/api/orders/';
        let method = axios.post;

        if (state.order.id) {
            url = `/api/orders/${state.order.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {

            method(url, state.order)
                .then((response) => {
                    commit('updateOrderSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);

                    commit('updateOrderSaving', false);
                    commit('updateOrderSavingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                });
        })

    },

    fetchEndCustomers({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomersLoading', true);
        commit('updateEndCustomersLoadingErrors', {});

        return new Promise((resolve, reject) => {

            axios.get('/api/entities/', {
                params: { limit: 1000, ordering: "name", profile: "endcustomer" },
            })
                .then((response) => {
                    commit('updateEndCustomers', response.data.results);
                    commit('updateEndCustomersCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {

                    let error = utils.handleError(xhr_error);
                    commit('updateEndCustomersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndCustomersLoading', false);
                });
        });
    },

    fetchEndCustomerSite({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomerSiteLoading', true);
        commit('updateEndCustomerSiteLoadingErrors', {});

        return new Promise((resolve, reject) => {

            axios.get(`/api/endcustomersites/${state.endcustomersite_id}/`
            )
                .then((response) => {
                    commit('updateEndCustomerSite', response.data);
                    dispatch('fetchEndCustomerSites');
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateEndCustomerSiteLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndCustomerSiteLoading', false);
                });
        });
    },

    fetchEndCustomer({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomerSites', []);
        commit('updateEndCustomerLoading', true);
        commit('updateEndCustomerLoadingErrors', {});

        return new Promise((resolve, reject) => {

            axios.get(`/api/entities/${state.endcustomer_id}/`, {
                params: { profile: "endcustomer" },
            }
            )
                .then((response) => {
                    commit('updateEndCustomer', response.data);
                    dispatch('fetchEndCustomerSites');
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateEndCustomerLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndCustomerLoading', false);
                });
        });
    },

    fetchEndCustomerSites({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("core.view_endcustomersite") == -1) {
            return;
        }

        commit('updateEndCustomerSitesLoading', true);
        commit('updateEndCustomerSitesLoadingErrors', {});

        return new Promise((resolve, reject) => {

            axios.get('/api/endcustomersites/', {
                params: { limit: 1000, ordering: "name", endcustomer: state.endcustomer_id },
            })
                .then((response) => {
                    commit('updateEndCustomerSites', response.data.results);
                    commit('updateEndCustomerSitesCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {

                    let error = utils.handleError(xhr_error);
                    commit('updateEndCustomerSitesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndCustomerSitesLoading', false);
                });
        });
    },

    initOrder({ commit, dispatch, state }, order) {
        if (store.getters['session/current_user_mode'] == "customer" ||
            store.getters['session/current_user_mode'] == "agent") {
            dispatch('fetchEndCustomers');
        }
        commit('updateOrder', order);
        commit('updateDeliveryDate', order.delivery_date);
        if (order.endcustomer || store.getters['session/current_user_mode'] == "endcustomer") {
            dispatch('fetchEndCustomerSites');
        }
    },

    setEndCustomerId({ commit, dispatch, state }, endcustomer_id) {
        commit('updateEndCustomerId', endcustomer_id);
        dispatch('fetchEndCustomer');
        dispatch('fetchEndCustomerSites');
    },

    setEndCustomerSiteId({ commit, dispatch, state }, endcustomersite_id) {
        commit('updateEndCustomerSiteId', endcustomersite_id);
        dispatch('fetchEndCustomerSite');
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
