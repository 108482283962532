import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    invoice: null,
    invoice_loading: false,
    invoice_loading_errors: null,

    invoiceitems: [],
    invoiceitems_count: 0,
    invoiceitems_filters: { offset: 0, limit: 20, ordering: 'billing_date' },
    invoiceitems_loading: false,
    invoiceitems_loading_errors: null,
    invoiceitems_loaded: false,
    invoiceitems_cancel_source: null,

    invoice_saving: false,
    invoice_saving_errors: {},

    entities: [],
    entities_loading: false,
    entities_loading_errors: null,

    billingaccount: null,
    billingaccount_loading: false,
    billingaccount_loading_errors: null,
};

const mutations = {

    updateInvoice(state, invoice) {
        if (invoice && state.invoice && state.invoice.id == invoice.id) {
            state.invoice = invoice;
            return;
        }

        state.invoice = invoice;
        state.invoiceitems = [];
        state.invoiceitems_count = 0;
        state.invoiceitems_loaded = false;
        state.invoice_saving_errors = {};

        if (!invoice) {
            state.invoiceitems_filters = { offset: 0, limit: state.invoiceitems_filters.limit, ordering: 'billing_date' };
            return;
        }

        state.invoiceitems_filters = {
            offset: 0,
            limit: state.invoiceitems_filters.limit,
            invoice: invoice.id,
            ordering: 'billing_date',
        }
    },
    updateInvoiceLoading(state, loading) {
        state.invoice_loading = loading;
    },
    updateInvoiceLoadingErrors(state, errors) {
        state.invoice_loading_errors = errors;
    },

    updateInvoiceItemSaving(state, invoice_saving) {
        state.invoice_saving = invoice_saving;
    },
    updateInvoiceItemSavingErrors(state, invoice_saving_errors) {
        state.invoice_saving_errors = invoice_saving_errors;
        state.invoice_saving = false;
    },

    updateInvoiceItems(state, invoiceitems) {
        state.invoiceitems = invoiceitems;
    },
    updateInvoiceItemsLoading(state, loading) {
        state.invoiceitems_loading = loading;
    },
    updateInvoiceItemsCount(state, count) {
        state.invoiceitems_count = count;
    },
    updateInvoiceItemsLoadingErrors(state, errors) {
        state.invoiceitems_loading_errors = errors;
    },
    updateInvoiceItemsFilters(state, filters) {
        state.invoiceitems_filters = filters;
    },
    updateInvoiceItemsLoaded(state, loaded) {
        state.invoiceitems_loaded = loaded;
    },
    updateInvoiceItemsCancelSource(state, cancelSource) {
        state.invoiceitems_cancel_source = cancelSource;
    },


    updateEntities(state, entities) {
        state.entities = entities;
        state.entities_loading = false;
        state.entities_loading_errors = null;
    },
    updateEntitiesLoading(state, loading) {
        state.entities_loading = loading;
        state.entities_loading_errors = null;
    },
    updateEntitiesLoadingErrors(state, errors) {
        state.entities_loading = false;
        state.entities_loading_errors = errors;
    },

    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
        state.billingaccount_loading = false;
        state.billingaccount_loading_errors = null;
    },
    updateBillingAccountLoading(state, billingaccount_loading) {
        state.billingaccount_loading = billingaccount_loading;
    },
    updateBillingAccountLoadingErrors(state, billingaccount_loading_errors) {
        state.billingaccount = [];
        state.billingaccount_loading = false;
        state.billingaccount_loading_errors = billingaccount_loading_errors;
    },
};

const actions = {

    async duplicateInvoice({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            const response = await axios.post(`/api/invoices/${state.invoice.id}/duplicate/`);
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    fetchBillingAccount({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (state.invoice.biller != store.getters['session/current_entity_id']) {
            return;
        }

        commit('updateBillingAccountLoading', true);
        commit('updateBillingAccountLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get(`/api/billingaccounts/${state.invoice.billingaccount}/`)
                .then((response) => {
                    commit('updateBillingAccount', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateBillingAccountLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateBillingAccountLoading', false);
                })
        })
    },

    fetchEntities({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEntitiesLoading', true);

        return new Promise((resolve, reject) => {

            this.request = axios.get("/api/entities/", { params: { limit: 1000, ordering: 'name', profile: params, parent: store.getters['session/current_entity_id'] } })
                .then((response) => {
                    commit('updateEntities', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateEntitiesLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    fetchInvoice({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        commit('updateInvoiceLoading', true);
        commit('updateInvoiceLoadingErrors', null);
        let url;
        if (params && params.invoice_id) {
            url = `/api/invoices/${params.invoice_id}/`;
        } else if (state.invoice) {
            url = `/api/invoices/${state.invoice.id}/`;
        } else {
            throw 'No invoice to fetch';
        }

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    commit('updateInvoiceLoading', false);
                    commit('updateInvoice', response.data);
                    dispatch('fetchInvoiceItems');
                    dispatch('fetchBillingAccount');

                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateInvoiceLoading', false);
                    commit('updateInvoiceLoadingErrors', error.details);
                    reject(error);
                });
        });
    },

    fetchContractLine({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        let url = `/api/contractlines/${params.contractline_id}/`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                });
        });
    },

    replayInvoice({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.post(`/api/invoices/${params.invoice.id}/replay/`)
                .then((response) => {
                    resolve(response.data);
                    dispatch('fetchInvoice');
                    dispatch('fetchInvoiceItems');
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                });
        });
    },

    replayInvoiceItem({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.post(`/api/invoiceitems/${params.invoiceitem.id}/replay/`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                });
        });
    },

    deleteInvoice({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.delete(`/api/invoices/${params.instance.id}/`)
                .then((response) => {
                    resolve();
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                    dispatch('billingstats/fetchAllStats', null, { root: true });
                });
        });
    },


    deleteInvoiceItem({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.delete(`/api/invoiceitems/${params.instance.id}/`)
                .then((response) => {
                    resolve();
                    dispatch('fetchInvoice');
                    dispatch('fetchInvoiceItems');
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                    dispatch('billingstats/fetchAllStats', null, { root: true });
                });
        });
    },


    async fetchInvoiceItems({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateInvoiceItemsLoading', true);
        commit('updateInvoiceItemsLoadingErrors', null);

        if (state.invoiceitems_cancel_source) {
            state.invoiceitems_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateInvoiceItemsCancelSource', cancelSource);

        try {
            const response = await axios.get("/api/invoiceitems/", {
                params: state.invoiceitems_filters,
                cancelToken: state.invoiceitems_cancel_source?.token
            });
            commit('updateInvoiceItems', response.data.results);
            commit('updateInvoiceItemsCount', response.data.count);
            commit('updateInvoiceItemsCancelSource', null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            let error = utils.handleError(xhr_error);
            commit('updateInvoiceItemsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateInvoiceItemsLoading', false);
        }
    },

    exportInvoiceItemsAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        filters.invoice = state.invoice.id;
        filters.format = 'csv';
        filters.limit = 10000;
        filters.ordering = 'billing_date';
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/invoiceitems/?" + queryparams);
    },

    createCreditInvoice({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.post(`/api/invoices/${state.invoice.id}/credit/`, params)
                .then((response) => {
                    resolve(response);
                    dispatch('fetchInvoice');
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
