import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    stock: null,
    stock_loading: false,
    stock_loading_errors: null,
    stock_saving: false,
};

const mutations = {

    updateStock(state, stock) {
        state.stock = stock;
    },
    updateStockLoading(state, loading) {
        state.stock_loading = loading;
    },
    updateStockLoadingErrors(state, errors) {
        state.stock_loading_errors = errors;
    },
    updateStockSaving(state, saving) {
        state.stock_saving = saving;
    },
    updateStockDeleting(state, deleting) {
        state.stock_deleting = deleting;
    },
};

const actions = {

    fetchStock({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStockLoading', true);
        commit('updateStockLoadingErrors', null);
        let url;
        if (params && params.stock_id) {
            url = `/api/stocks/${params.stock_id}/`;
        } else if (state.stock) {
            url = `/api/stocks/${state.stock.id}/`;
        } else {
            throw 'No stock to fetch';
        }
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    commit('updateStock', response.data);
                    dispatch('fetchStats');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStockLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateStockLoading', false);
                })
        })
    },

    deleteStock({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStockDeleting', true);

        const url = `/api/stocks/${params.instance.id}/`;
        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    commit('updateStockDeleting', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStockDeleting', false);
                    reject(error);
                })
                .finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                });
        })
    },

    saveStock({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStockSaving', true);

        let url = '/api/stocks/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/stocks/${params.instance.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {

            method(url, params.instance)
                .then((response) => {
                    commit('updateStockSaving', false);
                    resolve(response)
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    dispatch('session/fetchStats', null, { root: true });
                    reject(error);
                })
                .finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                });
        });
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
