import axios from "axios";
import utils from "@/stores/utils";

const state = {
    search: null,
    recurring: false,
    catalog: null,
    catalogentry: null,
    entity_id: null,
    entry_type: null,

    catalogentries: null,
    catalogentries_count: 0,
    catalogentries_filters: { limit: 1000, trigger: "manual" },
    catalogentries_loading: false,
    catalogentries_loading_errors: null,
};

const mutations = {
    updateCatalog(state, catalog) {
        state.catalog = catalog;
    },
    updateRecurring(state, recurring) {
        state.recurring = recurring;
        state.catalogentries_filters.recurring = recurring;
        state.entry_type = "service";
    },
    updateEntityId(state, entity_id) {
        state.entity_id = entity_id;
        state.catalogentries_filters.for_entity = entity_id;
    },

    updateSearch(state, search) {
        state.catalogentries_filters.search = search;
    },
    updateCatalogEntry(state, catalogentry) {
        state.catalogentry = catalogentry;
    },

    updateCatalogEntries(state, catalogentries) {
        state.catalogentries = catalogentries;
    },
    updateCatalogEntriesCount(state, count) {
        state.catalogentries_count = count;
    },
    updateCatalogEntriesLoading(state, loading) {
        state.catalogentries_loading = loading;
    },
    updateCatalogEntriesLoadingErrors(state, errors) {
        state.catalogentries_loading_errors = errors;
    },
    updateEntryType(state, entry_type) {
        state.entry_type = entry_type;
        state.catalogentries_filters.type = entry_type;
    },
};

const actions = {
    fetchCatalogEntries({ commit, dispatch, state }, params) {
        // eslint-disable-line no-unused-vars
        commit("updateCatalogEntriesLoading", true);
        commit("updateCatalogEntriesLoadingErrors", null);

        return new Promise((resolve, reject) => {
            axios
                .get("/api/catalogentries/", { params: state.catalogentries_filters })
                .then((response) => {
                    commit("updateCatalogEntries", response.data.results);
                    commit("updateCatalogEntriesCount", response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateCatalogEntriesLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    commit("updateCatalogEntriesLoading", false);
                });
        });
    },

    fetchCustomerCatalog({ commit, dispatch, state }, params) {
        // eslint-disable-line no-unused-vars

        return new Promise((resolve, reject) => {
            axios
                .get("/api/catalogassignments/", {
                    params: { entity: state.customer_id },
                })
                .then((response) => {
                    if (response.data.count) {
                        commit("updateCatalog", response.data.results[0].catalog_data);
                    } else {
                        commit("updateCatalog", null);
                    }
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                })
                .finally(() => { });
        });
    },

    fetchEndCustomerCatalog({ commit, dispatch, state }, params) {
        // eslint-disable-line no-unused-vars

        return new Promise((resolve, reject) => {
            axios
                .get("/api/catalogassignments/", {
                    params: { entity: state.endcustomer_id },
                })
                .then((response) => {
                    if (response.data.count) {
                        commit("updateCatalog", response.data.results[0].catalog_data);
                    } else {
                        commit("updateCatalog", null);
                    }
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                })
                .finally(() => { });
        });
    },

    setEntityId({ commit, dispatch, state }, entity_id) {
        // eslint-disable-line no-unused-vars
        commit("updateEntityId", entity_id);
        dispatch("fetchCustomerCatalog");
    },

    setSearch({ commit, dispatch, state }, search) {
        // eslint-disable-line no-unused-vars
        commit("updateSearch", search);
        dispatch("fetchCatalogEntries");
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
