import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    productprice: null,
    productprice_saving: false,
    productprice_saving_errors: null,
};

const mutations = {
    updateProductPrice(state, productprice) {
        state.productprice = productprice;
    },
    updateProductPriceSaving(state, saving) {
        state.productprice_saving = saving;
    },
    updateProductPriceSavingErrors(state, errors) {
        state.productprice_saving_errors = errors;
    },
};

const actions = {

    saveProductPrice({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductPriceSaving', true);
        commit('updateProductPriceSavingErrors', null);

        let url = '/api/productprices/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/productprices/${params.instance.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    commit('updateProductPriceSaving', false);
                    resolve(response);

                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductPriceSaving', false);
                    commit('updateProductPriceSavingErrors', error.details);
                    reject(error);

                });
        })
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
