import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import billingaccounts from './submodules/billingaccounts';

const state = {
    endcustomer: null,
    endcustomer_saving: false,
    endcustomer_saving_errors: {},

    features: [],
    features_loading: false,
    features_count: 0,
    features_loading_errors: null,

    billingaccounts: [],
    billingaccounts_loading: false,
    billingaccounts_loading_errors: null,
};

const mutations = {
    updateEndCustomer(state, endcustomer) {
        state.endcustomer = endcustomer;
    },

    updateEndCustomerSaving(state, saving) {
        state.endcustomer_saving = saving;
    },
    updateEndCustomerSavingErrors(state, errors) {
        state.endcustomer_saving_errors = errors;
    },

    updateBillingAccounts(state, billingaccounts) {
        state.billingaccounts = billingaccounts;
        state.billingaccounts_loading = false;
        state.billingaccounts_loading_errors = null;
    },
    updateBillingAccountsLoading(state, billingaccounts_loading) {
        state.billingaccounts_loading = billingaccounts_loading;
    },
    updateBillingAccountsLoadingErrors(state, billingaccounts_loading_errors) {
        state.billingaccounts = [];
        state.billingaccounts_loading = false;
        state.billingaccounts_loading_errors = billingaccounts_loading_errors;
    },

    updateEndCustomerBillingAccountId(state, billingaccount) {
        state.endcustomer.billingaccount = billingaccount;
    },
    updateBillingAccountLoading(state, loading) {
        state.billingaccount_loading = loading;
    },

    updateFeatures(state, data) {
        state.features = data.results;
        state.features_count = data.count;
        state.features_loading_errors = {};
        state.features_loading = false;
        state.endcustomer.features = data?.results?.filter((item) => item.entities > 0).map((item) => item.id);
    },
    updateFeaturesLoading(state, loading) {
        state.features_loading = loading;
        state.features_loading_errors = null;
    },
    updateFeaturesCount(state, count) {
        state.features_count = count;
    },
    updateFeaturesLoadingErrors(state, errors) {
        state.features_loading_errors = errors;
        state.features_loading = false;
    },

};

const actions = {

    fetchFeatures({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateFeaturesLoading', true);

        return new Promise((resolve, reject) => {
            axios.get('/api/features/', { params: { profile: 'endcustomer', entity: state.endcustomer.id } })
                .then((response) => {
                    commit('updateFeatures', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateFeaturesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateFeaturesLoading', false);
                })
        });
    },

    fetchEndCustomer({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        let url;
        if (params && params.endcustomer_id) {
            url = `/api/entities/${params.endcustomer_id}/`;
        } else if (state.endcustomer) {
            url = `/api/entities/${state.endcustomer.id}/`;
        } else {
            throw 'No endcustomer to fetch';
        }

        commit('updateEndCustomerLoading', true);

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    commit('updateEndCustomer', response.data);
                    dispatch('fetchEndCustomerStats');
                    resolve(response.data);
                    dispatch('fetchShipments');
                    dispatch('fetchEndCustomerSites');
                    dispatch('fetchUsers');
                    dispatch('fetchInvoices');
                    dispatch('documents/init', {
                        filterTag: `endcustomer:${response.data.id}`,
                        createTags: [
                            `endcustomer:${response.data.id}`,
                        ]
                    });
                    dispatch('documents/fetchDocuments');
                    dispatch('allocations/init', {
                        filters: { endcustomer: response.data.id },
                    });

                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateEndCustomerLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndCustomerLoading', false);
                })
        });
    },


    async saveEndCustomer({ commit, dispatch, save }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomerSaving', true);

        let url = '/api/entities/';
        let method = axios.post;
        if (state.endcustomer.id) {
            url = `/api/entities/${state.endcustomer.id}/`;
            method = axios.put;
        }
        try {
            const response = await method(url, state.endcustomer);
            commit('updateEndCustomerSaving', false);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEndCustomerSavingErrors', error.details);
            commit('updateEndCustomerSaving', false);
            throw error;
        }
    },


    fetchBillingAccounts({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateBillingAccountsLoading', true);
        commit('updateBillingAccountsLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/billingaccounts/')
                .then((response) => {
                    commit('updateBillingAccounts', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateBillingAccountsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateBillingAccountsLoading', false);
                })
        })
    },


    async fetchBillingAccount({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateBillingAccountLoading', true);

        dispatch('activebillingaccounts/fetchBillingAccounts').then((response) => {
            if (response.data.results.length > 0) {
                commit('updateEndCustomerBillingAccountId', response.data.results[0].id);
            }
        }).finally(() => {
            commit('updateBillingAccountLoading', false);
        });
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomer', params.endcustomer);
        dispatch('activebillingaccounts/init', {
            filters: { for_customer: state.endcustomer.id }
        });

        dispatch('fetchBillingAccount');
        dispatch('fetchFeatures');
        dispatch('billingaccounts/fetchBillingAccounts');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        billingaccounts: billingaccounts(),
        activebillingaccounts: billingaccounts(),
    }
};
