import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    stockproduct: null,
};


const mutations = {
    updateStockProduct(state, stockproduct) {
        state.stockproduct = stockproduct;
    },
};

const actions = {

    saveStockLocation({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        let url = '/api/stockproducts/';
        let method = axios.post;
        let payload = params.stockproduct;

        if (params.stockproduct.id) {
            url = `/api/stockproducts/${params.stockproduct.id}/`;
            method = axios.patch;
            payload = {
                location: params.stockproduct.location
            }
        }

        return new Promise((resolve, reject) => {

            method(url, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                })
        });
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
