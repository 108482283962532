import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';

const state = {
    endcustomers: [],
    endcustomers_count: 0,
    endcustomers_filters: { offset: 0, limit: 20, ordering: 'name', profile: "endcustomer" },
    endcustomers_loading: false,
    endcustomers_loading_errors: null,
    synchronizing_endcustomers: false,
    synchronizing_endcustomers_errors: null,
};

const mutations = {
    updateEndCustomers(state, endcustomers) {
        state.endcustomers = endcustomers;
    },
    updateEndCustomersCount(state, count) {
        state.endcustomers_count = count;
    },
    updateEndCustomersFilters(state, filters) {
        state.endcustomers_filters = filters;
    },
    updateEndCustomersLoading(state, loading) {
        state.endcustomers_loading = loading;
    },
    updateEndCustomersLoadingErrors(state, errors) {
        state.endcustomers_loading_errors = errors;
    },
    updateSynchronizingEndCustomers(state, synchronizing) {
        state.synchronizing_endcustomers = synchronizing;
    },
    updateSynchronizingEndCustomersErrors(state, errors) {
        state.synchronizing_endcustomers_errors = errors;
    },

};

const actions = {

    exportEndCustomersAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.endcustomers_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/entities/?" + queryparams);
    },

    fetchEndCustomers({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomersLoading', true);
        commit('updateEndCustomersLoadingErrors', null);

        return new Promise((resolve, reject) => {

            this.request = axios.get("/api/entities/", { params: state.endcustomers_filters })
                .then((response) => {
                    commit('updateEndCustomers', response.data.results);
                    commit('updateEndCustomersCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateEndCustomersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndCustomersLoading', false);
                });
        });
    },

    synchronizeEndCustomers({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateSynchronizingEndCustomers', true);
        commit('updateSynchronizingEndCustomersErrors', null);

        let url = '/api/sync/endcustomers/';

        return new Promise((resolve, reject) => {

            axios.post(url, { payload: params.payload })
                .then((response) => {
                    resolve(response.data.payload);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    error.details = error.details.payload;
                    commit('updateSynchronizingEndCustomersErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateSynchronizingEndCustomers', false);
                })
        });

    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
