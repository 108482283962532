import store from '@/stores/store';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import documents from './submodules/documents';

const state = {
    shipment: {},
    shipment_saving: false,
    shipment_saving_errors: null,

    options: [],
    options_loading: false,
    options_loading_errors: null,

    stocks: [],
    stocks_loading: false,
    stocks_loading_errors: null,

    endcustomers: [],
    endcustomers_loading: false,
    endcustomers_loading_errors: null,

    endcustomersites: [],
    endcustomersites_loading: false,
    endcustomersites_loading_errors: null,

    shipment_type: null,
    delivery_date: null,
    stock: null,
    endcustomer: null,
    endcustomersite: null,
};

const getters = {
    active_endcustomersite(state) {
        return state.endcustomersites.find((item) => {
            return item.id == state.shipment.endcustomersite;
        });
    },
};

const mutations = {
    updateShipment(state, shipment) {
        state.shipment = JSON.parse(JSON.stringify(shipment || {}));
        state.shipment_saving = false;
        state.shipment_saving_errors = null;

        state.options = [];
        state.options_loading = false;
        state.options_loading_errors = null;

        state.delivery_date = shipment?.delivery_date;
        state.stock = shipment?.stock;
        state.endcustomer = shipment?.endcustomer;
        state.endcustomersite = shipment?.endcustomersite;

        if (state.shipment) {
            if (state.shipment.id) {
                if (state.shipment.endcustomersite) {
                    state.shipment_type = "endcustomersite";
                } else if (state.shipment.endcustomer) {
                    state.shipment_type = "endcustomer";
                } else {
                    state.shipment_type = "pickup";
                }
            } else {
                state.shipment_type = "endcustomersite";
            }
        } else {
            state.shipment_type = "pickup";
        }
    },
    updateShipmentType(state, shipment_type) {
        state.shipment_type = shipment_type;
        if (shipment_type == "endcustomer") {
            state.shipment.endcustomersite = null;
        } else if (shipment_type == "pickup") {
            state.shipment.endcustomersite = null;
            state.shipment.endcustomer = null;
        }
    },
    updateShipmentSaving(state, saving) {
        state.shipment_saving = saving;
    },
    updateShipmentSavingErrors(state, errors) {
        state.shipment_saving_errors = errors;
    },
    updateOptions(state, options) {
        state.options = options;
    },
    updateOptionsLoading(state, deleting) {
        state.options_loading = deleting;
    },
    updateOptionsLoadingErrors(state, errors) {
        state.options_loading_errors = errors;
    },
    updateEndcustomers(state, endcustomers) {
        state.endcustomers = endcustomers;
    },
    updateEndcustomersLoading(state, loading) {
        state.endcustomers_loading = loading;
    },
    updateEndcustomersLoadingErrors(state, errors) {
        state.endcustomers_loading_errors = errors;
    },
    updateEndcustomerSites(state, endcustomersites) {
        state.endcustomersites = endcustomersites;
    },
    updateEndcustomerSitesLoading(state, loading) {
        state.endcustomersites_loading = loading;
    },
    updateEndcustomerSitesLoadingErrors(state, errors) {
        state.endcustomersites_loading_errors = errors;
    },

    updateStocks(state, stocks) {
        state.stocks = stocks;
        if (!state.shipment.stock && stocks.length) {
            let main_stock = state.stocks.find((stock) => stock.main) || state.stocks[0];
            state.shipment.stock = main_stock.id;
            state.stock = main_stock.id;
        }
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },

    toggleOption(state, catalogentry) {
        if (!state.shipment.options) {
            state.shipment.options = [];
        }
        if (!state.shipment.options.find((item) => catalogentry.id == item.catalogentry)) {
            state.shipment.options.push({ catalogentry: catalogentry.id });
        } else {
            state.shipment.options = state.shipment.options.filter((item) => catalogentry.id != item.catalogentry);
        }
    },

    updateStock(state, stock) {
        state.shipment.stock = stock;
        state.stock = stock;
    },
    updateDeliveryDate(state, delivery_date) {
        state.shipment.delivery_date = delivery_date;
        state.delivery_date = delivery_date;
    },
    updateEndcustomer(state, endcustomer) {
        state.shipment.endcustomer = endcustomer;
        state.shipment.endcustomersite = null;
        state.endcustomer = endcustomer;
        state.endcustomersite = null;
    },
    updateEndcustomerSite(state, endcustomersite) {
        state.shipment.endcustomersite = endcustomersite;
        state.endcustomersite = endcustomersite;
    },

};

const actions = {


    saveShipment({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateShipmentSaving', true);
        commit('updateShipmentSavingErrors', null);

        let url = '/api/shipments/';
        let method = axios.post;
        const shipment = JSON.parse(JSON.stringify(state.shipment || {}));

        switch (state.shipment_type) {
            case "endcustomersite":
                shipment.endcustomer = null;
                break;
            case "endcustomer":
                shipment.endcustomersite = null;
                break;
            case "pickup":
                shipment.endcustomer = null;
                shipment.endcustomersite = null;
                break;
        }

        if (shipment.id) {
            url = `/api/shipments/${shipment.shipment_id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {

            method(url, shipment)
                .then((response) => {
                    commit('updateShipmentSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateShipmentSaving', false);
                    commit('updateShipmentSavingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                });
        })

    },

    fetchOptions({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateOptionsLoading', true);
        commit('updateOptionsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get("/api/mycatalogentries/", { params: { trigger: "shipment-option", ordering: 'name' } })
                .then((response) => {
                    commit('updateOptions', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateOptionsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateOptionsLoading', false);
                });
        });
    },


    fetchEndcustomers({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndcustomersLoading', true);
        commit('updateEndcustomersLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/entities/', {
                params: { limit: 1000, ordering: "name", profile: "endcustomer" },
            })
                .then((response) => {
                    commit('updateEndcustomers', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateEndcustomersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndcustomersLoading', false);
                });
        });
    },

    fetchEndcustomerSites({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("core.view_endcustomersite") == -1) {
            return;
        }

        if (!state.shipment.endcustomer) {
            return;
        }

        commit('updateEndcustomerSitesLoading', true);
        commit('updateEndcustomerSitesLoadingErrors', null);
        return new Promise((resolve, reject) => {

            axios.get('/api/endcustomersites/', {
                params: { limit: 1000, ordering: "name", endcustomer: state.shipment.endcustomer },
            })
                .then((response) => {
                    commit('updateEndcustomerSites', response.data.results);
                    if (!state.shipment.endcustomersite && response.data.results.length) {
                        commit('updateEndcustomerSite', response.data.results[0].id);
                    }
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateEndcustomerSitesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndcustomerSitesLoading', false);
                });
        });
    },

    fetchStocks({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStocksLoading', true);
        commit('updateStocksLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/stocks/', {
                params: { limit: 1000 },
            })
                .then((response) => {
                    commit('updateStocks', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateStocksLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateStocksLoading', false);
                });
        });
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateShipment', params.shipment);
        dispatch('fetchStocks');
        dispatch('fetchOptions');
        dispatch('fetchEndcustomers');
        dispatch('fetchEndcustomerSites');
    },

    changeEndcustomer({ commit, dispatch, state }, endcustomer) { // eslint-disable-line no-unused-vars
        commit('updateEndcustomer', endcustomer);
        dispatch('fetchEndcustomerSites');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {
        documents: documents(),
    }
};
