import Vue from "vue";
import store from "@/stores/store";
import vuetify from "./plugins/vuetify";
import Router from 'vue-router';

import "@/assets/css/_base.scss";
import "@/plugins";

Vue.config.productionTip = false;
Vue.use(Router);

Vue.mixin({
    /**
     * Avoids redundand error when navigating to already active page
     */
    routerPush: function (route) {
        this.$router.push(route).catch((error) => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });
    },
});

async function init() {
    store
        .dispatch("session/fetchLoginInfo")
        .then(async () => {

            // Load locale settings
            const i18n = require('@/i18n');

            let app;
            let router;

            // Determine which app and router to load based on the user state
            if (store.getters["session/current_user_connected"]) {
                let entity = store.getters["session/current_entity"];

                if (store.getters["session/current_user_must_confirm_identity"]) {
                    app = (await import("@/apps/unverified")).default;
                    router = (await import("@/routers/unverified")).default;
                } else {
                    await store.dispatch("session/fetchOrganizations");
                    app = (await import("@/apps/connected")).default;
                    router = (await import(`@/routers/${entity.profile}`)).default;
                }
            } else {
                app = (await import("@/apps/anonymous")).default;
                router = (await import("@/routers/anonymous")).default;
            }

            // Mount the Vue instance
            let vue = new Vue({
                i18n,
                router,
                store,
                vuetify,
                render: (h) => h(app),
            }).$mount("#app");
        })
        .catch((error) => {
            console.log(error);
            alert("An error occured while fetching your information, please try again");
        });
}

init();