import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    endcustomers: [],
    endcustomers_loading: false,
    endcustomers_loading_errors: null,

    endcustomersites: [],
    endcustomersites_loading: false,
    endcustomersites_loading_errors: null,

    shipments: [],
    shipments_loading: false,
    shipments_loading_errors: null,

    shipmentitem: {},
    shipmentitem_saving: false,
    shipmentitem_saving_errors: {},

    stockproduct: {},
    stockproducts_filters: {},
};

const mutations = {
    updateEndCustomers(state, endcustomers) {
        state.endcustomers = endcustomers;
    },
    updateEndCustomersLoading(state, loading) {
        state.endcustomers_loading = loading;
    },
    updateEndCustomersLoadingErrors(state, errors) {
        state.endcustomers_loading_errors = errors;
    },

    updateEndCustomerSites(state, endcustomersites) {
        state.endcustomersites = endcustomersites;
    },
    updateEndCustomerSitesLoading(state, loading) {
        state.endcustomersites_loading = loading;
    },
    updateEndCustomerSitesLoadingErrors(state, errors) {
        state.endcustomersites_loading_errors = errors;
    },

    updateShipments(state, shipments) {
        state.shipments = shipments;
    },
    updateShipmentsLoading(state, loading) {
        state.shipments_loading = loading;
    },
    updateShipmentsLoadingErrors(state, errors) {
        state.shipments_loading_errors = errors;
    },

    updateShipmentItemSaving(state, saving) {
        state.shipmentitem_saving = saving;
    },
    updateShipmentItemSavingErrors(state, errors) {
        state.shipmentitem_saving_errors = errors;
    },

    updateShipmentItem(state, shipmentitem) {
        state.shipmentitem = shipmentitem;
        state.shipments_filters = {
            exclude_readonly: true,
            ready: false,
            ordering: "shipment_date",
            limit: 1000,
        }

        state.stockproducts_filters = {
            stock: state.shipmentitem.shipment_data ? state.shipmentitem.shipment_data.stock : null,
            product: state.shipmentitem.product
        };
        state.shipmentitem_saving_errors = [];
    },

    updateStockProduct(state, stockproduct) {
        state.stockproduct = stockproduct;
    },
};

const actions = {

    async fetchEndCustomers({ commit, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_endcustomer") === -1) {
            return;
        }

        commit('updateEndCustomersLoading', true);
        commit('updateEndCustomersLoadingErrors', null);

        try {
            const response = await this.request;
            commit('updateEndCustomers', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEndCustomersLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateEndCustomersLoading', false);
        }
    },

    async fetchEndCustomerSites({ commit, rootGetters }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_endcustomersite") === -1) {
            return;
        }

        commit('updateEndCustomerSitesLoading', true);
        commit('updateEndCustomerSitesLoadingErrors', null);

        const endcustomer_id = params.endcustomer_id;

        try {
            const response = await axios.get(`/api/endcustomersites/?endcustomer=${endcustomer_id}`, { params: { limit: 1000 } });
            commit('updateEndCustomerSites', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEndCustomerSitesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateEndCustomerSitesLoading', false);
        }
    },

    async fetchShipments({ commit, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_shipment") === -1) {
            return;
        }

        commit('updateShipmentsLoading', true);
        commit('updateShipmentsLoadingErrors', null);

        try {
            const response = await axios.get('/api/shipments/', { params: { ready: false, limit: 1000 } });
            commit('updateShipments', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateShipmentsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateShipmentsLoading', false);
        }
    },

    async fetchStockProduct({ commit, state }) {
        try {
            const response = await axios.get('/api/stockproducts/', { params: state.stockproducts_filters });
            commit('updateStockProduct', response.data.results ? response.data.results[0] : {});
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async saveShipmentItem({ commit, rootGetters, state }) {
        const permission = state.shipmentitem.id ? "core.change_shipmentitem" : "core.add_shipmentitem";
        if (rootGetters['session/current_user_permissions'].indexOf(permission) === -1) {
            return;
        }

        commit('updateShipmentItemSaving', true);
        commit('updateShipmentItemSavingErrors', {});

        let url = '/api/shipmentitems/';
        let method = axios.post;

        if (state.shipmentitem.id) {
            url = `/api/shipmentitems/${state.shipmentitem.id}/`;
            method = axios.put;
        }

        try {
            const response = await method(url, state.shipmentitem);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateShipmentItemSavingErrors', error.details);
            throw error;
        } finally {
            commit('updateShipmentItemSaving', false);
        }
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
