import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    producttype: null,
    producttype_loading: false,
    producttype_loading_errors: null,
    producttype_saving: false,
    producttype_saving_errors: null,
    producttype_deleting: false,
    producttype_deleting_errors: null,
};

const mutations = {

    updateProductType(state, producttype) {
        state.producttype = producttype;
    },
    updateProductTypeLoading(state, loading) {
        state.producttype_loading = loading;
    },
    updateProductTypeLoadingErrors(state, errors) {
        state.producttype_loading_errors = errors;
    },
    updateProductTypeSaving(state, saving) {
        state.producttype_saving = saving;
    },
    updateProductTypeSavingErrors(state, errors) {
        state.producttype_saving_errors = errors;
    },
    updateProductTypeDeleting(state, deleting) {
        state.producttype_deleting = deleting;
    },
    updateProductTypeDeletingErrors(state, errors) {
        state.producttype_deleting_errors = errors;
    },
};

const actions = {

    fetchProductType({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductTypeLoading', true);
        commit('updateProductTypeLoadingErrors', null);

        let url;
        if (params && params.producttype_id) {
            url = `/api/producttypes/${params.producttype_id}/`;
        } else {
            throw 'No producttype to fetch';
        }
        return new Promise((resolve, reject) => {

            axios.get(url)
                .then((response) => {
                    commit('updateProductType', response.data);
                    commit('updateProductTypeLoading', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypeLoading', false);
                    commit('updateProductTypeLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    dispatch('fetchProductTypeItems');
                    dispatch('fetchWaitingProductTypeItems');
                    dispatch('fetchProducts');
                    dispatch('fetchProviderStats');
                })
        });
    },


    deleteProductType({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductTypeDeleting', true);
        commit('updateProductTypeDeletingErrors', null);

        const url = `/api/producttypes/${params.instance.id}/`;
        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    commit('updateProductTypeDeleting', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypeDeleting', false);
                    commit('updateProductTypeDeletingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                });
        });
    },


    saveProductType({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProductTypeSaving', true);
        commit('updateProductTypeSavingErrors', null);

        let url = '/api/producttypes/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/producttypes/${params.instance.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    //dispatch('fetchProductType');
                    commit('updateProductTypeSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypeSaving', false);
                    commit('updateProductTypeSavingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                })
        })
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
