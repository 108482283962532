import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';

const state = {
    providers: [],
    filters: { offset: 0, limit: 20, ordering: "name" },
    providers_loading: false,
    providers_count: 0,
    providers_loading_errors: null,
    synchronizing_providers: false,
    synchronizing_providers_errors: null,
};

const mutations = {
    updateFilters(state, filters) {
        state.filters = filters;
    },
    updateProviders(state, providers) {
        state.providers = providers;
    },
    updateProvidersCount(state, providers_count) {
        state.providers_count = providers_count;
    },
    updateProvidersLoading(state, loading) {
        state.providers_loading = loading;
    },
    updateProvidersLoadingErrors(state, errors) {
        state.providers_loading_errors = errors;
    },
    updateSynchronizingProviders(state, synchronizing) {
        state.synchronize_providers = synchronizing;
    },
    updateSynchronizingProvidersErrors(state, errors) {
        state.synchronizing_providers_errors = errors;
    },
};

const actions = {

    exportProvidersAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/providers/?" + queryparams);
    },

    synchronizeProviders({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateSynchronizingProviders', true);
        commit('updateSynchronizingProvidersErrors', null);

        let url = '/api/sync/providers/';

        return new Promise((resolve, reject) => {
            axios.post(url, { payload: params.payload })
                .then((response) => {
                    commit('updateSynchronizingProviders', false);
                    resolve(response.data.payload);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    error.details = error.details.payload;
                    commit('updateSynchronizingProviders', false);
                    commit('updateSynchronizingProvidersErrors', error.details);
                    reject(error);
                });
        });
    },

    fetchProviders({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateProvidersLoading', true);
        commit('updateProvidersLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/providers/', {
                params: state.filters,
            })
                .then((response) => {
                    commit('updateProvidersLoading', false);
                    commit('updateProviders', response.data.results);
                    commit('updateProvidersCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateProvidersLoadingErrors', error.details);
                    commit('updateProvidersLoading', false);
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
