import Vue from 'vue';
import axios from '@/plugins/axios';
import storeUtils from '@/stores/utils';
import utils from '@/utils';
import sendinvoice from "./submodules/sendinvoice";
import store from '@/stores/store';

const state = {
    billingaccount: null,
    endcustomer_email_available: false,
    endcustomer: null,
    order: null,
    sending_invoice_errors: {},
    sending_invoice: false,
};


const mutations = {

    updateOrder(state, order) {
        state.order = order;
        state.sending_invoice = false;
        state.sending_invoice_errors = {};
    },

    updateSendingInvoice(state) {
        state.sending_invoice = true;
        state.sending_invoice_errors = {};
    },
    updateSendingInvoiceErrors(state, sending_invoice_errors) {
        state.sending_invoice_errors = sending_invoice_errors;
        state.sending_invoice = false;
    },

    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
    },

    updateEndCustomer(state, endcustomer) {
        state.endcustomer = endcustomer;
        state.endcustomer_email_available = !!endcustomer?.billing_address?.email;
    },

};

const getSubject = (order) => {
    if (!order) {
        return Vue.prototype.$gettext('New invoice');
    }
    let translated = null;
    if (order.suborders_count > 1) {
        translated = Vue.prototype.$gettext('New invoices for order {order_id}');
    } else {
        translated = Vue.prototype.$gettext('New invoice for order {order_id}');
    }
    translated = translated.replace('{order_id}', order.order_id);
    return translated;
};


const getMessage = (order) => {
    if (!order) {
        return Vue.prototype.$gettext('New invoice');
    }
    let translated = null;
    if (order.suborders_count > 1) {
        translated = Vue.prototype.$gettext('Hello,\n\nPlease find attached invoices for order {order_id}.\n\nBest regards.')
    } else {
        translated = Vue.prototype.$gettext('Hello,\n\nPlease find attached invoice for order {order_id}.\n\nBest regards.')
    }
    translated = translated.replace('{order_id}', order.order_id);
    return translated;
};

const actions = {

    sendInvoice({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let sendparams = {
            receive_copy: store.state.sendorderinvoice.sendinvoice['receive_copy'],
            recipients: store.state.sendorderinvoice.sendinvoice['recipients'],
            subject: store.state.sendorderinvoice.sendinvoice['subject'],
            message: store.state.sendorderinvoice.sendinvoice['message'],
            save_message: store.state.sendorderinvoice.sendinvoice['save_message'],
        };

        return new Promise((resolve, reject,) => {
            commit('updateSendingInvoice');
            commit('sendinvoice/updateSendingInvoice');
            axios.post(`/api/orders/${state.order.id}/sendinvoice/`, sendparams)
                .then((response) => {
                    resolve(response.data);
                    dispatch('session/fetchStats', null, { root: true });
                    dispatch('billingstats/fetchAllStats', null, { root: true });
                })
                .catch((xhr_error) => {
                    const error = storeUtils.handleError(xhr_error);
                    commit('updateSendingInvoiceErrors', error.details);
                    commit('sendinvoice/updateSendingInvoiceErrors', error.details);
                    reject(error);
                })
        });
    },


    fetchBillingAccount({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject,) => {
            axios.get("/api/billingaccounts/", { params: { entity: state.endcustomer.id } })
                .then((response) => {
                    if (response.data.results?.length) {
                        commit('updateBillingAccount', response.data.results[0]);
                        commit('sendinvoice/updateMessage', response.data.results[0].invoice_notification_text || getMessage(state.order));
                    } else {
                        state.message = null;
                    }
                })
                .catch((xhr_error) => {
                    const error = storeUtils.handleError(xhr_error);
                    reject(error);
                })
        });
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateOrder', params?.order);
        commit('updateEndCustomer', params?.order?.endcustomer_data);
        let email = params.order?.endcustomer_data?.billing_address?.email;
        dispatch('sendinvoice/init', {
            recipients: email ? [email] : [],
            subject: getSubject(params?.order),
            message: getMessage(params?.order),
        });

        if (params?.order) {
            dispatch('fetchBillingAccount');
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        sendinvoice: sendinvoice(),
    }
};
