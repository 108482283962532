import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const createState = () => ({
    stocks: [],
    stocks_count: 0,
    stocks_loading: false,
    stocks_loading_errors: null,
    stocks_filters: { offset: 0, limit: 20, },
});


const mutations = {

    updateStocks(state, stocks) {
        state.stocks = stocks;
    },
    updateStocksCount(state, count) {
        state.stocks_count = count;
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },
    updateStocksFilters(state, filters) {
        state.stocks_filters = filters;
    },
};


const actions = {

    setStockAsDefault({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            const url = `/api/stocks/${state.stock.id}/default/`;
            axios.post(url)
                .then((response) => {
                    resolve(response);
                    dispatch('fetchStock')
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    fetchStocks({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStocksLoading', true);
        commit('updateStocksLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/stocks/', { params: state.stocks_filters })
                .then((response) => {
                    commit('updateStocksLoading', false);
                    commit('updateStocks', response.data.results);
                    commit('updateStocksCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStocksLoadingErrors', error.details);
                    commit('updateStocksLoading', false);
                    reject(error);
                });
        });
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStocksFilters', Object.assign({}, state.stocks_filters, params.filters));
    },

};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});