import qs from 'qs';
import axios from '@/plugins/axios';
import storeutils from '@/stores/utils';
import utils from '@/utils';
import store from '@/stores/store';

const state = {
    invoiceitem: null,
    invoiceitem_saving: false,
    invoiceitem_saving_errors: {},
    vat: null,
    step: "search",
    mode: "catalog",
    catalogentry: null,
    billed: null,
    invoice: null,
    invoices: null,
    invoices_loading: false,
    invoices_loading_errors: null,
};

const mutations = {

    updateInvoiceItem(state, invoiceitem) {
        state.invoiceitem = invoiceitem;
        state.invoice = invoiceitem?.invoice;
        state.invoiceitem_saving = false;
        state.invoiceitem_saving_errors = {};

        state.vat = state.invoiceitem.vat !== null ? utils.toPercent(state.invoiceitem.vat) : null;
        if (state.invoiceitem.id) {
            state.step = "configure";
            if (state.invoiceitem.catalogentry) {
                state.catalogentry = state.invoiceitem.catalogentry;
            }
        }
    },

    updateCatalogEntry(state, catalogentry) {
        state.catalogentry = catalogentry;
        state.invoiceitem = {
            invoice: state.invoiceitem.invoice,
            customer: state.params.customer,
            endcustomer: state.params.endcustomer,
            catalogentry: catalogentry.id,
            unit_price_excl_vat: catalogentry.price,
            quantity: 1,
            label: catalogentry.name,
        };
        if (state.invoiceitem.vat !== null) {
            state.vat = utils.toPercent(state.invoiceitem.vat);
        } else {
            state.vat = null;
        }
    },

    updateVat(state, vat) {
        state.vat = vat;
        if (vat === null) {
            state.invoiceitem.vat = null;
        } else {
            state.invoiceitem.vat = vat / 100.0;
        }
    },


    updateInvoiceItemSaving(state, invoiceitem_saving) {
        state.invoiceitem_saving = invoiceitem_saving;
    },

    updateInvoiceItemSavingErrors(state, invoiceitem_saving_errors) {
        state.invoiceitem_saving_errors = invoiceitem_saving_errors;
    },

    updateInvoices(state, invoices) {
        state.invoices = invoices;
    },
    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },
    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },
    updateInvoice(state, invoice) {
        state.invoice = invoice;
        state.invoiceitem.invoice = invoice;
    },

    updateBilled(state, billed) {
        state.billed = billed;
    },

};

const actions = {

    async fetchInvoices({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateInvoices', []);
        commit('updateInvoicesLoading', true);
        try {
            const response = await axios.get('/api/invoices/', { params: { billed: state.billed, status: 'draft', 'ordering': '-invoice_date' } });
            commit('updateInvoices', response.data.results);
            return response.data;
        } catch (xhr_error) {
            const error = storeutils.handleError(xhr_error);
            commit('updateInvoicesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateInvoicesLoading', false);
        }
    },


    saveInvoiceItem({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        commit('updateInvoiceItemSaving', true);
        let url = '/api/invoiceitems/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/invoiceitems/${params.instance.id}/`;
            method = axios.put;
        }

        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = storeutils.handleError(xhr_error);
                    commit('updateInvoiceItemSavingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateInvoiceItemSaving', false);
                    dispatch('billingstats/fetchAllStats', null, { root: true });
                });
        });
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateInvoiceItem', params.invoiceitem);
        commit('updateBilled', params.billed);
        if (params.invoice) {
            commit('updateInvoice', params.invoice);
        }
        dispatch('fetchInvoices');
    }
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
