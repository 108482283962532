import qs from 'qs';
import catalogs from './submodules/catalogs';
import catalogagentassignments from './submodules/catalogagentassignments';

const state = {
    section: null,
};

const mutations = {
    updateSection(state, section) {
        state.section = section;
    },
};

const actions = {

    exportCatalogsAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.catalogs_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/catalogs/?" + queryparams);
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        dispatch('mycatalogs/init', { filters: { owned: true } });
        dispatch('othercatalogs/init', { filters: { owned: false } });
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        mycatalogs: catalogs(),
        othercatalogs: catalogs(),
    }
};
