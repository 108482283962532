var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": _vm.type == 'icon',
            "light": "",
            "x-small": "",
            "alt": _vm.label,
            "width": _vm.type != 'button' && _vm.icon ? 35 : null,
            "heigth": _vm.type != 'button' && _vm.icon ? 35 : null,
            "disabled": _vm.disabled,
            "color": _vm.type == 'button' ? _vm.color : null
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('click', $event);
            }
          }
        }, 'v-btn', attrs, false), on), [_vm.icon ? _c('v-icon', {
          attrs: {
            "small": "",
            "color": _vm.color
          }
        }, [_vm._v(" " + _vm._s(_vm.iconName(_vm.icon)))]) : _vm._e(), _vm.type == 'button' || !_vm.icon ? [_vm._v(" " + _vm._s(_vm.label) + " ")] : _vm._e()], 2)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.placeholder || _vm.label))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }