import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    receipt_id: null,
    provider_id: null,
    receiptitem: {},
    receiptitem_saving: false,
    receiptitem_saving_errors: {},

    receipts: [],
    receipts_count: 0,
    receipts_filters: { limit: 1000, exclude_readonly: true, ready: false, ordering: "receipt_date" },
    receipts_loading: false,
    receipts_loading_errors: null,

    productprices: [],
    productprices_loading: false,
    productprices_loading_errors: null,
};

const mutations = {
    updateProduct(state, product) {
        state.product = product;
        state.receipts = [];
        state.receipts_count = 0;
        state.receipts_filters = {
            exclude_readonly: true,
            status: "waiting",
            ready: false,
            provider: product ? product.provider : null,
            ordering: "receipt_date",
            limit: 1000,
        };
    },
    updateReceiptItem(state, receiptitem) {
        state.receipts = [];
        state.receipts_count = 0;
        state.receiptitem = receiptitem;
        if (state.receiptitem.product_data &&
            state.receiptitem.product_data.providers &&
            state.receiptitem.product_data.providers.length == 1
        ) {
            state.receiptitem.product_data.providers[0].provider
        }
        state.product = receiptitem.product;
        state.receipt_id = receiptitem.receipt;
        state.receipts_filters = {
            exclude_readonly: true,
            status: "waiting",
            ready: false,
            provider: state.provider,
            ordering: "receipt_date",
            limit: 1000,
        }
    },
    updateProvider(state, provider) {
        state.provider_id = provider;
        state.receipts = [];
        state.receipts_count = 0;
        state.receipts_filters = {
            exclude_readonly: true,
            status: "waiting",
            ready: false,
            provider: provider,
            ordering: "receipt_date",
            limit: 1000,
        };
    },
    updateReceiptId(state, receipt_id) {
        state.receiptitem.receipt = receipt_id;
        state.receipt_id = receipt_id;
    },
    updateReceiptItemSaving(state, saving) {
        state.receiptitem_saving = saving;
    },
    updateReceiptItemSavingErrors(state, errors) {
        state.receiptitem_saving_errors = errors;
    },
    updateReceipts(state, receipts) {
        state.receipts = receipts;
    },
    updateReceiptsFilters(state, filters) {
        state.receipts_filters = filters;
    },
    updateReceiptsCount(state, count) {
        state.receipts_count = count;
    },
    updateReceiptsLoading(state, loading) {
        state.receipts_loading = loading;
    },
    updateReceiptsLoadingErrors(state, errors) {
        state.receipts_loading_errors = errors;
    },
    updateProductPrices(state, productprices) {
        state.productprices = productprices;
    },
    updateProductPricesLoading(state, loading) {
        state.productprices_loading = loading;
    },
    updateProductPricesLoadingErrors(state, errors) {
        state.productprices_loading_errors = errors;
    },
};
const actions = {

    selectProvider({ commit, dispatch, rootGetters }, provider) {
        commit('updateProvider', provider);
        dispatch('fetchReceipts');
    },

    async fetchProductPrices({ commit, dispatch, state, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_productprice") === -1) {
            return;
        }

        commit('updateProductPricesLoading', true);
        commit('updateProductPricesLoadingErrors', null);

        try {
            const response = await axios.get('/api/productprices/', { params: { limit: 1000, product: state.receiptitem.product } });
            commit('updateProductPrices', response.data.results);
            if (response.data.results && response.data.results.length === 1) {
                commit('updateProvider', response.data.results[0].provider);
            }
            dispatch('fetchReceipts');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductPricesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductPricesLoading', false);
        }
    },

    async fetchReceipts({ commit, state, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_receipt") === -1) {
            return;
        }

        commit('updateReceiptsLoading', true);
        commit('updateReceiptsLoadingErrors', null);

        try {
            const response = await axios.get('/api/receipts/', { params: state.receipts_filters });
            commit('updateReceipts', response.data.results);
            commit('updateReceiptsCount', response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateReceiptsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateReceiptsLoading', false);
        }
    },

    async saveReceiptItem({ commit, state, rootGetters }) {
        const hasAddPermission = rootGetters['session/current_user_permissions'].indexOf("core.add_receiptitem") !== -1;
        const hasChangePermission = rootGetters['session/current_user_permissions'].indexOf("core.change_receiptitem") !== -1;

        if ((!state.receiptitem.id && !hasAddPermission) || (state.receiptitem.id && !hasChangePermission)) {
            return;
        }

        commit('updateReceiptItemSaving', true);
        commit('updateReceiptItemSavingErrors', {});

        try {
            let url = '/api/receiptitems/';
            let method = axios.post;

            if (state.receiptitem.id) {
                url = `/api/receiptitems/${state.receiptitem.id}/`;
                method = axios.put;
            }
            const response = await method(url, state.receiptitem);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateReceiptItemSavingErrors', error.details);
            throw error;
        } finally {
            commit('updateReceiptItemSaving', false);
        }
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
