import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    endcustomersites: [],
    endcustomersites_count: 0,
    endcustomersites_filters: { offset: 0, limit: 20, ordering: 'name' },
    endcustomersites_loading: false,
    endcustomersites_loading_errors: null,
};

const mutations = {
    updateEndCustomerSites(state, endcustomersites) {
        state.endcustomersites = endcustomersites;
    },
    updateEndCustomerSitesCount(state, count) {
        state.endcustomersites_count = count;
    },
    updateEndCustomerSitesFilters(state, filters) {
        state.endcustomersites_filters = filters;
    },
    updateEndCustomerSitesLoading(state, loading) {
        state.endcustomersites_loading = loading;
    },
    updateEndCustomerSitesLoadingErrors(state, errors) {
        state.endcustomersites_loading_errors = errors;
    },
};

const actions = {


    fetchEndCustomerSites({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("core.view_endcustomersite") == -1) {
            return;
        }

        commit('updateEndCustomerSitesLoading', true);
        commit('updateEndCustomerSitesLoadingErrors', null);

        return new Promise((resolve, reject) => {

            this.request = axios.get("/api/endcustomersites/", { params: state.endcustomersites_filters })
                .then((response) => {
                    commit('updateEndCustomerSites', response.data.results);
                    commit('updateEndCustomerSitesCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateEndCustomerSitesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndCustomerSitesLoading', false);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
