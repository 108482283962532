import axios from '@/plugins/axios';
import utils from '@/stores/utils';

function mapErrors(payload, error) {
    if (error.payload) {
        error.payload.forEach((item, index) => {
            payload[index] = {
                errors: item,
                item: payload[index],
            };
        });
    }
    return payload;
}

function convertPayload(data, fields_mapping) {
    var payload = [];

    data.forEach((item) => {

        var mapped_item = {};
        var index = 0;

        item.forEach((col) => {
            if (col == "") {
                col = null;
            }
            if (fields_mapping[index]) {
                let dict = mapped_item;
                let parts = fields_mapping[index].split(".");
                let last_field = parts[parts.length - 1];
                parts = parts.slice(0, parts.length - 1);
                parts.forEach((field) => {
                    if (!dict[field]) {
                        dict[field] = {};
                    }
                    dict = dict[field];
                })
                dict[last_field] = col;

            }
            index += 1;
        });
        payload.push(mapped_item);
    });
    return payload;
}

const createState = () => ({
    file: null,
    data: null,
    step: 'selectfile', // selectfile, configure, synchronizing, results
    error: false,
    server_error: false,
    global_errors: null,
    payload: null,

    fields: [],
    fields_mapping: [],
    ignore_first_line: false,
    extra_params: {},
});

const mutations = {
    updateStep(state, step) {
        state.step = step;
    },
    updateError(state, error) {
        state.error = error;
    },
    updateServerError(state, server_error) {
        state.server_error = server_error;
    },
    updateFile(state, file) {
        state.file = file;
    },
    updateData(state, data) {
        state.data = data;
    },
    updatePayload(state, payload) {
        state.payload = payload;
    },
    updateFieldsMapping(state, fields_mapping) {
        state.fields_mapping = fields_mapping;
    },
    updateFieldMapping(state, params) {
        state.fields_mapping.forEach((item, item_index) => {
            if (item_index != params.index) {
                if (params.value == item) {
                    state.fields_mapping[item_index] = null;
                }
            } else {
                state.fields_mapping[item_index] = params.value;
            }
        });
    },
    updateFields(state, fields) {
        let fields_mapping = [];

        state.fields = fields;
        state.fields_mapping = [];
    },
    updateIgnoreFirstLine(state, ignore_first_line) {
        state.ignore_first_line = ignore_first_line;
    },
    updateGlobalErrors(state, global_errors) {
        state.global_errors = global_errors;
    },
    updateExtraParams(state, extra_params) {
        state.extra_params = extra_params;
    },
}

const actions = {

    changeData({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateData', params.data);
        commit('updateStep', 'configure');
        dispatch("guessFieldsMapping");
    },

    synchronize({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateStep', 'synchronizing');

        let payload = convertPayload(
            state.ignore_first_line ? state.data.slice(1) : state.data,
            state.fields_mapping);

        commit('updatePayload', payload);
        let request_params = Object.assign({}, state.extra_params, { payload });

        return new Promise((resolve, reject) => {

            axios.post(`/api/sync/${params.type}/`, request_params)
                .then((response) => {
                    commit('updatePayload', response.data.payload);
                    commit('updateError', false);
                    commit('updateGlobalErrors', null);
                    dispatch('session/fetchStats', null, { root: true });

                    resolve();
                })
                .catch((xhr_error) => {
                    commit('updateError', true);

                    let error = utils.handleError(xhr_error);
                    commit('updateServerError', Number.parseInt(error.code / 100) == 5);

                    if (Number.parseInt(error.code / 100) == 4) {
                        let payload = mapErrors(state.payload, error.details);
                        commit('updatePayload', payload);
                        if (error.details.detail) {
                            commit('updateGlobalErrors', [error.details.detail]);
                        } else {
                            commit('updateGlobalErrors', error.details.non_field_errors);
                        }
                    } else {
                        commit('updateGlobalErrors', []);
                    }

                    reject(error);
                })
                .finally(() => {
                    commit('updateStep', 'results');
                });
        });
    },

    guessFieldsMapping({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let mapping_found = false;
        const first_line = state.data[0];
        let fields_mapping = [];
        first_line.forEach((cell) => {
            const field = state.fields.find((field) => field.value == cell || field.text == cell);
            fields_mapping.push(field ? field.value : null);
            if (field) {
                mapping_found = true;
            }
        })
        commit('updateFieldsMapping', fields_mapping);
        commit('updateIgnoreFirstLine', mapping_found);
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});