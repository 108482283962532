import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    shipmentitems: [],
    shipmentitems_url: '/api/shipmentitems/',
    shipmentitems_filters: { offset: 0, limit: 20, },
    shipmentitems_count: 0,
    shipmentitems_loaded: false,
    shipmentitems_loading: false,
    shipmentitems_loading_errors: null,

    shipmentitems_stats: {},
    shipmentitems_stats_loaded: false,
    shipmentitems_stats_loading: false,
    shipmentitems_stats_loading_errors: null,
});

const mutations = {
    updateShipmentItems(state, shipmentitems) {
        state.shipmentitems = shipmentitems;
        state.shipmentitems_loaded = true;
        state.shipmentitems_loading = false;
        state.shipmentitems_loading_errors = null;
    },
    updateShipmentItemsUrl(state, shipmentitems_url) {
        state.shipmentitems_url = shipmentitems_url;
    },
    updateShipmentItemsCount(state, shipmentitems_count) {
        state.shipmentitems_count = shipmentitems_count;
    },
    updateShipmentItemsFilters(state, shipmentitems_filters) {
        state.shipmentitems_filters = shipmentitems_filters;
    },
    updateShipmentItemsLoading(state, shipmentitems_loading) {
        state.shipmentitems_loading = shipmentitems_loading;
    },
    updateShipmentItemsLoadingErrors(state, shipmentitems_loading_errors) {
        state.shipmentitems_loading_errors = shipmentitems_loading_errors;
        state.shipmentitems_loading = false;
    },

    updateShipmentItemsStats(state, shipmentitems_stats) {
        state.shipmentitems_stats = shipmentitems_stats;
        state.shipmentitems_stats_loaded = true;
    },
    updateShipmentItemsStatsLoading(state, loading) {
        state.shipmentitems_stats_loading = loading;
    },
    updateShipmentItemsStatsLoadingErrors(state, errors) {
        state.shipmentitems_stats_loading_errors = errors;
    },

};

const actions = {

    fetchShipmentItems({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("core.view_shipmentitem") == -1) {
            return;
        }

        commit('updateShipmentItemsLoading', true);
        commit('updateShipmentItemsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get(state.shipmentitems_url, { params: state.shipmentitems_filters })
                .then((response) => {
                    commit('updateShipmentItemsCount', response.data.count);
                    commit('updateShipmentItems', response.data.results);
                    dispatch('fetchShipmentItemsStats');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateShipmentItemsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateShipmentItemsLoading', false);
                });

        });
    },

    async fetchShipmentItemsStats({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        if (store.getters['session/current_user_permissions'].indexOf("order.view_allocation") == -1) {
            return;
        }

        commit('updateShipmentItemsStatsLoading', true);
        commit('updateShipmentItemsStatsLoadingErrors', null);

        try {
            const response = await axios.get('/api/shipmentitems/stats/', { params: state.shipmentitems_filters });
            commit('updateShipmentItemsStatsLoading', false);
            commit('updateShipmentItemsStats', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateShipmentItemsStatsLoadingErrors', error.details);
            commit('updateShipmentItemsStatsLoading', false);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateShipmentItems', []);
        const default_filters = { offset: 0, limit: 20, ordering: '-creation_date' };
        commit('updateShipmentItemsFilters', { ...default_filters, ...(params?.filters || {}) });
        if (params && params.url) {
            commit('updateShipmentItemsUrl', params.url);
        }
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});