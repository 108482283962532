import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    endcustomersite: null,
    endcustomersite_saving: false,
    endcustomersite_errors: {},
};

const mutations = {

    updateEndCustomerSite(state, endcustomersite) {
        state.endcustomersite = Object.assign({ _endcustomer: {}, endcustomer: null }, JSON.parse(JSON.stringify(endcustomersite)));
        if (!state.endcustomersite.address) {
            state.endcustomersite.address = {};
        }
        state.endcustomersite_errors = {};
    },

    updateEndCustomerSiteErrors(state, errors) {
        state.endcustomersite_errors = errors;
    },
    updateEndCustomerSiteSaving(state, saving) {
        state.endcustomersite_saving = saving;
    },
};

const actions = {

    saveEndCustomerSite({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateEndCustomerSiteSaving', true);

        let url = '/api/endcustomersites/';
        let method = axios.post;

        if (state.endcustomersite.id) {
            url = `/api/endcustomersites/${state.endcustomersite.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, state.endcustomersite)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateEndCustomerSiteErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEndCustomerSiteSaving', false);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
