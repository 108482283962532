var render = function render(){
  var _vm$content, _vm$content$fields, _vm$content2, _vm$content2$fields;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModalContent', [_c('template', {
    slot: "title"
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s((_vm$content = _vm.content) === null || _vm$content === void 0 ? void 0 : (_vm$content$fields = _vm$content.fields) === null || _vm$content$fields === void 0 ? void 0 : _vm$content$fields.title))])]), _c('template', {
    slot: "content"
  }, [_vm.content ? _c('ContentfulRichTextRenderer', {
    attrs: {
      "document": (_vm$content2 = _vm.content) === null || _vm$content2 === void 0 ? void 0 : (_vm$content2$fields = _vm$content2.fields) === null || _vm$content2$fields === void 0 ? void 0 : _vm$content2$fields.helpText
    }
  }) : _vm.error ? _c('div', {
    key: "error"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm.loading ? _c('div', {
    key: "loading"
  }, [_c('Loading', {
    attrs: {
      "absolute": false,
      "size": 200
    }
  })], 1) : _c('div', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    key: "notfound"
  }, [_vm._v("Help not found.")])], 1), _c('template', {
    slot: "bottom"
  }, [_c('Button', {
    staticClass: "my-2 my-sm-4 w-sm-100 ml-sm-3",
    attrs: {
      "text": _vm.$gettext('Close')
    },
    on: {
      "click": _vm.hideModal
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }