import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';

const createState = () => ({
    groups: [],
    groups_filters: { offset: 0, limit: 20, },
    groups_count: 0,
    groups_loaded: false,
    groups_loading: false,
    groups_loading_errors: null,

    group_deleting: false,
    group_deleting_errors: null,
});


const mutations = {
    updateGroups(state, groups) {
        state.groups = groups;
        state.groups_loaded = true;
    },
    updateGroupsCount(state, count) {
        state.groups_count = count;
    },
    updateGroupsFilters(state, filters) {
        state.groups_filters = filters;
    },
    updateGroupsLoading(state, loading) {
        state.groups_loading = loading;
    },
    updateGroupsLoadingErrors(state, errors) {
        state.groups_loading_errors = errors;
    },

    updateGroupDeleting(state, deleting) {
        state.group_deleting = deleting;
    },
    updateGroupDeletingErrors(state, errors) {
        state.group_deleting_errors = errors;
    },
    updateGroupsLoaded(state, loaded) {
        state.groups_loaded = loaded;
    },
};

const actions = {

    exportGroupsAsCSV({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.groups_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/groups/?" + queryparams);
    },

    async deleteGroup({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        const url = `/api/groups/${params.instance.id}/`;

        commit('updateGroupDeleting', true);
        commit('updateGroupDeletingErrors', null);

        try {
            const response = await axios.delete(url);
            commit('updateGroupDeleting', false);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateGroupDeleting', false);
            commit('updateGroupDeletingErrors', error.details);
            throw error;
        }
    },

    async fetchGroups({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateGroupsLoading', true);
        commit('updateGroupsLoadingErrors', null);

        try {
            const response = await axios.get('/api/groups/', { params: state.groups_filters });
            commit('updateGroupsLoading', false);
            commit('updateGroups', response.data.results);
            commit('updateGroupsCount', response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateGroupsLoadingErrors', error.details);
            commit('updateGroupsLoading', false);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateGroupsLoaded', false);
        commit('updateGroups', []);
        commit('updateGroupsCount', 0);
        commit('updateGroupsFilters', { ...{ offset: 0, limit: 20 }, ...params?.filters || {} });
    }
};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});