<template>
    <div class="address mb-3">
        <h6 v-if="title" class="mt-3 text-overline font-weight-bold">
            {{ title }}
        </h6>
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    hide-details
                    v-model="address.contact_name"
                    :error-messages="errors.contact_name"
                    :label="$translate('contactname')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    hide-details
                    v-model="address.line1"
                    :error-messages="errors.line1"
                    :label="$translate('address')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <v-text-field
                    hide-details
                    v-model="address.postal_code"
                    :error-messages="errors.postal_code"
                    :label="$translate('postalCode')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    hide-details
                    v-model="address.city"
                    :error-messages="errors.city"
                    :label="$translate('city')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    hide-details
                    v-model="address.country"
                    :error-messages="errors.country"
                    :label="$translate('country')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <v-text-field
                    hide-details
                    v-model="address.mobile_phone_number"
                    :error-messages="errors.mobile_phone_number"
                    :label="$translate('mobileNumber')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    hide-details
                    v-model="address.phone_number"
                    :error-messages="errors.phone_number"
                    :label="$translate('phoneNumber')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="address.email"
                    :error-messages="errors.email"
                    :label="$translate('email')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
        },
        value: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    watch: {
        value(value) {
            this.address = value;
        },
    },
    data() {
        return { address: JSON.parse(JSON.stringify(this.value)) };
    },
    i18n: {
        messages: {
            en: {
                contactname: "Contact name",
                address: "Address",
                postalCode: "Postal code",
                city: "City",
                country: "Country",
                mobileNumber: "Mobile number",
                phoneNumber: "Phone number",
                email: "Email",
            },
            fr: {
                contactname: "Nom du contact",
                address: "Adresse",
                postalCode: "Code postal",
                city: "Ville",
                country: "Pays",
                mobileNumber: "Numéro de portable",
                phoneNumber: "Numéro de téléphone",
                email: "Email",
            },
        },
    },
};
</script>
