import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    zone: null,
    zone_saving: false,
    zone_errors: {}
};

const mutations = {
    updateZone(state, zone) {
        state.zone = zone;
        state.zone_errors = {};
    },
    updateZoneSaving(state, zone_saving) {
        state.zone_saving = zone_saving;
    },
    updateZoneErrors(state, zone_errors) {
        state.zone_errors = zone_errors;
    },
};

const actions = {

    saveZone({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateZoneSaving', true);

        let url = '/api/zones/';
        let method = axios.post;

        if (state.zone.zone.id) {
            url = `/api/zones/${state.zone.zone.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, state.zone.zone)
                .then(resolve)
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateZoneErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateZoneSaving', false);
                })
        });
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
