import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    settings: {},
    settings_changed: false,
    settings_saving: false,
    settings_saving_errors: {},

    entity: {},
    entity_id: null,
    entity_loading: false,
    entity_saving: false,
    entity_saving_errors: {},

    apikey: null,
    apikey_revoked: false,
    apikey_revoking: false,
    apikey_revoking_errors: null,
    apikey_generating: false,
    apikey_generating_errors: null,

    keys_to_save: [],

    apiinfo: null,
    apiinfo_fetching: false,
    apiinfo_fetching_errors: null,

    notifications_events: [],
    notifications_events_loading: false,

    notifications_subscriptions: [],
    notifications_subscriptions_loading: false,
    notifications_subscriptions_saving: false,

    dashboard_logo_uploading: false,
};
const getters = {
    dashboard_logo: state => state.dashboard_logo,
};

const mutations = {
    updateSettingsChanged(state, changed) {
        state.settings_changed = changed;
    },

    updateDashboardLogo(state, dashboard_logo) {
        state.settings.dashboard_logo = dashboard_logo;
    },

    updateSettings(state, settings) {
        state.settings = settings;
        state.settings_saving_errors = {};
    },

    updateSettingsSavingErrors(state, errors) {
        state.settings_saving_errors = errors;
    },
    updateSettingsSaving(state, saving) {
        state.settings_saving = saving;
        if (saving) {
            state.settings_saving_errors = {};
        }
    },

    updateOperatorSaving(state, saving) {
        state.entity_saving = saving;
    },
    updateMovementsLoading(state, loading) {
        state.entity_loading = loading;
    },
    updateEntity(state, entity) {
        state.entity = entity;
        state.entity_saving = false;
        state.entity_saving_errors = {};
    },
    updateEntitySaving(state, entity_saving) {
        state.entity_saving = entity_saving;
        state.entity_saving_errors = {};
    },
    updateEntitySavingErrors(state, errors) {
        state.entity_saving = false;
        state.entity_saving_errors = errors;
    },
    changeEntity(state, entity) {
        state.entity = Object.assign({}, state.entity, entity);
        state.settings_changed = true;
    },
    changeSettings(state, settings) {
        // add settings keys to keys_to_save
        for (let key in settings) {
            if (state.keys_to_save.indexOf(key) == -1) {
                state.keys_to_save.push(key);
            }
        }
        state.settings = Object.assign({}, state.settings, settings);
        state.settings_changed = true;
    },
    updateEntityId(state, entity_id) {
        state.entity_id = entity_id;
    },

    updateApiKey(state, apikey) {
        state.apikey = apikey;
        state.apikey_generating = false;
        state.apikey_generating_errors = null;
        state.apikey_revoking = false;
        state.apikey_revoking_errors = null;
    },
    updateApiKeyGenerating(state, apikey_generating) {
        state.apikey_generating = apikey_generating;
    },
    updateApiKeyGeneratingErrors(state, apikey_generating_errors) {
        state.apikey_generating = false;
        state.apikey_generating_errors = apikey_generating_errors;
    },

    updateApiKeyRevoking(state, apikey_revoking) {
        state.apikey_revoking = apikey_revoking;
    },
    updateApiKeyRevokingErrors(state, apikey_revoking_errors) {
        state.apikey_revoking = false;
        state.apikey_revoking_errors = apikey_revoking_errors;
    },


    updateApiInfo(state, apiinfo) {
        state.apiinfo = apiinfo;
        state.apiinfo_fetching = false;
    },
    updateApiInfoFetching(state, apiinfo_fetching) {
        state.apiinfo_fetching = apiinfo_fetching;
    },
    updateApiInfoFetchingErrors(state, apiinfo_fetching_errors) {
        state.apiinfo_fetching = false;
        state.apiinfo_fetching_errors = apiinfo_fetching_errors;
    },

    updateNotificationsSubscriptions(state, notifications_subscriptions) {
        state.notifications_subscriptions = notifications_subscriptions || [];
    },
    updateNotificationsSubscriptionsLoading(state, notifications_subscriptions_loading) {
        state.notifications_subscriptions_loading = notifications_subscriptions_loading;
    },
    updateNotificationsSubscriptionsSaving(state, notifications_subscriptions_saving) {
        state.notifications_subscriptions_saving = notifications_subscriptions_saving;
    },


    updateNotificationsEvents(state, notifications_events) {
        state.notifications_events = notifications_events;
        state.notifications_events_loading = false;
    },
    updateNotificationsEventsLoading(state, notifications_events_loading) {
        state.notifications_events_loading = notifications_events_loading;
    },

    updateDashboardLogoUploading(state, loading) {
        state.dashboard_logo_uploading = loading;
    },

};

const actions = {

    fetchSettings({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.get('/api/settings/')
                .then((response) => {
                    commit('updateSettings', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },




    fetchEntity({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        return new Promise((resolve, reject) => {

            commit('updateMovementsLoading', true);
            let url = `/api/entities/${state.entity_id}/`;

            axios.get(url)
                .then((response) => {
                    commit('updateMovementsLoading', false);
                    commit('updateEntity', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    commit('updateMovementsLoading', false);
                    reject(utils.handleError(xhr_error));
                });
        });
    },

    async saveEntity({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        try {
            let url = `/api/entities/${state.entity_id}/`;
            let response = await axios.put(url, state.entity);
            commit('updateEntity', response.data);
        }
        catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEntitySavingErrors', error.details);
            commit('updateSettingsSaving', false);
            throw error;
        }
    },

    async saveAllSettings({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateSettingsSaving', true);
        await dispatch('saveEntity');
        await dispatch('saveSettings');
        commit('updateSettingsSaving', false);
        commit('updateSettingsChanged', false);
        dispatch('session/fetchLoginInfo', null, { root: true });
    },


    async saveSettings({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

        commit('updateSettingsSaving', true);
        try {
            let data = {};
            for (let key of state.keys_to_save) {
                data[key] = state.settings[key];
            }
            let response = await axios.patch('/api/settings/', data);
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateSettingsSavingErrors', error.details);
            commit('updateSettingsSaving', false);
            throw error;
        }
    },

    async uploadDashboardLogo({ commit, dispatch, state }, file) { // eslint-disable-line no-unused-vars
        commit('updateDashboardLogoUploading', true);
        try {
            let data = {};
            let options = {};

            if (file) {
                data = new FormData();
                data.append('dashboard_logo', file);
                options.headers = { 'Content-Type': 'multipart/form-data' };
            } else {
                data.dashboard_logo = null;
            }
            let response = await axios.patch('/api/settings/', data, options);
            commit('updateDashboardLogoUploading', false);
            commit('updateDashboardLogo', response.data.dashboard_logo);
            dispatch('session/fetchLoginInfo', null, { root: true });
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateDashboardLogoUploading', false);
            throw error;
        }
    },

    generateApiKey({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateApiKeyGenerating', true);
        let url = `/api/account/apikey/`;

        return new Promise((resolve, reject) => {

            axios.post(url)
                .then((response) => {
                    commit('updateApiKey', response.data.apikey);
                    dispatch('fetchApiInfo');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateApiKeyGeneratingErrors', error.details);
                    reject(error);
                });
        });
    },

    revokeApiKey({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateApiKeyRevoking', true);
        let url = `/api/account/apikey/`;

        return new Promise((resolve, reject) => {

            axios.delete(url)
                .then((response) => {
                    commit('updateApiKey', null);
                    dispatch('fetchApiInfo');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateApiKeyRevokingErrors', error.details);
                    reject(error);
                });
        });
    },

    fetchApiInfo({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateApiInfoFetching', true);
        let url = `/api/account/apikey/`;

        return new Promise((resolve, reject) => {

            axios.get(url)
                .then((response) => {
                    commit('updateApiInfo', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateApiInfoFetchingErrors', error.details);
                    reject(error);
                });
        });
    },

    saveNotifications({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars

    },

    fetchNotificationsEvents({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateNotificationsEventsLoading', true);
        let url = `/api/notifications/events/`;

        return new Promise((resolve, reject) => {

            axios.get(url, { params: { ordering: '-description' } })
                .then((response) => {
                    commit('updateNotificationsEvents', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateNotificationsEventsLoading', false);
                    reject(error);
                });
        });
    },

    fetchNotificationsSubscriptions({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateNotificationsSubscriptionsLoading', true);
        let url = `/api/notifications/subscriptions/`;

        return new Promise((resolve, reject) => {

            axios.get(url)
                .then((response) => {
                    let subscriptions = response.data.results && response.data.results[0] || {};
                    commit('updateNotificationsSubscriptions', subscriptions.events);
                    commit('updateNotificationsSubscriptionsLoading', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateNotificationsSubscriptionsLoading', false);
                    reject(error);
                });
        });
    },

    saveNotificationSubscriptions({ commit, dispatch, state }, params) { // eslint-disable-line no-unused-vars
        commit('updateNotificationsSubscriptionsSaving', true);
        let url = `/api/notifications/subscriptions/`;

        return new Promise((resolve, reject) => {
            axios.post(url, { events: state.notifications_subscriptions })
                .then((response) => {
                    commit('updateNotificationsSubscriptionsSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateNotificationsSubscriptionsSaving', false);
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
